import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";

import loginPageStyle from "assets/jss/material-kit-pro-react/views/loginPageStyle.js";

import Slide from "@material-ui/core/Slide";
import Spinner from "components/spinner/Spinner";

const useStyles = makeStyles(loginPageStyle);

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});

Transition.displayName = "Transition";

export const ModalLoader = ({ showModal }) => {
    const classes = useStyles();

    return (
        <Dialog
            classes={{
                root: classNames(classes.modalRoot, classes.loader),
                paper: classes.modal
            }}
            open={showModal}
            TransitionComponent={Transition}
            keepMounted
            aria-labelledby="classic-modal-slide-title"
            aria-describedby="classic-modal-slide-description"
        >
            <DialogContent
                id="classic-modal-slide-description"
                className={classes.modalBody}
            >
                <Spinner />
            </DialogContent>
        </Dialog>
    );
};
