import React, { useEffect } from "react";

import "react-multi-carousel/lib/styles.css";

// nodejs library that concatenates classes
import classNames from "classnames";

// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

// redux
import { connect } from "react-redux";
import * as productsRecommendedActions from "../../redux/actions/productsRecommendedActions";
import * as carActions from "../../redux/actions/carActions";
import * as modalActions from "../../redux/actions/modalActions";

// style
import styles from "assets/jss/material-kit-pro-react/views/ecommerceSections/productsStyle.js";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(styles);

// REFACTOR: extraer esta configuracion, ha otro archivo !!

const SampleNextArrow = props => {
    const classes = useStyles();
    const { className, style, onClick } = props;
    return (
        <div
            className={classNames(
                className,
                classes.arrowCarousel,
                classes.arrowNext
            )}
            onClick={onClick}
        ></div>
    );
};

const SamplePrevArrow = props => {
    const classes = useStyles();
    const { className, style, onClick } = props;
    return (
        <div
            className={classNames(
                className,
                classes.arrowCarousel,
                classes.arrowPrevius
            )}
            onClick={onClick}
        ></div>
    );
};

const CarouselMain = props => {
    const classes = useStyles();

    const settings = {
        // dots: true,
        infinite: true,
        speed: 3000,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 5000,
        // autoplaySpeed: 500,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />
    };

    // const handleProducts = () => {
    //     return props.items.map((product, index) => (

    //     ));
    // };

    return (
        <Slider {...settings} style={{ backgroundColor: "#004481" }}>
            {props.children}
        </Slider>
    );
};

const mapStateToProps = ({ productsRecommended, carReducer, modalReducer }) => {
    return {
        ...productsRecommended,
        modal: modalReducer,
        car: carReducer
    };
};

const mapDispatchToProps = {
    ...productsRecommendedActions,
    ...carActions,
    ...modalActions
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CarouselMain);
