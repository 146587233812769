import React from "react";

// core
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "components/CustomButtons/Button.js";
import Slide from "@material-ui/core/Slide";
import Close from "@material-ui/icons/Close";
import classNames from "classnames";
// router
import { withRouter } from "react-router-dom";

// redux !!
import { connect } from "react-redux";
import * as modalActions from "redux/actions/modalActions";

import { baseRouteImages, pathImagenSmall } from "../../config/config.json";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import javascriptStyles from "assets/jss/material-kit-pro-react/views/componentsSections/javascriptStyles.js";
import styles from "assets/jss/material-kit-pro-react/views/ecommerceSections/productsStyle.js";
import { PUNTOS } from "config/const/constText";
import { numberFormat } from "utils/utils";

const useStyles = makeStyles(javascriptStyles);
const useStylesCard = makeStyles(styles);

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});

Transition.displayName = "Transition";

const ModalAddCar = props => {
    const classes = useStyles();
    const classesCard = useStylesCard();

    const handleHidenModal = () => {
        props.hidenModal();
    };

    const handleGoCar = () => {
        props.history.push("/carrito");
        props.hidenModal();
    };

    // setea la ruta de la imagen necesaria !!
    const setUriImage = () => {
        const img = props.params.uriImg;
        if (!img) {
            return "";
        }

        return baseRouteImages + pathImagenSmall + img;
    };

    return (
        <Dialog
            classes={{
                root: classNames(classes.modalRoot, classes.paddingMain),
                paper: classNames(classes.modal, classes.modalSmall)
            }}
            open={props.show}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleHidenModal}
        >
            <DialogTitle disableTypography>
                <p className={classes.titleCar}>
                    <CheckCircleOutlineIcon className={classes.iconCheck} />
                    Tu Producto fue añadido.
                </p>
                <Button
                    simple
                    className={classNames(classes.modalCloseButton)}
                    key="close"
                    aria-label="Close"
                    onClick={handleHidenModal}
                >
                    <Close className={classes.modalClose} />
                </Button>
            </DialogTitle>
            <DialogContent className={classNames(classes.modalBodyCard)}>
                <img src={setUriImage()} className={classes.imageProduct} />
                <div>
                    <p
                        className={classNames(
                            classes.carBrand,
                            classes.ffMedium
                        )}
                    >
                        {props.params.title}
                    </p>
                    <p className={classes.carBrand}>
                        cantidad: {props.params.cantidad}
                    </p>
                    <p className={classes.carBrand}>
                        {numberFormat(props.params.precio)} {PUNTOS}
                        <span
                            className={classNames(
                                classesCard.fz12,
                                classesCard.priceSoles
                            )}
                        >
                            o {`S/${numberFormat( props.params.soles )}`}
                        </span>
                    </p>
                </div>
            </DialogContent>

            <DialogActions className={classNames(classes.flexColumn)}>
                <Button onClick={handleGoCar} color="info" fullWidth>
                    Ir al la bolsa
                </Button>
                <Button onClick={handleHidenModal} color="info" simple>
                    Agregar mas productos
                </Button>
            </DialogActions>
        </Dialog>
    );
};
const mapStateToProps = ({ modalReducer }) => {
    return modalReducer;
};

export default withRouter(
    connect(
        mapStateToProps,
        { ...modalActions }
    )(ModalAddCar)
);
