import {
    GET_PRODUCTS,
    GET_PRODUCT_BY_ID,
    SET_PRODUCT_SELECT,
    DELETE_PRODUCT_SELECT,
    IS_LOADING,
    ERROR
} from "redux/types/productsTypes";
import { SEARCT_PRODUTS } from "redux/types/productsTypes";

const INITIAL_STATE = {
    products: null,
    selectProduct: null,
    searchProducts: null,
    keySearchProduts: "",
    isLoading: true,
    max: 1000,
    min: 1,
    error: ""
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case GET_PRODUCTS:
            return {
                ...state,
                products: action.payload.response,
                max: action.payload.max,
                min: action.payload.min,
                isLoading: false
            };

        case GET_PRODUCT_BY_ID:
            return {
                ...state,
                selectProduct: action.payload,
                isLoading: false
            };

        case SET_PRODUCT_SELECT:
            return {
                ...state,
                selectProduct: action.payload
            };
        case SEARCT_PRODUTS:
            return {
                ...state,
                isLoading: false,
                ...action.payload
            };
        case DELETE_PRODUCT_SELECT:
            return { ...state, selectProduct: null };

        case IS_LOADING:
            return { ...state, isLoading: true };

        case ERROR:
            return { ...state, isLoading: false, error: action.payload };

        default:
            return state;
    }
};
