import { POINTS } from "./const/constStorage";

import { objectToString, stringToObject } from "../../utils/utils";

// regresa los datos del canje de puntos !!
export const getPointsStorage = () => {
    const points = localStorage.getItem(POINTS);
    return !!points ? stringToObject(points) : null;
};

// agrega los datos del canje de puntos del storage !!
export const setPointsStorage = data => {
    localStorage.setItem(POINTS, objectToString(data));
};

// elimina datos del canje de puntos del storage !!
export const removePointsStorage = () => {
    localStorage.removeItem(POINTS);
};
