import { UPDATE_MARCA } from "redux/types/marcasTypes";
import { GET_MARCAS_FOR_CATEGORY } from "redux/types/marcasTypes";

const INITIAL_STATE = {
	marcas: [],
	isLoading: false,
	error: ""
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case GET_MARCAS_FOR_CATEGORY:
			return { ...state, marcas: action.payload };
		case UPDATE_MARCA:
			return { ...state, marcas: [...state.marcas, action.payload] };
		default:
			return state;
	}
};
