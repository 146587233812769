import { cnn, cnnAjax } from "../connect/apiPuntos";
import { debounceUtil } from "utils/utils";

const BASE_PATH = "productos";

export const getProducts = async (category, body) => {
    const { response, max, min } = await cnnAjax(
        `${BASE_PATH}/${category}`,
        "POST",
        {},
        body
    );

    // const { response, max, min } = resp;
    return { response, max, min };
};

// busca productos
export const searchProdctServices = async search => {
    const { response } = await cnnAjax(`${BASE_PATH}/search/${search}`, "GET");
    return { data: response };
};

export const getProductById = async id => {
    const { data } = await cnn(`${BASE_PATH}/${id}`, "GET");
    return data;
};

export const getProductRecommended = async () => {
    const data = await cnnAjax(`${BASE_PATH}/descuento`, "GET");
    return data;
};

/**
 * Valida si los productos estan activos y regresa solo los activos !!
 * @param {object} body {token, [id,id,id]}
 */
export const getProductsByIds = async body => {
    const data = await cnnAjax(`${BASE_PATH}/actives`, "POST", null, body);

    return data;
};

/**
 * Regresa las caracteristicas del producto seleccionado !!
 */
export const getFeaturesByCod = async codProduct => {
    const data = await cnnAjax(`/caracteristicas/${codProduct}`, "GET");
    return data;
};

export const getPhotosProductByCode = async productCode => {
    const data = await cnnAjax(`${BASE_PATH}/fotos/${productCode}`, "GET");
    return data;
};

export const getProductsByCategoriesActive = async () => {
    const data = await cnnAjax(`${BASE_PATH}/categories/actives`, "GET");

    return data;
};

export const getProductsOffers = async () => {
    const data = await cnnAjax(`${BASE_PATH}/offers`, "GET");

    return data;
};
