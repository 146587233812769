import React, { useEffect } from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";

import Info from "components/Typography/Info";
import style from "assets/jss/material-kit-pro-react/views/ecommerceStyle";
import infoPageStyle from "assets/jss/material-kit-pro-react/views/infoPageStyle";
import { PRICE_POINTS } from "config/const/globalConsts";

const useStyleInfo = makeStyles(infoPageStyle);
const useStyles = makeStyles(style);

const TerminosCondiciones = () => {
    const classes = useStyles();
    const classesInfo = useStyleInfo();

    useEffect(() => {
        goTop();
    }, []);

    const goTop = () => {
        window.scrollTo(0, 0);
        document.body.scrollTop = 0;
    };

    return (
        <div
            className={classNames(
                classes.main,
                classes.mainRaised,
                classes.marginInfoContainer,
                classesInfo.containerTerminos
            )}
        >
            <div className={classes.container}>
                <h2>Términos y Condiciones</h2>
                <br />
                <Info>Generalidades</Info>
                <p>
                    El objeto de los presentes Términos y Condiciones es regular
                    el acceso y uso al sitio web https://puntosdelivery.com (en
                    adelante el <b>Sitio Web</b>)
                </p>
                <p>
                    El <b>Sitio Web</b> es Administrado y de titularidad de la
                    empresa <b>Rewards Peru S.A.C.</b> (en adelante{" "}
                    <b>Rewards</b>) quien se reserva la facultad de modificar en
                    cualquier momento y sin previo aviso la presentación, la
                    funcionalidad, los productos, y los servicios que contienen
                    el <b>Sitio Web</b>; en tal sentido, el cliente reconoce y
                    acepta que <b>Rewards</b>) en cualquier momento podrá
                    interrumpir, desactivar, modificar o cancelar cualquiera de
                    los elementos que componen el <b>Sitio Web</b>. Por esta
                    razón recomendamos revisar los Términos y Condiciones cada
                    vez que utilice el <b>Sitio Web</b>. Los clientes quedarán
                    sujetos a los Términos y Condiciones del presente el{" "}
                    <b>Sitio Web</b>, en el momento en el que envíen su
                    solicitud de pedido.
                </p>
                <p>
                    Cualquier persona que realice una transacción en el{" "}
                    <b>Sitio Web</b>, declara y reconoce, por el hecho de
                    efectuar la compra, que conoce y acepta todos y cada uno de
                    los Términos y Condiciones descritos a continuación. Se
                    entenderán conocidos y aceptados, por el solo hecho del
                    registro y/o la compra de productos o servicios del{" "}
                    <b>Sitio Web</b> tanto pagando con Puntos BBVA como con
                    cualquier otro medio de pago.
                </p>
                <p>
                    Los productos ofrecidos por el <b>Sitio Web</b> sólo están
                    disponibles para personas que tengan capacidad legal para
                    adquirirlos.
                </p>
                <Info>Registro de usuario y contraseña</Info>
                <p>
                    Para canjear o comprar productos en el <b>Sitio Web</b>, es
                    necesario estar registrado. Puedes registrarte directamente
                    en el <b>Sitio Web</b> haciendo clic en botón "Ingresar" de
                    la barra superior y luego en el enlace inferior con el texto
                    "Regístrate aquí" El cliente deberá llenar el formulario
                    correspondiente. Posterior a esto recibirá un correo de
                    confirmación de registro y con esta confirmación ya podrá
                    ingresar colocando su documento de identidad y contraseña de
                    acceso exclusivo para el <b>Sitio Web</b>.
                </p>
                <p>
                    El cliente tendrá la posibilidad de cambiar su contraseña si
                    es olvidada, para lo cual deberá sujetarse al procedimiento
                    establecido en el <b>Sitio Web</b>. El usuario asume
                    totalmente la responsabilidad por el mantenimiento de la
                    confidencialidad de su contraseña registrada en el{" "}
                    <b>Sitio Web</b>, la cual le permite efectuar compras y
                    obtener información de las mismas. Dicha clave es de uso
                    personal y su entrega a terceros, no involucra
                    responsabilidad de <b>Rewards</b>.
                </p>
                <p>
                    Luego de ingresar con su usaurio y contraseña el cliente
                    visualizará su nombre en la parte superior, sin embargo,
                    para poder canjear sus Puntos BBVA, deberá solicitar via
                    chat en línea o correo electrónico a
                    puntosdelivery@rewardsperu.com la sincronización de sus
                    Puntos BBVA disponibles indicando su nombre completo, DNI y
                    correo registrado en BBVA.
                </p>
                <Info>Medios de pago</Info>
                <p>
                    Se podrán realizar compras en el <b>Sitio Web</b> pagando
                    con Puntos BBVA como con Puntos más soles o solo soles con
                    cargo a una Tarjeta de crédito o débito BBVA.
                </p>
                <p>
                    Para el pago de los soles sólo son válidas todas las
                    tarjetas de crédito o débito de BBVA, pudiendo en el momento
                    del pago dividirlo hasta en 12 cuotas sin intereses
                    únicamente con tarjetas de crédito Visa BBVA.
                </p>
                <p>
                    Los precios de los productos publicados en el{" "}
                    <b>Sitio Web</b>, se encuentran vigentes únicamente mientras
                    aparezcan en él. El <b>Sitio Web</b> podrá modificar
                    cualquier información contenida, incluyendo las relacionadas
                    con productos, precios y existencias, en cualquier momento y
                    sin previo aviso hasta el momento confirmar la realización
                    de una compra.
                </p>
                <p>
                    Todos los productos muestran su precio en Puntos y en Soles
                    y pueden ser pagados con Puntos BBVA teniendo en cuenta que
                    un sol (S/1) equivale a ${PRICE_POINTS} Puntos BBVA.
                </p>
                <Info>Pedidos, vigencias y stock</Info>
                <p>
                    Los precios de los productos y servicios publicados en el{" "}
                    <b>Sitio Web</b> se encuentran vigentes únicamente mientras
                    aparezcan en el <b>Sitio Web</b> y se permita la compra
                    luego de realizado y confirmado el pago correspondiente ya
                    sea con Puntos o Soles.
                </p>
                <p>
                    <b>Rewards</b> podrá modificar cualquier información
                    contenida en el <b>Sitio Web</b>, incluyendo las
                    relacionadas con mercaderías, precios y existencias en
                    cualquier momento y sin previo aviso, hasta el momento de
                    recibir una solicitud de pedido.
                </p>
                <p>
                    Los productos y servicios publicados en promoción son de
                    venta final y no aplican a cambios ni devoluciones. Estos
                    productos pueden encontrarse no disponibles a pesar de estar
                    habilitados en el <b>Sitio Web</b>.
                </p>
                <p>
                    Los precios pueden variar sin previo aviso. Los precios son
                    válidos hasta agotar stock. Todos los productos están
                    sujetos a un stock mínimo de un (01) producto por producto
                    publicado.
                </p>
                <Info>Despacho y Entrega</Info>
                <p>
                    Para el caso de los pedidos de productos con envío a Lima
                    Metropolitana y Callao el plazo de entrega será no mayor a
                    cinco (5) días hábiles a partir de la fecha de confirmación
                    del pedido. (no se consideran días hábiles sábados, domingos
                    ni feriados)
                </p>
                <p>
                    Para el caso de los pedidos de productos con envío a
                    Provincias, el plazo de entrega es de diez a quince (10 a
                    15) días hábiles a partir de la fecha de confirmación del
                    pedido. (no se consideran días hábiles sábados, domingos ni
                    feriados)
                </p>
                <p>
                    El horario de entrega es de Lunes a Viernes de 08:30am a
                    06:00pm y Sábados de 08:30am a 02:00pm pudiendo extenderse
                    en algunas Provincias.
                </p>
                <p>
                    Cuando en un pedido existan productos de diferentes
                    almacenes éstos podrán serán despachados de manera
                    independiente. La información del lugar de envío es de
                    exclusiva responsabilidad del cliente.
                </p>
                <p>
                    Para el caso de productos físicos se aplicará un costo de
                    envío al momento de realizar el canje o compra, el cual será
                    calculado de acuerdo a las características de los productos
                    adquiridos y la dirección de entrega consignada por el
                    cliente.
                </p>
                <p>
                    La entrega es realizada en la dirección que el cliente
                    ingresa al momento de efectuar el pedido. Es responsabilidad
                    del cliente ingresar con exactitud los datos correctos,
                    completos y referencias pertinentes para que la entrega se
                    pueda realizar de forma oportuna en la dirección y dentro
                    del plazo acordado.
                </p>
                <p>
                    Es responsabilidad del cliente que una persona mayor de 18
                    años debidamente identificada con DNI se encuentre en la
                    dirección de entrega durante la fecha programada.
                </p>
                <p>
                    Al recibir el o producto, el cliente debe validar que
                    corresponda con su pedido y que el/los producto(s)
                    adquirido(s) se encuentra en buen estado antes de declarar
                    que se encuentra conforme con la entrega. En caso de
                    disconformidad, debe rechazar el producto en el momento de
                    la entrega e indicar los motivos, su nombre y DNI en la guía
                    virtual de despacho.
                </p>
                <p>
                    En caso que el/los producto(s) adquirido(s) fuera recibido
                    en buenas condiciones y completo, el cliente declarara su
                    conformidad en el momento de la recepción (entiéndase
                    cliente como la persona que recepciona el producto en la
                    dirección consignada en el momento de la compra), el{" "}
                    <b>Sitio Web</b>, no se responsabiliza por daños físicos al
                    producto, por lo que solo se atenderá la aplicación de la
                    garantía de la Marca correspondiente según lo establecido en
                    los presentes Términos y Condiciones.
                </p>
                <p>
                    En caso se presente algún error en la dirección ingresada
                    por el cliente no se podrá realizar la entrega coordinada y
                    se procederá a regresar los productos a los almacenes de{" "}
                    <b>Rewards</b> y en un asesor se contactará por medio de
                    celular y/o correo para su reprogramación o coordinación de
                    recojo directamente en el almacén que <b>Rewards</b>{" "}
                    indique.
                </p>
                <p>
                    Luego de realizar dos visitas fallidas a la dirección
                    indicada. el cliente deberá recoger sus productos en la
                    dirección que <b>Rewards</b> mencione o coordinar un nuevo
                    servicio de envío previo pago, para lo cual el cliente se
                    obliga a realizar cualquier de estas acciones dentro de los
                    30 días hábiles contados a partir de la coordinación,
                    vencido dicho plazo sin que el cliente hubiese procedido a
                    recoger el/los producto(s) por cualequier razón, se
                    entenderá para todos los efectos y de manera irreversible
                    que el cliente ha desistido del pedido realizado, por lo que
                    ésta queda automáticamente sin valor ni efecto alguno.{" "}
                    <b>Rewards</b> procederá a emitir una Nota de Crédito la
                    cual tendrá una vigencia limitada, a favor del cliente,
                    pudiendo descontar un porcentaje de costos administrativos y
                    almacenamiento que se hayan generado por la gestión. lo cual
                    será informado al correo registrado en el <b>Sitio Web</b>
                </p>
                <p>
                    Si la entrega se realiza a terceros (familiares, asesora del
                    hogar, conserjes, mayordomos, etc.), entenderemos que el
                    producto fue recibido satisfactoriamente, bajo
                    responsabilidad del cliente.
                </p>
                <p>
                    Bajo ninguna circunstancia es posible devolver los Puntos
                    BBVA al cliente o extornar la operación a su medio de pago
                    directamente.
                </p>
                <p>
                    El cliente declara que ha sido expresamente informado de los
                    alcances de esta condición, aceptándola sin restricción
                    alguna y bajo su entera responsabilidad.
                </p>
                <p>
                    Para el caso de productos virtuales no se aplicará ningún
                    costo de envío, sin embargo, pueden estar sujetos a un costo
                    de Fee de procesamiento (E-vales, cupones virtuales, etc).
                    Este tipo de productos serán enviados al correo electrónico
                    del cliente en un plazo no mayor a 48 horas hábiles.
                </p>
                <Info>Información de los productos y publicaciones</Info>
                <p>
                    Los datos incluidos en cada página, artículo y descripción
                    de productos del <b>Sitio Web</b>, así como las fotografías,
                    imágenes, logotipos, representaciones
                    gráficas/iconográficas, videos, marcas o signos distintivos,
                    tienen una función meramente informativa.
                </p>
                <p>
                    El <b>Sitio Web</b> apunta a exhibir imágenes de productos
                    con la mayor precisión posible, no obstante, no podemos
                    garantizar el color que usted visualice en su pantalla
                    coincida con el del producto a entregar, ya que la
                    visualización del color depende, en parte, del tipo de
                    pantalla y configuración de color que utilice el usuario.
                </p>
                <p>
                    El <b>Sitio Web</b> no es responsable por la aparición de
                    errores o imprecisiones en dicha información de detalle, sin
                    embargo, se compromete a corregir los errores u omisiones, a
                    la brevedad, tras haber sido informada sobre los mismos.
                </p>
                <Info>Cambios, devoluciones y Garantías</Info>
                <p>
                    Si existió algún este error por parte del cliente o del{" "}
                    <b>Sitio Web</b> y es detectado luego de efectuado el
                    pedido, el cliente deberá comunicarse vía correo electrónico
                    o chat web dentro de las veinticuatro (24) horas de
                    realizada la transacción, en tal caso el cliente podra
                    realizar el cambio del detalle de su pedido, siempre y
                    cuando sea por un producto de precio similar o mayor.
                </p>
                <p>
                    Si el error es detectado posterior las veinticuatro (24)
                    horas de realizar el pedido el cambio no procede y el pedido
                    será enviado dentro del plazo establecido.
                </p>
                <p>
                    Luego de la entrega y recepción del pedido en la dirección
                    consignada, no procederá ningún cambio, salvo casos en que
                    se evidencie que el producto entregado no cumple con las
                    especificaciones ofrecidas en su publicación, siendo solo en
                    este caso que el cliente deberá comunicarse con el{" "}
                    <b>Sitio Web</b> via correo electrónico para explicarnos su
                    desconformidad, acción que deberá realizar únicamente dentro
                    de las veinticuatro (24) horas de la entrega, posterior a
                    este plazo el cliente esta obligado a hacer uso de la
                    garantía que la Marca del producto adquirido ofrezca, bajo
                    los términos, procesos y plazos que esta indique para su
                    atención.*
                </p>
                <p>
                    El <b>Sitio Web</b> en ningún caso se aceptará la anulación
                    del pedido. Bajo ninguna circunstancia es posible devolver
                    los Puntos BBVA al cliente luego de efectuada la compra.
                </p>
                <p>
                    Los productos que se presentan en las comunicaciones
                    correspondientes al <b>Sitio Web</b> y comunicaciones del
                    Banco están sujetos a disponibilidad de stock en el momento
                    de la confirmación del pedido.
                </p>
                <p>
                    (*) El cambio de producto no se aplica a productos médicos,
                    joyería, perfumes, licores, sets de cama y suministros
                    tecnológicos que se encuentren en empaques abiertos y
                    deteriorados, entiéndase “cliente” incluso a las personas
                    que recepciona el pedido.
                </p>
                <p>
                    Los productos publicados en el <b>Sitio Web</b> cuentan con
                    garantía, que se aplicará según el certificado de garantía
                    emitido por la Marca o de acuerdo a las condiciones
                    indicadas en las características del producto. El tiempo de
                    garantía lo establece la Marca.
                </p>
                <p>
                    Dependiendo de la Marca del producto, el cliente deberá
                    comunicarse con el centro de servicio autorizado para que se
                    informe sobre las condiciones de uso de la garantía. La
                    asistencia técnica, en caso aplique la garantía, la deberá
                    ser coordinado por el cliente con la directamente.
                </p>
                <p>
                    Para hacer efectiva la garantía del producto el cliente
                    deberá contar con su comprobante de compra y contactarse con
                    la marca y/o centro autorizado que el <b>Sitio Web</b> le
                    indique.
                </p>
                <Info>Canales de atención</Info>
                <p>
                    El <b>Sitio Web</b> cuenta con los siguientes Canales de
                    Atención
                </p>
                <p>
                    <b>Correo Electrónico:</b> Puede escribirnos al correo
                    puntosdelivery@rewardsperu.com indicando su nombre completo
                    y número de documento de identidad, el cual tendrá una
                    respuesta no mayor a 24 horas hábiles.
                </p>
                <p>
                    <b>Chat en línea:</b> Disponible de Lunes a viernes de
                    09:00am a 06:00pm, fuera de este horario sus mensajes serán
                    recepcionados y dentro de las 24 horas hábiles un asesor lo
                    contactará para ayudarlo con su consulta.
                </p>
                <p>
                    <b>Libro de Reclamaciones:</b> El <b>Sitio Web</b> cuenta
                    con un Libro de reclamaciones el cual generará un ticket de
                    atención que deberá ser respondido en un plazo no mayor a 48
                    horas hábiles y resuelto en un plazo no mayor a 30 días
                    hábiles.
                </p>
                <Info>Comprobantes de pago</Info>
                <p>
                    Toda compra en el <b>Sitio Web</b> generará una Boleta de
                    Venta, en caso se requiera una factura, deberá comunicarlo
                    dentro de las 24 horas de haber realizado su pedido por
                    medio de los canales de atención del <b>Sitio Web</b>{" "}
                    indicando su nuemero de DNI, RUC y Razón Social.
                </p>
                <p>
                    Según el reglamento de Comprobantes de Pago aprobado por la
                    Resolución de Superintendencia N° 007-99 / SUNAT (RCP) y el
                    Texto Único Ordenado de la Ley del Impuesto General a las
                    Ventas e Impuesto Selectivo al Consumo, aprobado mediante
                    Decreto Supremo N° 055-99-EF y normas modificatorias (TUO
                    del IGV), se concluye:
                </p>
                <p>
                    “No existe ningún procedimiento vigente que permita el canje
                    de boletas de venta por facturas, más aún las notas de
                    crédito no se encuentran previstas para modificar al
                    adquirente o usuario que figura en el comprobante de pago
                    original”.
                </p>
                <p>
                    Las facturas electrónicas, las boletas de venta electrónicas
                    y las notas electrónicas vinculadas a estos comprobantes
                    electrónicos se enviarán al correo electrónico designado por
                    el cliente.
                </p>
                <p>
                    Por lo expuesto, si el cliente requiere una factura, deberá
                    notificarlo el mismo día de haber realizado su compra
                    mediante correo electrónico, teléfono o whatsapp, ya que
                    según los párrafos citados no procederá cambio alguno.
                </p>

                <Info>Protección de Datos Personales</Info>
                <p>
                    En el registro del <b>Sitio Web</b> los usuarios deberán
                    facilitar determinados datos personales. Su información se
                    procesa y almacena en servidores propios, los cuales cumplen
                    con los estándares de seguridad y protección tanto física
                    como tecnológica.
                </p>
                <p>
                    <b>Rewards</b> informará al cliente que realizará el
                    tratamiento de los datos personales brindados (nombre,
                    apellidos, documento de identidad, direcciones y teléfonos).
                    Este tratamiento es necesario y tiene la finalidad exclusiva
                    de brindarle al cliente el producto solicitado, realizar la
                    entrega del mismo y poder realizar la atención de
                    solicitudes post venta.
                </p>
                <p>
                    <b>Rewards</b> compartirá dichos datos personales antes
                    mencionados con los proveedores que contrate para envíos a
                    distintas Provincias.
                </p>
                <p>
                    <b>Rewards</b> declara que cualquier tratamiento de los
                    datos personales se ajusta en las normativas de protección
                    de datos personales vigentes y aplicables dentro del
                    territorio peruano.
                </p>
                <p>
                    La aceptación de los presentes Términos y Condiciones,
                    incluye la aceptación del Tratamiento de datos personales
                    ingresados por el cliente en el <b>Sitio Web</b>.
                </p>
                <Info>Acerca del Programa Puntos BBVA</Info>
                <p>
                    <b>Puntos BBVA</b>, es el programa de lealtad de las
                    Tarjetas de Crédito BBVA. Sólo el cliente titular puede
                    utilizar los Puntos BBVA, siempre que su tarjeta se
                    encuentre sin ningún tipo de bloqueo. Para mayor información
                    del Programa Puntos
                    <b>BBVA</b>, puedes encontrar el reglamento en bbva.pe
                </p>
                <p>
                    <b>
                        La equivalencia de Puntos BBVA es la siguiente: $
                        {PRICE_POINTS}
                        Puntos BBVA = S/1 (1 sol).
                    </b>
                </p>
                <p>
                    <b>BBVA</b> actúa como intermediario entre Puntos Delivery y
                    el Cliente y no se responsabiliza por la calidad de los
                    productos de las marcas publicadas o servicios ofrecidos por
                    la Empresa administradora del <b>Sitio Web</b>
                </p>
                <br />
            </div>
        </div>
    );
};

export default TerminosCondiciones;
