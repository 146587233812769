import { CAR } from "./const/constStorage";

import { objectToString, stringToObject } from "../../utils/utils";

// regresa los datos del carrito !!
export const getCarStorage = () => {
	const car = localStorage.getItem(CAR);
	return !!car ? stringToObject(car) : [];
};

// agrega los datos del carrito al storage !!
export const setCarStorage = data => {
	localStorage.setItem(CAR, objectToString(data));
};

// elimina carrito del storage !!
export const removeCarStorage = () => {
	localStorage.removeItem(CAR);
};

// elimina los items que se envian solo a lima!!
export const removeItemsProvincia = () => {
	const car = getCarStorage();
	const newCard = car.filter(item => item.envio !== "L");
	setCarStorage(newCard);
}