import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
// nodejs library that concatenates classes
import classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Book from "@material-ui/icons/Book";
import Button from "components/CustomButtons/Button.js";
import GridItem from "components/Grid/GridItem.js";
import SvgIcon from "components/Svg/SvgIcon";
import Danger from "components/Typography/Danger.js";
import MenuItem from "@material-ui/core/MenuItem";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Check from "@material-ui/icons/Check";
import Checkbox from "@material-ui/core/Checkbox";

// CAPCHAT
import ReCAPTCHA from "react-google-recaptcha";
import { CAPCHA_KEY } from "config/const/capchat";
import {
    InputAdornment,
} from "@material-ui/core";
import GridContainer from "components/Grid/GridContainer.js";

// redux
import { connect } from "react-redux";
import * as formActions from "../../redux/actions/formActions";
import * as modalActions from "../../redux/actions/modalActions";

// formikf
import { Formik, Form } from "formik";
import CustomInput from 'components/CustomInput/CustomInput';
import { INITIAL_VALUES, ShemaComplaintBook } from './shemas/shemaComplaintBook';
import style from "assets/jss/material-kit-pro-react/views/ComplaintBook.js";
import basicsStyle from "assets/jss/material-kit-pro-react/views/componentsSections/basicsStyle.js";
import { setComplaintServices } from 'services/complaint/complaintServices';

const useStyles = makeStyles(style);
const useBasicStyles = makeStyles(basicsStyle);

const FormComplaintBook = props => {
    const classes = useStyles();
    const classesBasic = useBasicStyles();

    const [tokenCapchat, setTokenCapchat] = useState(null);
    const [checked, setChecked] = useState(false);

    useEffect(() => {
        if ( !props.type_document.length ) {
            props.getTypeDocument();
        }
    }, [])


    const handleSubmit = async (inputForm) => {
        if (!tokenCapchat) return; // si no existe el token, no deja pasar !!
        props.toggleGlobalLoader();
        if ( !inputForm.phone2 ) {
            delete inputForm.phone2;
        }
        inputForm.phone1 = parseInt(inputForm.phone1);
        inputForm.captcha = tokenCapchat;
        
        const result = await setComplaintServices(inputForm);
        props.toggleGlobalLoader();
        if ( result.status == "ok" ) {
            props.history.push("/reclamaciones/respuesta", {
                result
            });
        }

        
    }

    // genera las opciones del select box !!
    const setSelect = () => {
        if ( !!props.type_document.length ) {           
            return props.type_document.map((tipo, index) => (
                <MenuItem
                    key={`select-${index}`}
                    classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelected
                    }}
                    value={tipo.tipo_doc}
                >
                    {tipo.nombre}
                </MenuItem>
            ));
        }

        return null
    };

    const hasError = (errors, touched, field) => {
        return !!errors[field] && touched[field]
    }

    const onChangeCapcha = value => {
        setTokenCapchat(value);
    };

    return (
        <Formik 
            onSubmit={handleSubmit}
            initialValues={INITIAL_VALUES}
            validationSchema={ShemaComplaintBook}
            render={({                    
                handleChange,
                values,
                touched,
                errors}) => {
                return (
                    <Form>
                        <GridContainer >
                            <GridItem xs={12} sm={5} md={5}>
                                <FormControl
                                    fullWidth
                                    className={classes.selectFormControl}
                                >
                                    <InputLabel
                                        htmlFor="typeDocument"
                                        className={classes.fontLabel}
                                    >
                                        Tipo de documento *
                                    </InputLabel>
                                    <Select
                                        MenuProps={{
                                            className: classes.selectMenu
                                        }}
                                        classes={{
                                            select: classes.select
                                        }}
                                        value={values.document_type}
                                        onChange={handleChange}
                                        inputProps={{
                                            name: "document_type",
                                            id: "typeDocument"
                                        }}
                                    >
                                        {/* <MenuItem
                                            disabled
                                            classes={{
                                                root: classes.selectMenuItem
                                            }}
                                        >
                                            Seleccione tipo de documento
                                        </MenuItem> */}

                                        {!!props.type_document &&
                                            !!props.type_document.length
                                            ? setSelect()
                                            : null}
                                    </Select>
                                </FormControl>
                            </GridItem>
                            <GridItem xs={12} sm={7} md={7}>
                                <CustomInput
                                    labelText="Numero de documento *"
                                    formControlProps={{ fullWidth: true }}
                                    error={hasError(errors, touched, "document_number")}
                                    inputProps={{
                                        type: "text",
                                        name: "document_number",
                                        onChange: handleChange,
                                        value: values.document_number,
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <SvgIcon
                                                    desc="icono-name"
                                                    fill="#626262"
                                                    width="20px"
                                                    height="20px"
                                                />
                                            </InputAdornment>
                                        )
                                    }}
                                />
                                {hasError(errors, touched, "document_number") && (
                                    <Danger>
                                        {errors.document_number}
                                    </Danger>
                                )}
                            </GridItem>
                            <GridItem xs={12} sm={6} md={6}>
                                <CustomInput
                                    labelText="Nombres *"
                                    formControlProps={{ fullWidth: true }}
                                    error={hasError(errors, touched, "fullname")}
                                    inputProps={{
                                        type: "text",
                                        name: "fullname",
                                        onChange: handleChange,
                                        value: values.fullname,
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <SvgIcon
                                                    desc="icono-name"
                                                    fill="#626262"
                                                    width="20px"
                                                    height="20px"
                                                />
                                            </InputAdornment>
                                        )
                                    }}
                                />
                                {hasError(errors, touched, "fullname") && (
                                        <Danger>
                                            {errors.fullname}
                                        </Danger>
                                    )}
                            </GridItem>
                            <GridItem xs={12} sm={6} md={6}>
                                <CustomInput
                                    labelText="Apellidos *"
                                    formControlProps={{ fullWidth: true }}
                                    error={hasError(errors, touched, "lastname")}
                                    inputProps={{
                                        type: "text",
                                        name: "lastname",
                                        onChange: handleChange,
                                        value: values.lastname,
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <SvgIcon
                                                    desc="icono-name"
                                                    fill="#626262"
                                                    width="20px"
                                                    height="20px"
                                                />
                                            </InputAdornment>
                                        )
                                    }}
                                />
                                {hasError(errors, touched, "lastname") && (
                                        <Danger>
                                            {errors.lastname}
                                        </Danger>
                                    )}
                            </GridItem>

                            {/* // row 3 */}
                            <GridItem xs={12} sm={6} md={6}>
                                <CustomInput
                                    labelText="Correo *"
                                    formControlProps={{ fullWidth: true }}
                                    error={hasError(errors, touched, "email")}
                                    inputProps={{
                                        type: "text",
                                        name: "email",
                                        onChange: handleChange,
                                        value: values.email,
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <SvgIcon
                                                    desc="icono-name"
                                                    fill="#626262"
                                                    width="20px"
                                                    height="20px"
                                                />
                                            </InputAdornment>
                                        )
                                    }}
                                />
                                {hasError(errors, touched, "email") && (
                                    <Danger>
                                        {errors.email}
                                    </Danger>
                                )}
                            </GridItem>
                            <GridItem xs={12} sm={6} md={6}>
                                <CustomInput
                                    labelText="Dirección *"
                                    formControlProps={{ fullWidth: true }}
                                    error={hasError(errors, touched, "direction")}
                                    inputProps={{
                                        type: "text",
                                        name: "direction",
                                        onChange: handleChange,
                                        value: values.direction,
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <SvgIcon
                                                    desc="icono-home"
                                                    fill="#626262"
                                                    width="20px"
                                                    height="20px"
                                                />
                                            </InputAdornment>
                                        )
                                    }}
                                />
                                {hasError(errors, touched, "direction") && (
                                        <Danger>
                                            {errors.direction}
                                        </Danger>
                                    )}
                            </GridItem>

                            {/* // row 4 */}
                            <GridItem xs={12} sm={6} md={6}>
                                <CustomInput
                                    labelText="Teléfono 1 *"
                                    formControlProps={{ fullWidth: true }}
                                    error={hasError(errors, touched, "phone1")}
                                    inputProps={{
                                        type: "text",
                                        name: "phone1",
                                        onChange: handleChange,
                                        value: values.phone1,
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <SvgIcon
                                                    desc="icono-phone"
                                                    fill="#626262"
                                                    width="20px"
                                                    height="20px"
                                                />
                                            </InputAdornment>
                                        )
                                    }}
                                />
                                {hasError(errors, touched, "phone1") && (
                                        <Danger>
                                            {errors.phone1}
                                        </Danger>
                                    )}
                            </GridItem>
                            <GridItem xs={12} sm={6} md={6}>
                                <CustomInput
                                    labelText="Teléfono 2"
                                    formControlProps={{ fullWidth: true }}
                                    error={hasError(errors, touched, "phone2")}
                                    inputProps={{
                                        type: "text",
                                        name: "phone2",
                                        onChange: handleChange,
                                        value: values.phone2,
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <SvgIcon
                                                    desc="icono-phone"
                                                    fill="#626262"
                                                    width="20px"
                                                    height="20px"
                                                />
                                            </InputAdornment>
                                        )
                                    }}
                                />
                                {hasError(errors, touched, "phone2") && (
                                        <Danger>
                                            {errors.phone2}
                                        </Danger>
                                    )}
                            </GridItem>
                            <GridItem xs={12} sm={6} md={6}>
                                <CustomInput
                                    labelText="Detalle de Reclamo *"
                                    formControlProps={{ fullWidth: true }}
                                    error={hasError(errors, touched, "complaint")}
                                    inputProps={{
                                        multiline: true,
                                        type: "text",
                                        name: "complaint",
                                        onChange: handleChange,
                                        value: values.complaint,
                                        rowsMax: 4,
                                        rows: 3,
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <Book />
                                            </InputAdornment>
                                        )
                                    }}
                                />
                                {hasError(errors, touched, "complaint") && (
                                        <Danger>
                                            {errors.complaint}
                                        </Danger>
                                    )}
                            </GridItem>
                            <GridItem xs={12} sm={6} md={6}>
                                <CustomInput
                                    labelText="Pedido de Cliente *"
                                    formControlProps={{ fullWidth: true }}
                                    error={hasError(errors, touched, "order")}
                                    inputProps={{
                                        multiline: true,
                                        type: "text",
                                        name: "order",
                                        onChange: handleChange,
                                        value: values.order,
                                        rowsMax: 4,
                                        rows: 3,
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <SvgIcon
                                                    desc="icono-referencia"
                                                    fill="#626262"
                                                    width="20px"
                                                    height="20px"
                                                />
                                            </InputAdornment>
                                        )
                                    }}
                                />
                                {hasError(errors, touched, "order") && (
                                        <Danger>
                                            {errors.order}
                                        </Danger>
                                    )}
                            </GridItem>

                            <GridItem xs={12} sm={12} md={12}>
                                <FormControlLabel
                                    classes={{
                                        label: classesBasic.label
                                    }}
                                    //Acepto recibir vía e-mail Novedades y Promociones
                                    control={
                                        <Checkbox
                                            tabIndex={-1}
                                            onClick={() => {
                                                setChecked(!checked);
                                            }}
                                            checkedIcon={
                                                <Check
                                                    className={
                                                        classesBasic.checkedIcon
                                                    }
                                                />
                                            }
                                            icon={
                                                <Check
                                                    className={
                                                        classesBasic.uncheckedIcon
                                                    }
                                                />
                                            }
                                            classesBasic={{
                                                checked: classesBasic.checked,
                                                root: classesBasic.checkRoot
                                            }}
                                            checked={checked}
                                            inputProps={{
                                                name: "terms"
                                            }}
                                        />
                                    }
                                    label={
                                        <span className={classesBasic.ffBook}>
                                        Declaro ser el usuario del servicio o producto y acepto el contenido
                                        del presente formulario manifestado bajo Declaración jurada 
                                        la veracidad ed los hechos descritos.
                                        </span>
                                    }
                                />
                            </GridItem>
                            
                            <div className={classes.centerFlex}>
                                <ReCAPTCHA sitekey={CAPCHA_KEY} onChange={onChangeCapcha} />
                            </div>

                            {/* // button !! */}
                            <GridItem xs={12} sm={12} md={12} >
                                <div className={classes.centerFlex}>
                                    <Button
                                        color="info"
                                        size="md"
                                        type="submit"
                                        disabled={!tokenCapchat || !checked} 
                                    >
                                        ENVIAR
                                    </Button>
                                </div>
                            </GridItem>
                        </GridContainer>
                    </Form>
                )
            }}
        />
    )
}

FormComplaintBook.propTypes = {

}

const mapStateToProps = ({ formReducer }) => ({
    ...formReducer
});

const mapDispatchToProps = {
    ...formActions,
    ...modalActions
};

export default connect(mapStateToProps, mapDispatchToProps)(FormComplaintBook)
