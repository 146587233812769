import { cnnAjax } from "../connect/apiPuntos";

const BASE_PATH = "visa";

export const getSessionKey = async (amount, token) => {
    const response = await cnnAjax(`${BASE_PATH}/auth`, "POST", null, {
        amount,
        token
    });

    return response;
};

/**
 * Guarda
 * @param {Object} body { auth, purchaseNumber, amount, transactionToken }
 */
export const saveChangeVisa = async body => {
    const data = await cnnAjax(`${BASE_PATH}/save`, "POST", null, body);

    return data;
};

// crea la trama de soles, aun sin la respuesta!!
export const createTramaSoles = async body => {
    const data = await cnnAjax(`${BASE_PATH}/create/tramasoles`, "POST", null, body);

    return data;
}
