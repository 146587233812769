import {
    DELETE_DIRECTION_USER,
    GET_DIRECTIONS_USER,
    UPDATE_DIRECTION_USER,
    ADD_DIRECTION_USER,
    CREATE_DIRECTIONS_USER,
    SELECT_EDIT_DIRECCION,
    REMOVE_SELECT_EDIT_DIRECTION,
    SELECT_DIRECTION,
    IS_LOADING,
    DIRECTION_ACTIVE,
    ERROR
} from "../types/directionsType";
import { directionByUser } from "services/form/direccionServices";
import { createDirectionByUserId } from "services/form/direccionServices";
import { updateDirection } from "services/form/direccionServices";
import { removeDirectionServices } from "services/form/direccionServices";
import { setAuthStorage } from "services/localStorage/authStorage";
import { updateActiveDirection } from "services/form/direccionServices";

export const getDirectionsUser = token => async dispatch => {
    dispatch({ type: IS_LOADING });

    const { response, ...rest } = await directionByUser(token);
    if (rest.status == "error") {
        if (rest.message["status"] == 412) {
            setAuthStorage();
            return dispatch({
                type: ERROR,
                payload: { error: "No autorizado" }
            });
        }
    }

    return dispatch({
        type: GET_DIRECTIONS_USER,
        payload: response
    });
};

export const createDirectionUser = body => async dispatch => {
    const response = await createDirectionByUserId(body);
    if (response.status == "error") {
        if (response.message["status"] == 412) {
            setAuthStorage();
            return dispatch({
                type: ERROR,
                payload: { error: "No autorizado" }
            });
        }
    }

    if (response.response.active === "1") {
        dispatch({
            type: DIRECTION_ACTIVE,
            payload: response.response
        });
    }
    return dispatch({
        type: ADD_DIRECTION_USER,
        payload: response.response
    });
};

// actualiza la direccion del usuario !!
export const updateDirectionUser = direction => async dispatch => {
    const response = await updateDirection(direction);

    if (response.status == "error") {
        if (response.message["status"] == 412) {
            setAuthStorage();
            return dispatch({
                type: ERROR,
                payload: { error: "No autorizado" }
            });
        }
    }

    if (response) {
        return dispatch({
            type: UPDATE_DIRECTION_USER,
            payload: response.response
        });
    }
};

export const removeSelectEditDirection = () => dispatch => {
    return dispatch({
        type: REMOVE_SELECT_EDIT_DIRECTION
    });
};

export const activeUserDirection = (direction, token) => async dispatch => {
    const response = await updateActiveDirection(direction.id, token);
    if (response.status == "error") {
        if (response.message["status"] == 412) {
            setAuthStorage();
            return dispatch({
                type: ERROR,
                payload: { error: "No autorizado" }
            });
        }
    }

    return dispatch({
        type: DIRECTION_ACTIVE,
        payload: direction
    });
};

export const selectEditDirection = direction => dispatch => {
    return dispatch({
        type: SELECT_EDIT_DIRECCION,
        payload: direction
    });
};

// selecciona la direccion a la que se le va a enviar los productos !!
export const selectDirection = (direction, token) => async dispatch => {
    await updateActiveDirection(direction.id, token);
    return dispatch({
        type: SELECT_DIRECTION,
        payload: direction
    });
};

export const removeDirection = (idDirection, token) => async dispatch => {
    try {
        const response = await removeDirectionServices(idDirection, token);
        if (response.status == "error") {
            if (response.message["status"] == 412) {
                setAuthStorage();
                return dispatch({
                    type: ERROR,
                    payload: { error: "No autorizado" }
                });
            }
        }

        return dispatch({
            type: DELETE_DIRECTION_USER,
            payload: idDirection
        });
    } catch (error) {
        return dispatch({
            type: ERROR,
            payload: error
        });
    }
};

// =================== logic ============================ //
const generatePathsDirections = async direccions => {
    if (!direccions.length) {
        return direccions;
    }
};
