import React, { useLayoutEffect, useState, useEffect } from "react";
import PropTypes from "prop-types";

const VideoYoutube = ({ videoPath, maxWidth }) => {
    const [width, setWidth] = useState(0);
    const [maxWidthVideo, setMaxWidthVideo] = useState(500);

    useEffect(() => {
        if (maxWidth) {
            setMaxWidthVideo(maxWidth);
        }
    }, []);

    useLayoutEffect(() => {
        function updateSize() {
            const container = document.getElementById("video-container");
            if (!container) return;

            const containerParent = container.parentElement;
            if (containerParent.offsetWidth >= maxWidthVideo) {
                setWidth(500);
            } else {
                setWidth(containerParent.offsetWidth);
            }
        }
        window.addEventListener("resize", updateSize);
        updateSize();
    }, [window.innerWidth]);

    return !videoPath ? null : (
        <iframe
            id="video-container"
            width={width}
            height="280" // REFACTOR: Buscar formula para hacer este valor relativo al ancho del video!!
            src={`https://www.youtube-nocookie.com/embed/${videoPath}`}
            frameborder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
        ></iframe>
    );
};

VideoYoutube.propTypes = {
    videoPath: PropTypes.string.isRequired,
    maxWidth: PropTypes.number
};

export default VideoYoutube;
