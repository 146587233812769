import React, { useEffect } from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";

import Info from "components/Typography/Info";
import style from "assets/jss/material-kit-pro-react/views/ecommerceStyle";

import { PREGUNTAS_FRECUENTES } from "config/const/footer";

const useStyles = makeStyles(style);

const PreguntasFrecuentes = () => {
    const classes = useStyles();

    useEffect(() => {
        goTop();
    }, []);

    const goTop = () => {
        window.scrollTo(0, 0);
        document.body.scrollTop = 0;
    };

    const preguntas = () => {
        return PREGUNTAS_FRECUENTES.map((item, i) => (
            <div key={`preguntas-${i}`} style={{ padding: "0 10px" }}>
                <Info>{item.title}</Info>
                <p>{item.parrafo}</p>
            </div>
        ));
    };

    return (
        <div
            className={classNames(
                classes.main,
                classes.mainRaised,
                classes.marginInfoContainer
            )}
        >
            <div className={classes.container}>
                <h2 className={classes.title}>Preguntas Frecuentes</h2>
                {preguntas()}
            </div>
        </div>
    );
};

export default PreguntasFrecuentes;
