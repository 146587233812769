export const INFO_LANDING_CARD = [
    {
        img: "registro",
        title: "¿Cómo puedo comenzar a canjear mis Puntos BBVA?",
        body: `Para comenzar a canjear debes estar registrado en esta web y tener tu Tarjeta de Crédito BBVA.`,
        navigator: "",
        modal: true,
        typeModal: "sesion"
    }
    // {
    //     icon: "question_answer",
    //     title: "¿Necesitas ayuda?",
    //     body: `Encuentra nuestro chat en línea en la esquina
    //             inferior de tu pantalla o haz click aquí para
    //             contactarnos.`,
    //     navigator: "",
    //     modal: true
    // }
];
