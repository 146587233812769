import React, { useEffect } from "react";

import "react-multi-carousel/lib/styles.css";

import { withRouter } from "react-router-dom";

// nodejs library that concatenates classes
import classNames from "classnames";

import Slider from "react-slick";

// redux
import { connect } from "react-redux";
import * as productsRecommendedActions from "../../redux/actions/productsRecommendedActions";
import * as carActions from "../../redux/actions/carActions";
import * as modalActions from "../../redux/actions/modalActions";

//config
import { pathCategoriMini, baseRouteImages } from "../../config/config.json";

// style
import styles from "assets/jss/material-kit-pro-react/views/ecommerceSections/productsStyle.js";
import { makeStyles } from "@material-ui/core/styles";

import CardProduct from "views/EcommercePage/Sections/CardProduct";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import { capitalize } from "utils/utils";

const useStyles = makeStyles(styles);

// REFACTOR: extraer esta configuracion, ha otro archivo !!

const SampleNextArrow = props => {
    const classes = useStyles();
    const { className, style, onClick } = props;
    return (
        <div
            className={classNames(className, classes.arrowCarousel)}
            onClick={onClick}
        ></div>
    );
};

const SamplePrevArrow = props => {
    const classes = useStyles();
    const { className, style, onClick } = props;
    return (
        <div
            className={classNames(className, classes.arrowCarousel)}
            onClick={onClick}
        ></div>
    );
};

const MultiCarousel = ({ categories, ...props }) => {
    const classes = useStyles();

    useEffect(() => {
        if (!props.productsRecommended.length && !categories) {
            props.getProductsRecommended();
        }
    }, []);

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 5,
        slidesToScroll: 1,
        autoplay: true,
        // autoplaySpeed: 5000,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true
                    // dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1
                    // initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1
                }
            }
        ]
    };

    const handleRouteImagen = img => {
        return `${baseRouteImages}${pathCategoriMini}${img}.jpg`;
    };

    const handleClassHeight = () => {
        if (props.headerReducer.sizeWindow <= 750) {
            return classes.cardHeightMovil;
        }

        return classes.cardHeight;
    };

    const handleRecomend = () => {
        if (!props.productsRecommended) return;
        if (!props.productsRecommended.length) return;
        return props.productsRecommended.map((product, index) => (
            <div className={classes.pr1} key={`CardProduct${index}`}>
                <CardProduct
                    product={product}
                    addToCar={values => props.addToCar(values)}
                    showModal={values => props.showModal(values)}
                    cardClass={classNames(classes.mbCard, handleClassHeight())}
                />
            </div>
        ));
    };

    const handleProducts = () => {
        const { categoriesReducer } = props;
        if (!categories) {
            return handleRecomend();
        }

        if (!!categoriesReducer.categories.length) {
            return categoriesReducer.categories.map((item, index) => (
                <div
                    className={classes.containerCard}
                    key={`carousel-${item.nombre}`}
                >
                    <Card
                        plain
                        grow
                        className={classNames(
                            classes.pointer,
                            classes.borderCard,
                            !categories ? "hover" : ""
                        )}
                        onClick={() =>
                            props.history.push(`./tienda/${item.slug}?page=1`)
                        }
                    >
                        <CardHeader
                            noShadow
                            image
                            className={!categories ? classes.imgInCarousel : ""}
                        >
                            <img
                                src={handleRouteImagen(item.imagen_mini)}
                                alt={item.nombre}
                            />
                            <div className={classes.opacityOverlay}></div>
                        </CardHeader>
                        <CardBody className={classes.categoriBody}>
                            <h3 className={classes.fz14}>
                                {capitalize(item.nombre)}
                            </h3>
                        </CardBody>
                    </Card>
                </div>
            ));
        }
    };

    return <Slider {...settings}>{handleProducts()}</Slider>;
};

const mapStateToProps = ({
    productsRecommended,
    carReducer,
    modalReducer,
    categoriesReducer,
    headerReducer
}) => {
    return {
        ...productsRecommended,
        categoriesReducer,
        modal: modalReducer,
        car: carReducer,
        headerReducer
    };
};

const mapDispatchToProps = {
    ...productsRecommendedActions,
    ...carActions,
    ...modalActions
};

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(MultiCarousel)
);
