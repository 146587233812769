import React, { useEffect } from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";

// redux
import { connect } from "react-redux";

import styles from "assets/jss/material-kit-pro-react/views/componentsSections/preFooter.js";
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";

const useStyles = makeStyles(styles);

const BannerSection = ({ bg, title, ...props }) => {
    const classes = useStyles();

    const handleHeightImage = () => {
        if (props.isMobile) {
            return "15vh";
        }

        return "35vh";
    };

    return (
        <div
            className={classNames(
                classes.subscribeLine,
                classes.subscribeLineImage,
                classes.centerFlex
            )}
            style={{
                backgroundImage: "url(" + bg + ")",
                backgroundSize: "cover",
                height: handleHeightImage()
            }}
        >
            <GridContainer>
                <GridItem xs={12} sm={12} md={12} className={classes.zIndex2}>
                    <div className={classes.textCenter}>
                        <h3
                            className={classNames(
                                classes.title,
                                props.isMobile ? classes.titleMovil : ""
                            )}
                        >
                            {title}
                        </h3>
                    </div>
                </GridItem>
            </GridContainer>
        </div>
    );
};

const mapStateToProps = ({ headerReducer }) => {
    return headerReducer;
};

export default connect(
    mapStateToProps,
    null
)(BannerSection);
