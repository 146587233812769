import {
    section,
    container,
    cardTitle,
    main,
    mainRaised,
    mrAuto,
    mlAuto,
    ffBook
} from "assets/jss/material-kit-pro-react.js";

const style = {
    container: {
        ...container,
        height: "70vh"
    },
    cardTitle,
    main,
    mainRaised,
    mrAuto,
    mlAuto,
    ffBook,
    heightAuto: {
        height: "auto"
    },
    centerFlex: {
        display: "flex",
        justifyContent: "center",
        flex: 1
    },
    selectFormControl: {
        marginTop: "11px"
    },
    marginContainer: {
        marginTop: "4em"   
    },
    marginText: {
        marginTop: ".8em"
    },
    textClaint: {
        marginBottom: ".1em"
    }
};

export default style;