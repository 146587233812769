import { GET_CATEGORIES } from "redux/types/categoriesTypes";

const INITIAL_STATE = {
    categories: [],
    isLoading: false,
    error: ""
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case GET_CATEGORIES:
            return { ...state, categories: action.payload };

        default:
            return state;
    }
};
