import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import profilePageStyle from "assets/jss/material-kit-pro-react/views/profilePageStyle.js";
import { useEffect, useState } from "react";
import ListItems from "./ListItems";

const useStyles = makeStyles(profilePageStyle);

const SelectDirection = ({
    selectId,
    isDisabled,
    selected,
    name,
    listItems,
    error,
    onChange,
    selectName
}) => {
    const [select, setSelect] = useState(0)
    const classes = useStyles();

    useEffect(() => {
      setSelect(selected)
      return () => {
        setSelect(0)
      }
    }, [selected, selectId])
    

    // if (!listItems.length) return <></>;

    const handleListItems = () => {
        if (!listItems.length) return null;

        return listItems.map((ubigeo, index) => (
            <MenuItem
                key={`select-${ubigeo[selectId]}-${index}`}
                classes={{
                    root: classes.selectMenuItem,
                    selected: classes.selectMenuItemSelected
                }}
                value={ubigeo[selectId]}
            >
                {ubigeo[selectName]}
            </MenuItem>
        ));
    };

    return (
        <FormControl
            fullWidth
            className={classes.selectFormControl}
            error={error}
        >
            <InputLabel
                htmlFor={selectId}
                className={classNames(classes.label, classes.fontLabel)}
            >
                {name}
            </InputLabel>
            <Select
                disabled={isDisabled}
                menuProps={{ className: classes.selectMenu }}
                classes={{
                    select: classNames(classes.select, classes.selectText)
                }}
                value={select}
                onChange={onChange}
                inputProps={{
                    name: selectId,
                    id: selectId
                }}
            >
                <MenuItem disabled classes={{ root: classes.selectMenuItem }}>
                    Seleccione un {name}
                </MenuItem>
                {handleListItems()}
                {/* <ListItems
                    listItems={listItems}
                    selectId={selectId}
                    selectName={selectName}
                /> */}
            </Select>
        </FormControl>
    );
};

SelectDirection.defaultProps = {
    isDisabled: false,
    listItems: [],
    error: false
};

SelectDirection.propTypes = {
    selectId: PropTypes.string.isRequired, // el id de la seleccion !!
    isDisabled: PropTypes.bool, // si esta disable
    selected: PropTypes.number, // El valor actual seleccionado
    name: PropTypes.string, // nombre del combo
    listItems: PropTypes.array, // listo del combo
    error: PropTypes.bool, // en caso de error, [true]
    onChange: PropTypes.func.isRequired, // dispara el cambio
    selectName: PropTypes.string // el key del nombre a mostrar Ej: [departamento, provincia]
};

export default SelectDirection;
