import React, { useEffect } from "react";

const FormVisa = ({ sessionKey, purchaseNumber, amount }) => {
    useEffect(() => {

        if (!sessionKey) return;

        const form = document.getElementById("formVisa");
        let script = document.createElement("script");

        script.setAttribute(
            "src",
            // "https://static-content-qas.vnforapps.com/v2/js/checkout.js?qa=true" // QA !!
            "https://static-content.vnforapps.com/v2/js/checkout.js" // production !!
        );
        script.setAttribute("data-sessiontoken", sessionKey);
        script.setAttribute("data-channel", "web");
        script.setAttribute("data-merchantid", "536876908"); // qa -> 602545705 product->536876908
        script.setAttribute(
            "data-merchantlogo",
            "https://puntosdelivery.com/Logo_PD_color.png"
        );
        script.setAttribute("data-formbuttoncolor", "#1d73b2");
        script.setAttribute("data-purchasenumber", purchaseNumber);
        script.setAttribute("data-amount", amount);
        script.setAttribute("data-showamount", true);
        script.setAttribute("data-expirationminutes", "15");
        script.setAttribute(
            "data-timeouturl",
            "https://puntosdelivery.com"
        );

        form.appendChild(script);

        return () => {
            // let padre = form.parentNode;
            form.removeChild(script);


            let modal = document.getElementById("visaNetWrapper");
            if (modal) {
                let padreModal = modal.parentNode;
                padreModal.removeChild(modal)
            }
        }
    }, [sessionKey]);

    return (
        <form
            action="https://apipuntos.rewardsaws.com/visa/form"
            id="formVisa"
            style={{ display: "none" }}
        ></form>
    ); // production !!

    // return (
    //     <form
    //         action="https://apipuntosqa.rewardsaws.com/visa/form"
    //         id="formVisa"
    //         style={{ display: "none" }}
    //     ></form>
    // ); // QA !!
};

export default FormVisa;
