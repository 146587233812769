import React, { useEffect, useState, useRef } from "react";
import { withRouter } from "react-router-dom";

import classNames from "classnames";

// core components
// import Accordion from "components/Accordion/Accordion.js";
import Button from "components/CustomButtons/Button.js";
// @material-ui/core components
import MenuItem from "@material-ui/core/MenuItem";
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import Close from "@material-ui/icons/Close";
import Checkbox from "@material-ui/core/Checkbox";
import Check from "@material-ui/icons/Check";

// custom icons
import SvgIcon from "components/Svg/SvgIcon";

//redux
import { connect } from "react-redux";
import * as directionsUserActions from "../../../redux/actions/directionsUserActions";
import * as modalActions from "../../../redux/actions/modalActions";

// servicios!!
import {
    getDepartamentos,
    getProvincias,
    getDistritos
} from "services/form/direccionServices";

// formik
import { Formik, Form } from "formik";
import * as Yup from "yup";

// styles !!
import profilePageStyle from "assets/jss/material-kit-pro-react/views/profilePageStyle.js";
import javascriptStyles from "assets/jss/material-kit-pro-react/views/componentsSections/javascriptStyles.js";
import basicsStyle from "assets/jss/material-kit-pro-react/views/componentsSections/basicsStyle.js";

// components
import Success from "components/Typography/Success.js";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import CustomInput from "components/CustomInput/CustomInput";
import { InputAdornment, Icon, FormControlLabel } from "@material-ui/core";
import Danger from "components/Typography/Danger";
import {
    validationNumber,
    validateSpecialCaracteres
} from "utils/validationsInputs";
import { TIPO_DIRECTION } from "config/const/constText";
import { capitalize, capitalizeFirst } from "utils/utils";
import SelectDirection from "./SelectDirection";
import { replaceTildes } from "utils/utils";
const useStyles = makeStyles(profilePageStyle);
const useStylesJs = makeStyles(javascriptStyles);
const useBasicStyles = makeStyles(basicsStyle);

// transition modal !!
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});

// esquema de validacion del formulario
const SignupSchema = Yup.object().shape({
    telefono_movil: Yup.string()
        .min(9, "Minimo 9 digitos")
        .max(15, "Maximo 15 digitos"),
    // .required("Numero de telefono es requerido"),
    direccion: Yup.string()
        .min(9, "Minimo 9 digitos")
        .max(70, "Maximo 70 digitos")
        .matches(/^[aA-zZ0-9.,\s]+$/, "no se permiten tildes")
        .required("La dirección es requerida"),
    referencia: Yup.string().max(80, "Maximo 80 digitos"),
    contacto: Yup.string().max(20, "Maximo 20 caracteres"),
    idProv: Yup.string().required("La provincia es requerida"),
    idDist: Yup.string().required("La provincia es requerida")
});

const INITIAL_DATA_FORM = {
    idDepa: 14,
    idProv: null,
    idDist: null,
    direccion: "",
    referencia: "",
    contacto: "",
    telefono_movil: "",
    telefono_fijo: "",
    etiqueta: "",
    active: true
};

// component !!
const FormularioDirecciones = props => {
    const classes = useStyles();
    const classesJS = useStylesJs();
    const classesBasic = useBasicStyles();

    const [departamento, setDepartamento] = useState([]);
    const [provincia, setProvincia] = useState([]);
    const [distrito, setDistrito] = useState([]);
    const [modal, setModal] = useState(false);
    const [formValues, setFormValues] = useState(INITIAL_DATA_FORM);
    const [firstRender, setFirstRender] = useState(true);
    
    useEffect(() => {
        setFormValues(() => handlePreloadDireccion());
    }, [props.directionActive]);

    useEffect(() => {
        requestDepartamento();
        if (!props.isLoading && !props.directionActive && !firstRender) {
            requestProvincia(formValues?.idDepa);
        }
    }, [props.isLoading]);

    useEffect(() => {
        return () => {};
    }, [provincia]);

    useEffect(() => {
        // if (!firstRender && !props.isLoading) {
            requestProvincia(formValues?.idDepa || formValues?.departamento);
        // }
    }, [formValues?.idDepa, formValues?.idProv]);

    useEffect(() => {
        if (!!formValues?.idProv || formValues?.provincia) {
            requestDistrito(formValues?.idProv || formValues?.provincia);
        }
    }, [formValues?.idProv, formValues?.provincia]);

    useEffect(() => {
        getDirections();

        // goTop();

            setFirstRender(() => false);

    }, []);

    const handlePreloadDireccion = () => {
        if (props.directionActive && !props.isLoading) {
            return {
                ...props.directionActive,
                active: true
            };
        } else {
            return INITIAL_DATA_FORM;
        }
    };

    const goTop = () => {
        window.scrollTo(0, 0);
        document.body.scrollTop = 0;
    };

    const getDirections = () => {
        props.getDirectionsUser(props.user.token);
    };

    // REFACTOR: pasar estos request de direcciones para el redux !!
    const requestDepartamento = async () => {
        const { response } = await getDepartamentos();

        setDepartamento(response);
    };

    const requestProvincia = async idDepartamento => {
        if (!idDepartamento) return;
        const { response } = await getProvincias(idDepartamento);

        setProvincia(response);
    };

    const requestDistrito = async idProvicia => {
        const { response } = await getDistritos(idProvicia);
        setDistrito(response);
    };

    // REFACTOR: pasar estos request de direcciones para el redux !!
    const handleSaveDirection = (
        {
            contacto,
            direccion,
            etiqueta,
            referencia,
            telefono_movil,
            telefono_fijo,
            active
        },
        { resetForm }
    ) => {
        const { user } = props;

        const data = {
            token: user.token,
            idDepa: formValues.idDepa,
            idProv: formValues.idProv,
            idDist: formValues.idDist,
            direccion: capitalizeFirst(direccion.trim()),
            referencia: referencia.trim()?.toLowerCase(),
            contacto: capitalize(contacto.trim()),
            telefono_movil,
            telefono_fijo,
            etiqueta,
            active: "1",
            // estos dos valores son para cuando se edita una direccion!!
            valor: props.directionActive ? props.directionActive.valor : null,
            idDirection: props.directionActive ? props.directionActive.id : null
        };

        handleCreateOrUpdate(data);
    };

    const handleDisabled = (isSubmitting) => {
        if (isSubmitting || !formValues) return true;


        const { idDepa, idDist, idProv, direccion, contacto, etiqueta, telefono_movil } = formValues
        const rules = [idDepa, idDist, idProv, direccion.trim(), contacto.trim(), etiqueta.trim(), telefono_movil ]

        return rules.some((rule) => !rule)
    }

    // crea o actualiza la direccion, dependiendo de si el usuario selecciono una direccion a actualizar !!
    const handleCreateOrUpdate = async data => {
        if (props.directionActive) {
            await props.updateDirectionUser(data);
        } else {
            await props.createDirectionUser(data);
        }

        handleResetValues(false);
        if (props.onClick) {
            props.onClick();
        }
        // detecta si el modal de agregar o editar direccion !!
        handleDetectAndHidenModal();

        // setModal(true); // aqui se mostraba el modal cuando se actualizaba o se creaba con exito la direccion !!
    };

    // detecta si el modal de edicion o creacion de direccion esta en pantalla
    // y lo esconde de ser asi !!
    const handleDetectAndHidenModal = () => {
        if (props.modal.formNewDirectionModal.show) {
            props.hidenModalFormDirections();
        }
        if (props.modal.formUpdateDirectionModal.show) {
            props.hidenModalFormUpdateDirections();
        }

    };

    // resetea los valores del formulario de direccion !!
    const handleResetValues = (remove = true) => {
        if (remove) {
            props.removeSelectEditDirection();
        }
        // setFormValues(INITIAL_DATA_FORM);
    };

    const handleOnChange = (event, handleChange) => {
        const target = event.target;
        const value =
            target.type === "checkbox" ? target.checked : target.value;
        const name = target.name;

        setFormValues({
            ...formValues,
            [name]: value
        });
        
        return handleChange(event);
    };

    // resetea los combos !!
    const resetCombox = (field) => {
        if ( field === "idDepa" ) {
            setFormValues((prev) => ({ ...prev, idProv: null, idDist: null }));
        } else if (field === "idProv") {
            setFormValues((prev) => ({ ...prev, idDist: null }));
        }
    };

    // Se dispara cuando se cambia el valor de cualquier select !!
    const handleChangeSelect = handleChange => (event) => {
        const prevValues = formValues || INITIAL_DATA_FORM
        const {name, value} = event.target
        setFormValues({
            ...prevValues,
            [name]: value
        });

        resetCombox(name)
        return handleChange(event);
    };

    const handleSelectedDepartamento = values => {
        return values?.idDepa || values?.departamento;
    };

    const handleSelectedProvincia = values => {
        const result = values?.idProv || values?.provincia;

        return result;
    };

    if (props.isLoading) return <></>;

    return (
        <>
            <Dialog
                classes={{
                    root: classesJS.modalRoot,
                    paper: classesJS.modal + " " + classesJS.modalSmall
                }}
                open={modal}
                TransitionComponent={Transition}
                keepMounted
                onClose={() => setModal(false)}
                aria-labelledby="small-modal-slide-title"
                aria-describedby="small-modal-slide-description"
            >
                <DialogTitle
                    id="small-modal-slide-title"
                    disableTypography
                    className={classesJS.modalHeader}
                >
                    <Success>Felicitaciones!</Success>
                    <Button
                        simple
                        className={classesJS.modalCloseButton}
                        key="close"
                        aria-label="Close"
                        onClick={() => setModal(false)}
                    >
                        {" "}
                        <Close className={classesJS.modalClose} />
                    </Button>
                </DialogTitle>
                <DialogContent
                    id="small-modal-slide-description"
                    className={
                        classesJS.modalBody + " " + classesJS.modalSmallBody
                    }
                >
                    <p>
                        Se {!!props.direccionEdit ? "Actualizo" : "Agrego"}{" "}
                        Correctamente su dirección
                    </p>
                </DialogContent>
            </Dialog>
            <Formik
                enableReinitialize
                initialValues={formValues}
                onSubmit={handleSaveDirection}
                validationSchema={SignupSchema}
                render={({
                    handleChange,
                    values,
                    touched,
                    errors,
                    isSubmitting
                }) => {
                    return (
                        <Form>
                            <GridContainer>
                                {/* // Etiqueta o nombre de la direccion */}
                                <GridItem xs={12} sm={12} md={12}>
                                    <CustomInput
                                        labelText={TIPO_DIRECTION}
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        error={
                                            !!errors?.etiqueta &&
                                            touched?.etiqueta
                                        }
                                        inputProps={{
                                            type: "text",
                                            name: "etiqueta",
                                            placeholder:
                                                "Ej: Trabajo, Oficina, Casa",
                                            onChange: value =>
                                                handleOnChange(
                                                    value,
                                                    handleChange
                                                ),
                                            value: values?.etiqueta,
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <SvgIcon
                                                        desc="icono-home"
                                                        fill="#626262"
                                                        width="20px"
                                                        height="20px"
                                                    />
                                                </InputAdornment>
                                            )
                                        }}
                                    />
                                    {errors?.etiqueta && touched?.etiqueta && (
                                        <Danger>{errors?.etiqueta}</Danger>
                                    )}
                                </GridItem>

                                <GridItem xs={12} sm={6} md={4}>
                                    <SelectDirection
                                        name="Departamento"
                                        selectId="idDepa"
                                        selectName="departamento"
                                        selected={handleSelectedDepartamento(
                                            values
                                        )}
                                        error={errors?.idDepa && touched?.idDepa}
                                        listItems={departamento}
                                        onChange={handleChangeSelect(
                                            handleChange
                                        )}
                                    />
                                </GridItem>

                                <GridItem xs={12} sm={6} md={4}>
                                    <SelectDirection
                                        name="Provincia"
                                        selectId="idProv"
                                        selectName="provincia"
                                        error={errors?.idProv && touched?.idProv}
                                        isDisabled={!provincia.length}
                                        selected={handleSelectedProvincia(
                                            values
                                        )}
                                        listItems={provincia}
                                        onChange={handleChangeSelect(
                                            handleChange
                                        )}
                                    />
                                </GridItem>
                                <GridItem xs={12} sm={6} md={4}>
                                    <SelectDirection
                                        name="Distrito"
                                        selectId="idDist"
                                        selectName="distrito"
                                        error={errors?.idDist && touched?.idDist}
                                        isDisabled={!distrito.length}
                                        selected={values?.idDist}
                                        listItems={distrito}
                                        onChange={handleChangeSelect(
                                            handleChange
                                        )}
                                    />
                                    {/* {selectBoxDistrito(values.idDist, handleChange, errors.idDist && touched.idDist)} */}
                                </GridItem>

                                <GridItem xs={12} sm={12} md={12}>
                                    <CustomInput
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        labelText="Dirección"
                                        error={
                                            !!errors?.direccion &&
                                            touched?.direccion
                                        }
                                        inputProps={{
                                            type: "text",
                                            placeholder: "Dirección",
                                            name: "direccion",
                                            // rows: 3,
                                            onChange: value =>
                                                handleOnChange(
                                                    value,
                                                    handleChange
                                                ),
                                            value: values?.direccion,
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <SvgIcon
                                                        desc="icono-home"
                                                        fill="#626262"
                                                        width="20px"
                                                        height="20px"
                                                    />
                                                </InputAdornment>
                                            )
                                        }}
                                    />
                                    {errors?.direccion && touched?.direccion && (
                                        <Danger>{errors?.direccion}</Danger>
                                    )}
                                </GridItem>

                                {/* // referencia */}
                                <GridItem xs={12} sm={12} md={12}>
                                    <CustomInput
                                        id="referencia"
                                        labelText="Referencia"
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        error={
                                            !!errors?.referencia &&
                                            touched?.referencia
                                        }
                                        inputProps={{
                                            type: "text",
                                            placeholder: "Referencia",
                                            name: "referencia",
                                            multiline: true,
                                            // rows: 3,
                                            onChange: value =>
                                                handleOnChange(
                                                    value,
                                                    handleChange
                                                ),
                                            value: values?.referencia,
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <SvgIcon
                                                        desc="icono-referencia"
                                                        fill="#626262"
                                                        width="20px"
                                                        height="20px"
                                                    />
                                                </InputAdornment>
                                            )
                                        }}
                                    />
                                    {errors?.referencia &&
                                        touched?.referencia && (
                                            <Danger>{errors?.referencia}</Danger>
                                        )}
                                </GridItem>

                                <GridItem xs={12} sm={12} md={12}>
                                    <CustomInput
                                        labelText="¿Quien recibira el pedido?"
                                        id="contacto"
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        error={
                                            !!errors?.contacto &&
                                            touched?.contacto
                                        }
                                        inputProps={{
                                            type: "text",
                                            name: "contacto",
                                            onChange: value =>
                                                handleOnChange(
                                                    value,
                                                    handleChange
                                                ),
                                            value: values?.contacto,
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <SvgIcon
                                                        desc="icono-name"
                                                        fill="#626262"
                                                        width="20px"
                                                        height="20px"
                                                    />
                                                </InputAdornment>
                                            )
                                        }}
                                    />
                                    {errors?.contacto && touched?.contacto && (
                                        <Danger>{errors?.contacto}</Danger>
                                    )}
                                </GridItem>

                                <GridItem xs={12} sm={6} md={6}>
                                    <CustomInput
                                        labelText="Teléfono celular"
                                        id="telefono_movil"
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        error={
                                            !!errors?.telefono_movil &&
                                            touched?.telefono_movil
                                        }
                                        inputProps={{
                                            type: "text",
                                            name: "telefono_movil",
                                            placeholder: "Numero de tlf",
                                            value: values?.telefono_movil,
                                            onChange: value =>
                                                handleOnChange(
                                                    value,
                                                    handleChange
                                                ),
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <SvgIcon
                                                        desc="icono-phone"
                                                        fill="#626262"
                                                        width="20px"
                                                        height="20px"
                                                    />
                                                </InputAdornment>
                                            )
                                        }}
                                    />
                                    {errors?.telefono_movil &&
                                        touched?.telefono_movil && (
                                            <Danger>
                                                {errors?.telefono_movil}
                                            </Danger>
                                        )}
                                </GridItem>
                                <GridItem xs={12} sm={6} md={6}>
                                    <CustomInput
                                        labelText="Teléfono fijo"
                                        id="telefono_fijo"
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        error={
                                            !!errors?.telefono_fijo &&
                                            touched?.telefono_fijo
                                        }
                                        inputProps={{
                                            type: "text",
                                            name: "telefono_fijo",
                                            onChange: value =>
                                                handleOnChange(
                                                    value,
                                                    handleChange
                                                ),
                                            value: values?.telefono_fijo,
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <SvgIcon
                                                        desc="icono-telefono-fijo"
                                                        fill="#626262"
                                                        width="22px"
                                                        height="22px"
                                                    />
                                                </InputAdornment>
                                            )
                                        }}
                                    />
                                    {errors?.telefono_fijo &&
                                        touched?.telefono_fijo && (
                                            <Danger>
                                                {errors?.telefono_fijo}
                                            </Danger>
                                        )}
                                </GridItem>

                                <GridItem xs={12} sm={12} md={12}>
                                    <Button
                                        color="info"
                                        size="sm"
                                        type="submit"
                                        disabled={handleDisabled(isSubmitting)}
                                    >
                                        Guardar
                                    </Button>
                                </GridItem>
                            </GridContainer>
                        </Form>
                    );
                }}
            />
        </>
    );
};

const mapStateToProps = ({ userReducer, directionsReducer, ModalsReducer }) => {
    return {
        ...userReducer,
        ...directionsReducer,
        modal: ModalsReducer
    };
};

const mapDispatchToProps = {
    ...directionsUserActions,
    ...modalActions
};

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(FormularioDirecciones)
);
