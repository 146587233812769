import React, { useEffect, useState } from "react";

import { withRouter } from "react-router-dom";

import classNames from "classnames";

import { makeStyles } from "@material-ui/core/styles";

import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

//redux
import { connect } from "react-redux";
import * as filterProductsActions from "../../../redux/actions/filterProductsActions";

import styles from "assets/jss/material-kit-pro-react/views/ecommerceStyle";

const useStyles = makeStyles(styles);

const INITIAL_ORDER = [
    { id: 0, typeOrder: "asc", orderBy: "marca", title: "Marca" },
    { id: 3, typeOrder: "", orderBy: "", title: "Nombre" },
    { id: 1, typeOrder: "asc", orderBy: "puntos", title: "Menor precio" },
    { id: 2, typeOrder: "desc", orderBy: "puntos", title: "Mayor precio" }
];

const SectionOrder = props => {
    const classes = useStyles();
    const [orderActive, setOrderActive] = useState(0);

    useEffect(() => {
        // reinicia el filtro de orden!!
        setOrderActive(0);
    }, [props.history.location.pathname]);

    // change order !!
    const handleChange = e => {
        const select = e.target.value;
        setOrderActive(select);
        const order = INITIAL_ORDER.find(o => o.id == select);

        props.orderBy(order);
    };

    const handleSelect = () => {
        return INITIAL_ORDER.map((orderFilter, index) => (
            <MenuItem
                key={`${orderFilter}-${index}`}
                classes={{
                    root: classes.selectMenuItem,
                    selected: classes.selectMenuItemSelected
                }}
                value={orderFilter.id}
            >
                {orderFilter.title}
            </MenuItem>
        ));
    };

    return (
        <div className={classes.container}>
            <GridContainer
                alignItems="center"
                className={classes.orderContainer}
            >
                <GridItem md={6} sm={6} xs={12}>
                    <h5
                        className={classNames(
                            classes.ffLight,
                            classes.textOrder
                        )}
                    >
                        Encontrados {props.totalProducts} productos
                    </h5>
                </GridItem>
                <GridItem md={2} sm={false} xs={false}></GridItem>
                <GridItem md={4} sm={6} xs={12}>
                    <FormControl
                        fullWidth
                        className={classes.selectFormControl}
                    >
                        <InputLabel
                            htmlFor="order"
                            className={classNames(
                                classes.fontLabel,
                                classes.fz19
                            )}
                        >
                            Ordenar por:
                        </InputLabel>
                        <Select
                            classes={{
                                select: classNames(
                                    classes.select,
                                    classes.selectMenu
                                )
                            }}
                            value={orderActive}
                            onChange={handleChange}
                            required={true}
                            inputProps={{
                                name: "order",
                                id: "order"
                            }}
                        >
                            {/* <MenuItem
                                disabled
                                classes={{
                                    root: classes.selectMenuItem
                                }}
                                value={0}
                            >
                                Ordenar por
                            </MenuItem> */}
                            {handleSelect()}
                        </Select>
                    </FormControl>
                </GridItem>
            </GridContainer>
        </div>
    );
};

const mapStateToProps = ({ filterProductsReducer }) => {
    return {
        filter: filterProductsReducer
    };
};

const mapDispatchToProps = {
    ...filterProductsActions
};

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(SectionOrder)
);
