import {
    main,
    mainRaised,
    container,
    textMute,
    colorMuteLight,
    colorWhite,
    infoColor
} from "assets/jss/material-kit-pro-react.js";

import imagesStyle from "assets/jss/material-kit-pro-react/imagesStyles.js";

import tooltipsStyle from "assets/jss/material-kit-pro-react/tooltipsStyle.js";

const styles = {
    container,
    ...imagesStyle,
    ...tooltipsStyle,
    containerCard: {
        display: "flex",
        justifyContent: "space-between",
        marginBottom: "1em"
    },
    borderBt: {
        borderBottom: "1px solid gray"
    },
    flex: {
        flex: "1"
    },
    row: {
        flexDirection: "row"
    },
    column: {
        flexDirection: "column"
    },
    m0: {
        margin: "0"
    },
    displayFlex: {
        display: "flex"
    },
    textMuteLight: {
        ...textMute,
        color: colorMuteLight
    },
    pl05: {
        paddingLeft: ".5em"
    },
    cardCategory: {
        fontSize: "0.75rem",
        textTransform: "none",
        fontWeight: "500"
    },
    textR: {
        textAlign: "right"
    },
    sizePoints: {
        fontSize: "0.7rem"
    },
    main: {
        ...main
    },
    mainRaised: {
        ...mainRaised
    },
    resetPd: {
        paddingLeft: "0",
        paddingRight: "0"
    },
    mainMarginTop: {
        marginTop: "6em"
    },
    marginTopRespuesta: {
        marginTop: "10em"
    },
    containerTotal: {
        display: "flex",

        flexDirection: "column"
    },
    containerCalculate: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between"
    },
    totalTextLeft: {
        display: "flex",
        alignItems: "center",
        flex: "1"
    },
    paddingHorizontal5: {
        padding: "0 5px"
    },
    colorWhite: {
        color: "#fff"
    },
    heigthSizeContainer: {
        height: "80vh",
        maxHeight: "1200px",
        minHeight: "800px",
        overFlow: "scroll"
    },
    heigthSizeContainerCheckout: {
        minHeight: "800px"
    },
    marginContainerCheckoutMobile: {
        marginBottom: "8em",
        marginTop: "8em"
    },
    mt4: {
        marginTop: "4em"
    },
    pdb27: {
        paddingBottom: "27px"
    },
    marginContainerMobile: {
        marginBottom: "20em",
        marginTop: "8em"
    },
    pt1: {
        paddingTop: "1px"
    },
    pt10: {
        paddingTop: "15px"
    },
    pt15: {
        paddingTop: "15px"
    },
    paddingTitle: {
        paddingTop: "30px"
    },
    noMarginHorizon: {
        marginLeft: "0",
        marginRight: "0"
    },
    cardHeader: {
        display: "flex",
        alignItems: "center"
    },
    textHeader: {
        margin: "0",
        paddingLeft: "10px",
        color: colorWhite
    },
    title: {
        paddingBottom: "9px",
        lineHeight: "1em"
    },
    textCenter: {
        textAlign: "center"
    },
    SizeH3: {
        fontSize: "1.5625rem"
    },
    centerVertica: {
        display: "flex",
        alignItems: "center"
    },
    containerResponesta: {
        marginTop: "8em"
    },
    colorBlue: {
        color: infoColor[0]
    },
    fz20: {
        fontSize: "20px",
        lineHeight: "22px"
    },
    fz14: {
        fontSize: "14px"
    },
    cardFooter: {
        padding: "1.23em",
        paddingTop: "0",
        marginTop: "-20px"
    },
    lineHeightNormal: {
        lineHeight: "normal"
    },
    boxCuponActivo: {
        display: "flex",
        alignItems: "center",
        backgroundColor: "#7f849238",
        padding: "0 0 0 15px",
        borderRadius: "10px"
    },
    infoTex: {
        marginBottom: 0,
        color: "#1d73b2",
        fontWeight: "bold",
        fontFamily: "BentonSansBBVA Medium"
    },
    // btnCupon: {
    //     marginBottom: "-6px"
    // },
    boxCupon: {
        display: "flex",
        flexDirection: "column",
        position: "relative",
        marginBottom: "25px"
    },
    errorCupon: {
        position: "absolute",
        bottom: "-25px"
    }

};

export default styles;
