/*eslint-disable*/
import React, { useState, useEffect, useRef } from "react";

// nodejs library that concatenates classes
import classNames from "classnames";

// react component used to create nice image meadia player
import ImageGallery from "react-image-gallery";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
import LocalShipping from "@material-ui/icons/LocalShipping";
import VerifiedUser from "@material-ui/icons/VerifiedUser";
import Favorite from "@material-ui/icons/Favorite";
import Add from "@material-ui/icons/Add";
import Remove from "@material-ui/icons/Remove";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import InfoArea from "components/InfoArea/InfoArea.js";
import CustomDropdown from "components/CustomDropdown/CustomDropdown.js";
import ReactImageZoom from "react-image-zoom";

import Info from "components/Typography/Info";
import Accordion from "components/Accordion/Accordion";

// utils
import {
    numberFormat,
    capitalizeFirst,
    combineFunc,
    lowerOrUpper,
    capitalize
} from "../../utils/utils";

import productStyle from "assets/jss/material-kit-pro-react/views/productStyle.js";
import styles from "assets/jss/material-kit-pro-react/views/ecommerceStyle";

// redux !!
import { connect } from "react-redux";
import * as productsActions from "../../redux/actions/productsActions";
import * as modalActions from "../../redux/actions/modalActions";
import * as carActions from "../../redux/actions/carActions";

// config
import {
    baseRouteImages,
    pathImagenBig,
    pathImagenMedium,
    pathImagenSmall,
    pathBanner
} from "../../config/config.json";
//config

import { PUNTOS } from "config/const/constText";

import SvgIcon from "components/Svg/SvgIcon";

// images
import noImagen from "assets/img/no-image.svg";
import Spinner from "components/spinner/Spinner";
import VideoYoutube from "components/Video/VideoYoutube";
import MultiCarousel from "components/MultiCarousel/MultiCarousel";
import { getPhotosProductByCode } from "services/home/productsServices";
import { MAS_PEDIDOS } from "config/const/constText";
import WrapperMovile from "hoc/WrapperMovile";
import "../../assets/css/imagenGallery.css";
import UseOnScrollDown from "Hooks/UseOnScrollDown/UseOnScrollDown";
import FixedBottom from "components/SectionFixed/FixedBottom";
import BasicInfo from "./components/BasicInfo";
import ShowPrice from "./components/ShowPrice";
import WrapperDeskto from "hoc/WrapperDeskto";
import { MAX_AMOUNT_ALLOWED } from "config/const/constText";

const useStyles = makeStyles(productStyle);
const usesEcomerce = makeStyles(styles);

// https://github.com/laurenashpole/react-inner-image-zoom
// REFACTOR: this component !!
const ProductPage = props => {
    const [images, setImages] = useState([]);
    // REFACTOR: este maximo del producto sera dinamico
    const [quiantity, setQuiantity] = useState(1);
    const [productPrice, setProductPrice] = useState({ puntos: 0, precio: 0 });
    const [totalPrice, setTotalPrice] = useState({ puntos: 0, precio: 0 });
    const [thumbnailPosition, setThumbnailPosition] = useState("left");
    const galleryRef = useRef(null);

    const classes = useStyles();
    const classesEcomerce = usesEcomerce();

    // scrolling es true cuando el usuario hace scrollDown
    const { scrolling } = UseOnScrollDown();

    const productSelect = props.productsReducer.selectProduct;

    useEffect(() => {
        return () => {
            setImages([]);
        };
    }, []);

    useEffect(() => {
        // @OJO aqui se puede llamar al api !!

        const {
            match: { params }
        } = props;
        const id = atob(params.id);
        setImages([]);
        getProductByIdForRedux(id);
        goTop();
    }, [props.match.params]);

    useEffect(() => {
        handleSetImage();
        handleInitialTotal();
    }, [props.productsReducer.selectProduct]);

    useEffect(() => {
        handleTotal();
    }, [quiantity]);

    // useEffect(() => {
    //     const position = props.sizeScreen.isMobile ? "left" : "bottom";
    //     setThumbnailPosition("position");
    // }, [props.sizeScreen.isMobile]);

    const goTop = () => {
        window.scrollTo(0, 0);
        document.body.scrollTop = 0;
    };

    const handleTotal = () => {
        const precio = productPrice.precio * quiantity;
        const puntos = productPrice.puntos * quiantity;

        setTotalPrice({ precio, puntos });
    };

    const handleSetImage = async () => {
        const product = props.productsReducer.selectProduct;
        if (!product) return;
        const fotos = await getPhotosProductByCode(product.codproducto);
        if (!fotos) {
            return;
        }

        // forma normal !!
        const imagesMap = fotos.map((img, i) => {
            let objImage = {
                original: sanitizeImg(img.nombreimagen, "medium"),
                // thumbnail: sanitizeImg(img.nombreimagen, "small"),
                fullscreen: sanitizeImg(img.nombreimagen, "large"),
                bulletClass: classes.bulletStyle,
                renderThumbInner: render => (
                    <img
                        src={sanitizeImg(img.nombreimagen, "small")}
                        onError={e =>
                            (e.target.src = sanitizeImg(
                                img.nombreimagen,
                                "large"
                            ))
                        }
                    />
                )
            };

            if (!props.sizeScreen.isMobile) {
                objImage.renderItem = e => {
                    return (
                        <ReactImageZoom
                            img={e.fullscreen}
                            zoomPosition={"original"}
                            width={400}
                            height={400}
                        />
                    );
                };
            }

            return objImage;
        });

        // // TRABAJANDO: organizar bien como se van a tratar las imagenes con respecto a los nombre y rutas !!
        setImages(imagesMap);
    };

    const handleInitialTotal = () => {
        const producto = props.productsReducer.selectProduct;
        if (!producto) return;

        const precio =
            producto.preciooferta != 0
                ? producto.preciooferta
                : producto.precio;

        const puntos =
            producto.puntosoferta != 0
                ? producto.puntosoferta
                : producto.puntos;

        setProductPrice({ precio, puntos });
        setTotalPrice({ precio, puntos });
    };

    /**
     * Busca el producto por [id]
     * Si el producto existe en el state, lo trae y no hace request !!
     * @param [string] id del producto a buscar
     */
    const getProductByIdForRedux = async id => {
        const reducer = props.productsReducer;

        if (reducer.selectProduct === id) return;

        if (
            !!reducer.products &&
            reducer.products.hasOwnProperty("data") &&
            !!reducer.selectProduct
        ) {
            if (
                !!reducer.products.data.length &&
                reducer.selectProduct.id !== id
            ) {
                const resultProduct = reducer.products.data.find(
                    produc => produc.id == id
                );
                !!resultProduct
                    ? props.setProductSelect(resultProduct)
                    : props.getProdutcById(id);
            }
            return;
        }

        props.getProdutcById(id);
        return;
    };

    /**
     * REFACTOR: Cuando se tenga los diferentes tamaños de las imagenes ahi que realizar otro tratamiento !!
     * Trata a una imagen la limpia y regresa el url de la imagen
     * @param {string} img Imagen a tratar
     */
    const sanitizeImg = (img, size) => {
        img = img.trim();

        switch (size) {
            case "small":
                return `${baseRouteImages}${pathImagenSmall}${img}`;
            case "medium":
                return `${baseRouteImages}${pathImagenMedium}${img}`;
            case "large":
                return `${baseRouteImages}${pathImagenBig}${img}`;
            default:
                return `${baseRouteImages}${pathImagenBig}${img}`;
        }
    };

    const handlePlusCant = () => {
        if (quiantity >= MAX_AMOUNT_ALLOWED) {
            return;
        }
        setQuiantity(quiantity + 1);
    };

    const handleLessCant = () => {
        if (quiantity <= 1) {
            return;
        }
        setQuiantity(quiantity - 1);
    };

    const handleShowModal = () => {
        const {
            id,
            marca,
            nombrecorto,
            imgproducto,
            precio,
            preciooferta,
            puntos,
            puntosoferta,
            codproducto,
            envio,
            idcategoria,
            costoenviovale
        } = props.productsReducer.selectProduct;

        const costoenvio = parseInt(
            props.productsReducer.selectProduct.costoenvio.replace(".00", "")
        );
        const soles = parseInt(preciooferta.replace(".00", ""));

        props.showModal({
            title: `${marca} ${nombrecorto}`,
            description: nombrecorto,
            cantidad: quiantity,
            precio: puntosoferta || puntos,
            uriImg: imgproducto,
            soles: soles || precio
        });

        props.addToCar({
            idcategoria,
            marca,
            codproducto,
            precio,
            envio,
            id,
            costoenvio,
            titulo: nombrecorto,
            precioOferta: preciooferta,
            cantidad: quiantity,
            precioFinal: totalPrice.puntos,
            puntos,
            puntosOferta: puntosoferta,
            imagen: sanitizeImg(imgproducto, "small"),
            costoenviovale
        });
    };

    // arodeon donde se muestran las caracteriticas del producto!!
    const arrAcordeon = () => {
        const arr = [];

        // si tiene descripcion, se agrega al array
        if (
            productSelect.hasOwnProperty("descripcion") &&
            !!productSelect.descripcion
        ) {
            const descripcion = {
                title: "Descripción",
                content: (
                    <p>
                        {combineFunc(productSelect.descripcion)(
                            lowerOrUpper,
                            capitalizeFirst
                        )}
                    </p>
                )
            };

            arr.push(descripcion);
        }

        // si tiene caracteristicas, se agrega al array
        if (
            productSelect.hasOwnProperty("caracteristicas") &&
            !!productSelect.caracteristicas.length
        ) {
            const caracteristicas = {
                title: "Características",
                content: (
                    <ul>
                        {productSelect.caracteristicas &&
                            productSelect.caracteristicas.map((value, i) => (
                                <li
                                    key={`caraterictica-${i}`}
                                    className={classes.ffBook}
                                >
                                    {value.caracteristica}
                                </li>
                            ))}
                    </ul>
                )
            };
            arr.push(caracteristicas);
        }

        // si tiene Video, se agrega al array
        if (productSelect.codvideo) {
            arr.push({
                title: "Video",
                content: <VideoYoutube videoPath={productSelect.codvideo} />
            });
        }

        return arr;
    };

    const setBannerCategory = () => {
        const { categories } = props;
        const { selectProduct } = props.productsReducer;
        if (!categories.length || !selectProduct) return;

        const index = categories.findIndex(
            cate => cate.id === selectProduct.idcategoria
        );

        const banner = !!categories[index].imagen
            ? categories[index].imagen
            : "Banner_Category_Entretenimiento"; // REFACTOR: imagen por defecto !!

        return `${baseRouteImages}${pathBanner}${banner}.jpg`;
    };

    const handleChangeDetailProduct = id => {
        const encripted = btoa(id);
        props.history.push(`../../producto/${encripted}`);
    };

    // regresa el precio o el precio de oferta de tenerlo !!
    const handleShowPrice = () => {
        return productSelect.preciooferta > 0
            ? productSelect.preciooferta
            : productSelect.precio;
    };

    const handleShowPoints = () => {
        if (productSelect.puntosoferta > 0) {
            return (
                <>
                    <p
                        className={classNames(
                            classes.decount,
                            classes.mainPrice
                        )}
                    >
                        {`${numberFormat(productSelect.puntos)} ${PUNTOS}`}
                    </p>
                    <Info>
                        <h3
                            className={classNames(
                                classes.mainPrice,
                                classes.priceMedium,
                                classes.fz16
                            )}
                        >
                            {`${numberFormat(
                                productSelect.puntosoferta
                            )} ${PUNTOS}`}
                        </h3>
                    </Info>
                </>
            );
        }

        return (
            <Info>
                <h3
                    className={classNames(
                        classes.mainPrice,
                        classes.priceMedium
                    )}
                >
                    {`${numberFormat(productSelect.puntos)} ${PUNTOS}`}
                </h3>
            </Info>
        );
    };

    //@OJO en caso que una imagen de error, se asignara una imagen por defecto !!
    const handleImagenError = event => {
        const errorImagen = images.map(img => {
            if (img.original == event.currentTarget.src) {
                return {
                    original: noImagen,
                    thumbnail: noImagen
                };
            }
            return img;
        });

        setImages(errorImagen);
    };

    return (
        <WrapperMovile>
            <div className={classes.productPage}>
                {/* <BannerSection bg={setBannerCategory()} /> */}
                <div className={classNames(classes.section)}>
                    <div
                        className={classNames(
                            classes.main,
                            classes.mainRaised,
                            classes.bgWhite
                        )}
                    >
                        <div className={classes.container}>
                            {!productSelect ? (
                                <div className={classes.flexCenter}>
                                    <Spinner />
                                </div>
                            ) : (
                                <GridContainer>
                                    <GridItem md={6} sm={12}>
                                        <ImageGallery
                                            ref={galleryRef}
                                            showFullscreenButton={false}
                                            showPlayButton={false}
                                            stopPropagation
                                            startIndex={0}
                                            lazyLoad={true}
                                            items={productSelect ? images : []}
                                            additionalClass="center-box"
                                            onImageError={handleImagenError}
                                            showThumbnails={
                                                !props.sizeScreen.isMobile
                                            }
                                            showBullets={
                                                props.sizeScreen.isMobile
                                            }
                                            thumbnailPosition={
                                                "bottom"
                                                // thumbnailPosition
                                            }
                                            showNav={true}
                                        />
                                    </GridItem>
                                    <GridItem
                                        md={6}
                                        sm={12}
                                        style={{ marginTop: "18px" }}
                                    >
                                        <BasicInfo />

                                        <div
                                            className={classes.containerPrince}
                                        >
                                            <ShowPrice
                                                pointsOffert={
                                                    productSelect.puntosoferta
                                                }
                                                points={productSelect.puntos}
                                                soles={handleShowPrice()}
                                            />
                                        </div>
                                        <Accordion
                                            active={0}
                                            activeColor="info"
                                            collapses={arrAcordeon()}
                                            hoverActive
                                        />
                                        <WrapperDeskto>
                                            <GridContainer
                                                className={classes.pickSize}
                                            >
                                                <GridItem md={6} sm={6} xs={6}>
                                                    <div>
                                                        <label
                                                            className={classNames(
                                                                classes.ffBook,
                                                                classes.fz16
                                                            )}
                                                        >
                                                            Cantidad
                                                        </label>
                                                        <div>
                                                            <Button
                                                                color="info"
                                                                outline
                                                                xsRoundFull
                                                                size="sm"
                                                                onClick={
                                                                    handleLessCant
                                                                }
                                                                disabled={
                                                                    quiantity <=
                                                                    1
                                                                }
                                                            >
                                                                <Remove />
                                                            </Button>
                                                            <span
                                                                className={
                                                                    classes.pdLefRig05
                                                                }
                                                            >
                                                                {quiantity}
                                                            </span>
                                                            <Button
                                                                color="info"
                                                                outline
                                                                xsRoundFull
                                                                size="sm"
                                                                onClick={
                                                                    handlePlusCant
                                                                }
                                                                disabled={
                                                                    MAX_AMOUNT_ALLOWED <=
                                                                    quiantity
                                                                }
                                                            >
                                                                <Add />
                                                            </Button>
                                                        </div>
                                                    </div>
                                                </GridItem>

                                                <GridItem
                                                    md={6}
                                                    sm={12}
                                                    xs={12}
                                                >
                                                    <div
                                                        className={
                                                            classes.textRight
                                                        }
                                                    >
                                                        <label
                                                            className={classNames(
                                                                classes.ffBook,
                                                                classes.fz16
                                                            )}
                                                        >
                                                            Total
                                                        </label>
                                                        <div>
                                                            <ShowPrice
                                                                points={
                                                                    totalPrice.puntos
                                                                }
                                                                soles={
                                                                    totalPrice.precio
                                                                }
                                                            />
                                                        </div>
                                                    </div>
                                                </GridItem>
                                            </GridContainer>

                                            {/* // btn agregar en screen deskto */}

                                            <GridContainer
                                                className={classes.pullRight}
                                            >
                                                <Button
                                                    color="bbva"
                                                    onClick={handleShowModal}
                                                >
                                                    Agregar a la bolsa &nbsp;{" "}
                                                    <i className="icon__shopping icon__shopping--small"></i>
                                                </Button>
                                            </GridContainer>
                                        </WrapperDeskto>
                                    </GridItem>
                                </GridContainer>
                            )}
                        </div>
                    </div>

                    {/* // carousel products resaltados */}
                    <div
                        className={classNames(
                            classes.features,
                            classes.textCenter,
                            classes.container,
                            classesEcomerce.marginSectionTop
                        )}
                    >
                        <h2
                            className={classNames(
                                classesEcomerce.titleCarousel,
                                classesEcomerce.sizeTitle,
                                classesEcomerce.textCenter
                            )}
                        >
                            {" "}
                            {MAS_PEDIDOS}
                        </h2>
                        <MultiCarousel
                            detailProduct={handleChangeDetailProduct}
                        />
                    </div>

                    {/* // section legals */}
                    <div
                        className={classNames(
                            classes.features,
                            classes.textCenter,
                            classes.container
                        )}
                    >
                        <GridContainer>
                            <GridItem md={4} sm={4}>
                                <InfoArea
                                    title="Entrega no mayor a 5 días"
                                    description="Tu canje será entregado de manera regular en un plazo de hasta 5 días hábiles a Lima Metropolitana o Callao, y hasta 15 días para provincias."
                                    icon={LocalShipping}
                                    iconColor="info"
                                    vertical
                                />
                            </GridItem>
                            <GridItem md={4} sm={4}>
                                <InfoArea
                                    title="Canjea con tranquilidad"
                                    description="Tus canjes se realizaran de manera 100% segura. Si no tienes los puntos suficientes completa tu canje pagando con tus tarjetas BBVA."
                                    icon={VerifiedUser}
                                    iconColor="success"
                                    vertical
                                />
                            </GridItem>
                            <GridItem md={4} sm={4}>
                                <InfoArea
                                    title="Productos deseados"
                                    description="Si no encuentras el producto de tu preferecia, déjanos un mensaje y buscaremos tu producto para que lo canjees con tus Puntos BBVA."
                                    icon={Favorite}
                                    iconColor="rose"
                                    vertical
                                />
                            </GridItem>
                        </GridContainer>
                    </div>
                    {/* // section legals end*/}
                </div>
            </div>

            {/* // section mobile scrolling top */}
            {scrolling && props.sizeScreen.isMobile && (
                <FixedBottom>
                    <div className={classes.flex}>
                        <Button
                            color="bbva"
                            className={classes.firstButton}
                            onClick={handleShowModal}
                        >
                            <i className="icon__shopping  icon__shopping--small"></i>
                            {/* <SvgIcon
                                desc="icon-shopping"
                                height="13px"
                                width="13px"
                                fill="#fff"
                                className={classes.iconButton}
                            /> */}
                            &nbsp;{` Agregar a la bolsa`}
                        </Button>
                        <CustomDropdown
                            textWhite
                            backgroundBbva
                            dropdownItemSmall
                            buttonProps={{
                                color: "bbva",
                                className: classes.btnCantidad
                            }}
                            dropPlacement="top"
                            buttonText={<span>{quiantity}</span>}
                            onClick={e => setQuiantity(e)}
                            dropdownList={[
                                "5",
                                { divider: true },
                                "4",
                                { divider: true },
                                "3",
                                { divider: true },
                                "2",
                                { divider: true },
                                "1"
                            ]}
                        />
                    </div>
                </FixedBottom>
            )}
        </WrapperMovile>
    );
};

const mapStateToProps = ({
    productsReducer,
    modalReducer,
    carReducer,
    categoriesReducer,
    headerReducer
}) => {
    return {
        productsReducer,
        categories: categoriesReducer.categories,
        modalReducer,
        car: carReducer,
        sizeScreen: headerReducer
    };
};

const mapDispatchToProps = {
    ...modalActions,
    ...productsActions,
    ...carActions
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ProductPage);
