export const GET_INFO = "get_info";

export const SET_INFO = "set_info";

export const REMOVE_INFO = "remove_info";

export const ERROR_USER = "error_user";

export const UPDATE_PROFILE = "update_profile";

export const GET_POINTS_USERS = "get_points_users";

export const LOADING_POINTS = "loading_points";

export const GET_USER_DATA = "get_user_data";

export const UPDATE_POINTS_USER = "update_points_user";

export const SET_CODE_VISA_POINT = "set_code_visa_point";