import React, { useState, useEffect } from "react";

// @material-ui/core components
import { getBanners } from "../../services/publicidad/publicidad";

//carousel
import CarouselMain from "components/MultiCarousel/CarouselMain";

//redux
import { connect } from "react-redux";

// styles
import { BackgroundLoader } from "./sections/BackgroundLoader.js";
import { BannerDesk } from "./sections/BannerDesk.js";
import BannerMobile from "./sections/BannerMobile";

// component !!
const BannerContainer = props => {
    const [bannerInfo, setBannerInfo] = useState(null);

    useEffect(() => {
        handleGetBanner();
    }, []);

    const handleGetBanner = async () => {
        const banner = await getBanners();

        if (!!banner) {
            setBannerInfo(banner);
        }
    };

    const handleGoRoute = (rute, newScreen) => {
        if (newScreen === "1") {
            window.open(rute, "_blank");
        } else {
            props.history.push(rute);
        }
    };

    // check return banner !!
    const handleRenderBanner = () => {
        return bannerInfo.map((banner, index) => {
            if (props.isMobile) {
                return (
                    <BannerMobile
                        key={`${banner.imgfondo}`}
                        banner={banner}
                        goRoute={handleGoRoute}
                    />
                );
            }
            return (
                <BannerDesk
                    key={banner.img_fondo_movil}
                    banner={banner}
                    goRoute={handleGoRoute}
                />
            );
        });
    };

    // retorno del componente !!
    return !bannerInfo ? (
        <BackgroundLoader />
    ) : (
        <CarouselMain>{handleRenderBanner()}</CarouselMain>
    );
};

const mapStateToProps = ({ headerReducer }) => {
    return headerReducer;
};

export default connect(
    mapStateToProps,
    null
)(BannerContainer);
