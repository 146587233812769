import { CLOSE_MODAL, SHOW_MODAL } from "../../types/modalTypes";

const INITIAL_STATE = {
    params: {
        title: "",
        uriImg: "",
        description: ""
    },
    show: false
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SHOW_MODAL:
            return {
                ...state,
                params: action.payload,
                show: true
            };

        case CLOSE_MODAL:
            return INITIAL_STATE;

        default:
            return state;
    }
};
