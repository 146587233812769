import React, { useEffect, useState } from "react";
import Slider from "nouislider";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Checkbox from "@material-ui/core/Checkbox";
import Tooltip from "@material-ui/core/Tooltip";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import TextField from '@material-ui/core/TextField';

// redux
import { connect } from "react-redux";
import * as productsActions from "../../../redux/actions/productsActions";
import * as marcasActions from "../../../redux/actions/marcasActions";
import * as filterProductsActions from "../../../redux/actions/filterProductsActions";
// router
import { withRouter } from "react-router-dom";

// @material-ui icons
import Check from "@material-ui/icons/Check";

// core components
import Accordion from "components/Accordion/Accordion.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import Button from "components/CustomButtons/Button.js";

import "./seccionStyle.css";

import styles from "assets/jss/material-kit-pro-react/views/ecommerceSections/productsStyle.js";
import { capitalizeFirst } from "utils/utils";
import TagsFilters from "./TagsFilters";

const useStyles = makeStyles(styles);


const SectionFilters = props => {
    const [checked, setChecked] = useState([]);
    const [isMobile, setIsMobile] = useState(false);
    const [maxRange, setMaxRange] = useState("");
    const [minRange, setMinRange] = useState("");
    const [allBrand, setAllBrand] = useState(false);

    const classes = useStyles();

    useEffect(() => {
        setMaxRange(props.productsReducer.max);
        setMinRange(props.productsReducer.min);

    }, [props.productsReducer.max, props.productsReducer.min]);

    useEffect(() => {
        setChecked([]);

        setMaxRange(props.productsReducer.max);
        setMinRange(props.productsReducer.min);
    }, [props.match.params.category]);

    useEffect(() => {
        // props.marcasReducer.marcas
        const { category } = props.match.params;
        props.getMarcasFroCategory(category);
        handleResetsFilters();
    }, [props.history.location.pathname]);

    useEffect(() => { // REFACTOR: here deprecate!!
        function updateSize() {
            // trabajando en mostrar la tarjeta o el fixed !!
            if (window.innerWidth <= 959) {
                setIsMobile(true);
            } else {
                setIsMobile(false);
            }
        }
        window.addEventListener("resize", updateSize);
        updateSize();

    }, [window.innerWidth]);

    useEffect(() => {
        handleIsCheckedBrand();
        return () => {
            // handleResetsFilters()
        }
    }, [props.filterProductsReducer.idmarca.length])

    const handleResetsFilters = () => {
        props.resetAllFilters();
    };

    const setFilterRange = () => {
        props.changeRank(minRange, maxRange);
    }

    const handleToggle = (index, id) => {
        const currentIndex = checked.indexOf(index);
        const newChecked = [...checked];
        if (currentIndex === -1) {
            newChecked.push(index);
            props.changeFilterMarcas(id);
        } else {
            newChecked.splice(currentIndex, 1);
            props.changeRemoveFilter(id);
        }

        setChecked(newChecked);
    };

    const handleChangeMinRange = (e) => {
        let newValue = parseInt(e.target.value);
        let maxValue = props.productsReducer.max;
        if (newValue <= 1 || newValue == NaN) {
            setMinRange(1)
        } else {
            setMinRange(newValue);
        }
        // if (newValue > maxValue) {
        //     setMinRange(maxValue - 1);
        // } else {
        //     setMinRange(newValue || 0);
        // }
    }

    const handleChangeMaxRange = (e) => {
        let newValue = e.target.value;
        let minValue = props.productsReducer.min;

        if (newValue < 0) {
            setMaxRange(2);
        } else {
            setMaxRange(newValue)
        }
    }

    // filtros !!
    const rangePriceStruct = () => ({
        title: "Rango de Puntos",
        content: (
            <CardBody className={classNames(classes.cardBodyRefine, classes.containerBodyRange)}>
                <div className={classes.containerRange}>
                    <TextField
                        label="desde"
                        placeholder="desde"
                        variant="outlined"
                        color="secondary"
                        type="number"
                        value={minRange}
                        onChange={handleChangeMinRange}
                        inputProps={{
                            style: { width: "65px", padding: "10px" }
                        }}
                    />
                    <TextField
                        label="hasta"
                        placeholder="hasta"
                        variant="outlined"
                        type="number"
                        color="secondary"
                        value={maxRange}
                        onChange={handleChangeMaxRange}
                        inputProps={{
                            style: { width: "65px", padding: "10px" }
                        }}
                    />
                </div>
                <div>
                    <Button
                        size="sm"
                        color="bbva"
                        onClick={setFilterRange}
                        className={classes.mt15}
                    >
                        Filtrar
                    </Button>
                </div>
            </CardBody>
        )
    });

    const handleSetEnvio = value => {
        const { envio } = props.filterProductsReducer;
        if (value == envio) {
            props.setEnvio("");
            return;
        }
        props.setEnvio(value);
    };

    const handleIsCheckedBrand = (index) => {
        const { idmarca } = props.filterProductsReducer;
        if (!idmarca.length) {
            return false;
        }
        return Boolean(idmarca.find(id => id === index))
    }

    const envioStruct = () => ({
        title: "Envío Nacional",
        content: (
            <div className={classes.containerEnvio}>
                <FormControlLabel
                    control={
                        <Checkbox
                            tabIndex={-1}
                            onClick={() => handleSetEnvio("P")}
                            checked={props.filterProductsReducer.envio === "P"}
                            checkedIcon={
                                <Check className={classes.checkedIcon} />
                            }
                            icon={<Check className={classes.uncheckedIcon} />}
                            classes={{
                                root: classes.checkRoot
                            }}
                        />
                    }
                    classes={{
                        label: classes.label
                    }}
                    label="Si"
                />

            </div>
        )
    });

    const marcasStruct = () => ({
        title: "Marcas",
        content: (
            <div className={classes.customExpandPanel}>
                <div
                    className={classNames(
                        classes.checkboxAndRadio,
                        allBrand ? "" : classes.containerMarcas
                    )
                    }
                >
                    {!props.marcasReducer.marcas.length
                        ? null
                        : props.marcasReducer.marcas.map((item, i) => {

                            return (
                                <FormControlLabel
                                    key={`${item.marca}-${i}`}
                                    control={
                                        <Checkbox
                                            tabIndex={-1}
                                            onClick={() =>
                                                handleToggle(i, item.idmarca)
                                            }
                                            checked={
                                                handleIsCheckedBrand(item.idmarca)
                                            }
                                            checkedIcon={
                                                < Check
                                                    className={
                                                        classes.checkedIcon
                                                    }
                                                />
                                            }
                                            icon={
                                                <Check
                                                    className={
                                                        classes.uncheckedIcon
                                                    }
                                                />
                                            }
                                            classes={{
                                                checked: classes.checked,
                                                root: classes.checkRoot
                                            }}
                                        />
                                    }
                                    classes={{
                                        label: classes.label
                                    }}
                                    label={capitalizeFirst(item.marca)}
                                />
                            );
                        })}
                </div>
                {!!props.marcasReducer.marcas.length && (
                    <span className={classes.verMasText} onClick={() => setAllBrand(!allBrand)}> { allBrand ? "Ver menos" : "Ver más"}</span>
                )}
            </div>
        )
    });

    //define si se muestra el rango de precios
    const handleShowFilter = () => {
        let filters = [marcasStruct()];
        // if (isMobile) { // REFACTOR: <-
        filters.unshift(rangePriceStruct);
        // }
        return filters;
    };
    //filtros end !!

    return (
        <GridItem md={3} sm={3}>
            <Card color="white">
                {/* <Tags /> */}
                <CardHeader className={classes.filterHeader}>
                    <div className={classes.boxFilterTitle}>
                        <h4 className={classes.titleFilter}>Filtrar</h4>
                        <Tooltip
                            id="tooltip-top"
                            title="Limpiar Filtros"
                            placement="top"
                            classes={{ tooltip: classes.tooltip }}
                        >
                            <h4
                                onClick={handleResetsFilters}
                                className={classes.deleteFilter}
                            >
                                Limpiar Filtros
                            </h4>
                        </Tooltip>
                        {/* <Clearfix /> */}
                    </div>
                    <div>
                        <TagsFilters />
                    </div>
                </CardHeader>
                <CardBody
                // className={classes.cardBodyRefine}
                //style={{ height: "80rem" }} // TODO: se esta forzando el alto de la tarjeta
                >
                    <Accordion
                        active={[0, 1, 2]}
                        activeColor="info"
                        classesSummary={classes.pt5}
                        classesDetails={classes.pt0}
                        collapses={[
                            rangePriceStruct(),
                            envioStruct(),
                            marcasStruct()
                        ]}
                    />
                </CardBody>
            </Card>
        </GridItem>
    );
};

const mapStateToProps = ({
    productsReducer,
    marcasReducer,
    filterProductsReducer
}) => {
    return {
        productsReducer,
        marcasReducer,
        filterProductsReducer
    };
};

const mapDispatchToProps = {
    ...productsActions,
    ...marcasActions,
    ...filterProductsActions
};

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(SectionFilters)
);
