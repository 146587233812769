import { USER } from "./const/constStorage";

import { objectToString, stringToObject } from "../../utils/utils";

// regresa los datos del usuario !!
export const getUserStorage = () => {
    const user = localStorage.getItem(USER);
    return !!user ? stringToObject(user) : {};
};

// agrega los datos del usuario al storage !!
export const setUserStorage = data => {
    localStorage.setItem(USER, objectToString(data));
};

// elimina usuario del storage !!
export const removeUserStorage = () => {
    localStorage.removeItem(USER);
};
