import React, { useLayoutEffect, useState } from "react";
import classNames from "classnames";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// router
import { Link } from "react-router-dom";

// redux
import { connect } from "react-redux";
import * as productsActions from "../../../redux/actions/productsActions";
import * as modalActions from "../../../redux/actions/modalActions";
import * as carActions from "../../../redux/actions/carActions";

// core components
import Accordion from "components/Accordion/Accordion.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import Button from "components/CustomButtons/Button.js";
import SectionFilters from "./SectionFilters";

import "./seccionStyle.css";

import noImagen from "assets/img/no-image.svg";

//config
import {
    baseRouteImages,
    pathImagenBig,
    pathImagenMedium,
    pathImagenSmall
} from "../../../config/config.json";

import styles from "assets/jss/material-kit-pro-react/views/ecommerceSections/productsStyle.js";
import Info from "components/Typography/Info";
import Spinner from "components/spinner/Spinner";
import { capitalize, combineFunc, sanitizeStr } from "utils/utils";
import Icon from "@material-ui/core/Icon";
import { numberFormat } from "utils/utils";
import Warning from "components/Typography/Warning";
import { PUNTOS } from "config/const/constText";
import CardProduct from "./CardProduct";

const useStyles = makeStyles(styles);

// componente interno, que se muestra si no hay productos !!
const NoProducts = () => {
    return (
        <GridItem xs={12} sm={12} md={6} lg={4}>
            <Card>
                <CardHeader noTop image={false}>
                    Lo sentimos{" "}
                    <Warning>
                        <Icon>announcement</Icon>
                    </Warning>
                </CardHeader>
                <CardBody>
                    <p>
                        La categoria seleccinada, no posee productos
                        relacionados
                    </p>
                </CardBody>
            </Card>
        </GridItem>
    );
};

const SectionProducts = props => {
    const [isMobile, setIsMobile] = useState(false);
    const [colorIcon, setColorIcon] = useState("");
    const classes = useStyles();

    useLayoutEffect(() => {
        function updateSize() {
            // trabajando en mostrar la tarjeta o el fixed !!
            if (window.innerWidth <= 959) {
                setIsMobile(false);
            } else {
                setIsMobile(true);
            }
        }
        window.addEventListener("resize", updateSize);
        updateSize();
    }, [window.innerWidth]);

    const showModalAddCar = product => {
        const {
            id,
            marca,
            nombrecorto,
            imgproducto,
            precio,
            preciooferta,
            puntos,
            puntosoferta,
            codproducto,
            ...props
        } = product;

        props.showModal({
            title: marca,
            description: nombrecorto,
            uriImg: imgproducto
        });

        props.addToCar({
            marca,
            codproducto,
            titulo: nombrecorto,
            precio,
            precioOferta: preciooferta,
            cantidad: 1,
            precioFinal: puntosoferta != 0 ? puntosoferta : puntos,
            puntos: puntos,
            puntosOferta: puntosoferta,
            imagen: handleRouteImage(imgproducto),
            id: id
        });
    };

    const handleRouteImage = image => {
        return `${baseRouteImages}${pathImagenSmall}${image}`;
    };

    const { products } = props;
    const productList = props.search
        ? products.searchProducts
        : products.products;
    
    const sizeGrid = !isMobile ? 12 : 9; // isMobile esta al revez !!

    return (
        <div className={(classes.section, classes.pd30)}>
            <div className={classes.container}>
                <GridContainer>
                    {/* // filtros !! */}
                    {!props.search && isMobile && <SectionFilters />}

                    {/* Productos */}
                    <GridItem md={sizeGrid} sm={sizeGrid}>
                        <GridContainer
                            justify={
                                products.isLoading ? "center" : "flex-start"
                            }
                        >
                            {products.isLoading && <Spinner />}

                            {!products.isLoading && !productList && (
                                <NoProducts />
                            )}

                            {!products.isLoading &&
                                !!productList &&
                                productList.data.map((product, i) => {
                                    return (
                                        <GridItem
                                            xs={6}
                                            sm={4}
                                            md={4}
                                            lg={!!props.search ? 3 : 3}
                                            key={`${i}-product-${product.codproducto}`}
                                            className={classNames(classes.mbCard, classes.horizontalPd5)}
                                        >
                                            <CardProduct
                                                product={product}
                                                addToCar={values =>
                                                    props.addToCar(values)
                                                }
                                                showModal={values =>
                                                    props.showModal(values)
                                                }
                                            />
                                        </GridItem>
                                    );
                                })}
                        </GridContainer>
                    </GridItem>
                </GridContainer>
            </div>
        </div>
    );
};

const mapStateToProps = ({ productsReducer, modalReducer, carReducer }) => {
    return {
        products: productsReducer,
        modal: modalReducer,
        car: carReducer
    };
};

const mapDispatchToProps = {
    ...productsActions,
    ...modalActions,
    ...carActions
};

export default connect(mapStateToProps, mapDispatchToProps)(SectionProducts);
