export const GET_DIRECTIONS_USER = "get_directions_user";

export const CREATE_DIRECTIONS_USER = "create_directions_user";

export const ADD_DIRECTION_USER = "add_direction_user";

export const UPDATE_DIRECTION_USER = "update_direction_user";

export const DELETE_DIRECTION_USER = "delete_direction_user";

export const SELECT_EDIT_DIRECCION = "select_edit_direccion";

export const REMOVE_SELECT_EDIT_DIRECTION = "remove_select_edit_direction";

export const IS_LOADING = "is_loading";

export const DIRECTION_ACTIVE = "direction_active";

export const ERROR = "ERROR";

// SELECCIONA LA DIRECCION PARA EL CANJE !!
export const SELECT_DIRECTION = "select_direction";
