/*eslint-disable*/
import React, { useEffect, useState } from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
import Camera from "@material-ui/icons/Camera";
import Palette from "@material-ui/icons/Palette";
import People from "@material-ui/icons/People";
import Add from "@material-ui/icons/Add";
import Favorite from "@material-ui/icons/Favorite";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import SvgIcon from "components/Svg/SvgIcon";

// core components
import SnackbarContent from "components/Snackbar/SnackbarContent.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Parallax from "components/Parallax/Parallax.js";
import Clearfix from "components/Clearfix/Clearfix.js";
import Button from "components/CustomButtons/Button.js";
import Accordion from "components/Accordion/Accordion.js";
import Datetime from "react-datetime";

// CAPCHAT
import ReCAPTCHA from "react-google-recaptcha";
import { CAPCHA_KEY } from "config/const/capchat";

// REDUX
import { connect } from "react-redux";
import * as formActions from "redux/actions/formActions";
import * as userActions from "redux/actions/userActions";
import * as modalActions from "redux/actions/modalActions";

import Email from "@material-ui/icons/Email";
import noAvatar from "../../assets/img/no-avatar.jpg";
import Danger from "components/Typography/Danger.js";

import profilePageStyle from "assets/jss/material-kit-pro-react/views/profilePageStyle.js";
import styles from "assets/jss/material-kit-pro-react/components/customInputStyle.js";
import Info from "components/Typography/Info";

// formik
import { Formik, Form } from "formik";
import * as Yup from "yup";

import CustomInput from "components/CustomInput/CustomInput";
import {
    InputAdornment,
    Icon,
    FormControl,
    InputLabel
} from "@material-ui/core";
import ContainerDireciones from "./sections/ContainerDireciones";
import { validationNumber } from "utils/validationsInputs";
import { PUNTOS } from "config/const/constText";
import ModalCreditCard from "components/Modal/ModalCreditCard";
import { capitalize } from "utils/utils";
import moment from "moment";
import { Suspense } from "react";

// esquema de validacion del formulario
const SignupSchema = Yup.object().shape({
    tarjeta: Yup.string()
        .matches(validationNumber, "Solo se permiten numeros")
        .nullable(),
    email: Yup.string()
        .email("El Email no es valido")
        .required("Email es requerido"),
    telefono: Yup.string()
        .matches(validationNumber, "Solo se permiten numeros")
        .max(11, "Maximo 11 digitos")
});

const useStyles = makeStyles(profilePageStyle);
const useStylesInput = makeStyles(styles);

const ProfilePage = props => {
    //@OJO el genero esta en hardcode !!
    const classes = useStyles();
    const classesInput = useStylesInput();

    const [genero, setGenero] = useState([
        { genero: "Masculino", value: "M" },
        { genero: "Femenino", value: "F" },
        { genero: "Prefiero no decirlo", value: "I" }
    ]);
    const [shoMessage, setShoMessage] = useState(false);
    const [submitting, setSubmitting] = useState(false);
    const [tokenCapchat, setTokenCapchat] = useState(null);
    const [directionUser, setDirectionUser] = useState(null);

    const { user } = props;

    useEffect(() => {
        props.getTypedocumentById(props.user.tipo_doc);

        return () => {
            setTokenCapchat(null);
        };
    }, []);

    useEffect(() => {
        setDirectionUser(props.directionsReducer);
    }, [
        props.directionsReducer.directions.length,
        props.directionsReducer.direccionEdit
    ]);

    // genera las opciones del select box !!
    const setSelect = () => {
        return genero.map((tipo, index) => (
            <MenuItem
                key={`select-${index}`}
                classes={{
                    root: classes.selectMenuItem,
                    selected: classes.selectMenuItemSelected
                }}
                value={tipo.value}
            >
                {tipo.genero}
            </MenuItem>
        ));
    };

    const setUserDataByRequest = values => {
        values.captcha = tokenCapchat;
        values.token = user.token;
        values.nombres = capitalize(values.nombres.trim());
        values.apellidos = capitalize(values.apellidos.trim());
        if (values.hasOwnProperty("tarjeta")) {
            delete values.tarjeta;
        }
        return values;
    };

    const onChangeCapcha = value => {
        setTokenCapchat(value);
    };

    const showChapchat = () => {
        return (
            <div className={classes.centerFlex}>
                <ReCAPTCHA sitekey={CAPCHA_KEY} onChange={onChangeCapcha} />
            </div>
        );
    };

    const handleSubmit = async values => {
        const fecha = document.getElementById("fecha_nac");
        values.fecha_nac = fecha.value;

        await setSubmitting(true);
        // chekear el guardado de perfil!!
        values = setUserDataByRequest(values);

        await props.setProfile(values);
        setShoMessage(true);

        await setSubmitting(false);
    };

    const handleShowMessage = () => {
        const success = {
            action: "Actualizacion",
            message: "Se actualizaron sus datos",
            color: "success"
        };
        const error = {
            action: "Error",
            message: props.error,
            color: "danger"
        };

        return (
            <SnackbarContent
                message={
                    <span>
                        <b>{!props.error ? success.action : error.action}: </b>
                        {!props.error ? success.message : error.message}
                    </span>
                }
                close
                color={!props.error ? success.color : error.color}
                icon="done_outline"
            />
        );
    };

    const imageClasses = classNames(
        classes.imgRaised,
        classes.imgRoundedCircle,
        classes.imgFluid
    );

    const handleShowTypedocument = () => {
        const { documento_identidad, tipo_doc } = props.user;
        let type = "DNI";
        if (tipo_doc === "E") {
            type = "CARNET EXTRANJERIA";
        } else if (tipo_doc === "P") {
            type = "Pasaporte";
        }

        return `${type}: ${documento_identidad}`;
    };

    const handleChangePhono = (event, handleChange) => {
        const value = event.target.value;
        event.target.value = value.replace(/[^\d]/g, "");
        if (value.length > 11) {
            event.target.value = value.slice(0, 11);
        }
        return handleChange(event);
    };

    return (
        <div>
            {/* <Parallax className={classes.parallax} image="https://crm.rewardsaws.com/_lib/file/img/banner/BANNER-BBVA.png" /> */}
            {/* <ModalCreditCard /> */}
            <div
                className={classNames(
                    classes.mainRaised,
                    classes.bgLightBlue,
                    classes.headerInfo
                )}
            >
                <div className={classes.container}>
                    <GridContainer justify="center">
                        <GridItem xs={12} sm={12} md={6}>
                            <div
                                className={classNames(
                                    classes.profile,
                                    classes.mb30
                                )}
                            >
                                <div className={classes.containerImgAvatar}>
                                    <img
                                        src={noAvatar}
                                        alt="Perfil"
                                        className={imageClasses}
                                    />
                                </div>
                                <div className={classes.name}>
                                    <h3
                                        className={classNames(
                                            classes.title,
                                            classes.nomarginBt
                                        )}
                                    >
                                        {props.user.nombres}
                                    </h3>
                                    <h6 className={classes.colorLightBlue}>
                                        Tienes {props.user.puntos || 0} {PUNTOS}{" "}
                                        disponibles
                                    </h6>
                                    <Clearfix />
                                </div>
                            </div>
                        </GridItem>
                    </GridContainer>
                </div>

                <GridContainer
                    justify="center"
                    className={classNames(
                        classes.bgWhite,
                        classes.preFooter,
                        classes.paddingContainer
                    )}
                >
                    <GridItem xs={12} sm={8} md={8}>
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                                flexDirection: "row"
                            }}
                        >
                            <div
                                className={classNames(
                                    classes.mt30,
                                    classes.from
                                )}
                            >
                                {handleShowTypedocument()}
                            </div>
                            {/* <div>
                                <Button
                                    color="info"
                                    size="md"
                                    type="buton"
                                    className={classes.mt15}
                                    onClick={props.toggleCreditCard}
                                >
                                    Registrar mi Tarjeta
                                </Button>
                            </div> */}
                        </div>
                        <Formik
                            initialValues={{
                                email: user.email,
                                genero: user.genero || "M",
                                nombres: user.nombres,
                                apellidos: user.apellidos,
                                tarjeta: user.tarjeta,
                                telefono: user.telefono,
                                fecha_nac: user.fecha_nac
                            }}
                            validationSchema={SignupSchema}
                            onSubmit={handleSubmit}
                            render={({
                                handleSubmit,
                                handleChange,
                                handleBlur,
                                isSubmitting,
                                values,
                                touched,
                                errors
                            }) => {
                                return (
                                    <Form className={classes.from}>
                                        <GridContainer>
                                            {/* // Nombres  */}
                                            <GridItem xs={12} sm={6} md={6}>
                                                <CustomInput
                                                    labelText="Nombres"
                                                    id="nombres"
                                                    formControlProps={{
                                                        fullWidth: true
                                                    }}
                                                    error={
                                                        !!errors.nombres &&
                                                        touched.nombres
                                                    }
                                                    inputProps={{
                                                        type: "text",
                                                        name: "nombres",
                                                        onChange: handleChange,
                                                        value: values.nombres,
                                                        startAdornment: (
                                                            <InputAdornment position="start">
                                                                <SvgIcon
                                                                    desc="icono-name"
                                                                    fill="#626262"
                                                                    width="20px"
                                                                    height="20px"
                                                                />
                                                            </InputAdornment>
                                                        )
                                                    }}
                                                />
                                                {errors.nombres &&
                                                    touched.nombres && (
                                                        <Danger>
                                                            {errors.nombres}
                                                        </Danger>
                                                    )}
                                            </GridItem>

                                            <GridItem xs={12} sm={6} md={6}>
                                                {/* // apellidos  */}
                                                <CustomInput
                                                    labelText="Apellidos"
                                                    id="apellidos"
                                                    formControlProps={{
                                                        fullWidth: true
                                                    }}
                                                    error={
                                                        !!errors.apellidos &&
                                                        touched.apellidos
                                                    }
                                                    inputProps={{
                                                        type: "text",
                                                        name: "apellidos",
                                                        onChange: handleChange,
                                                        value: values.apellidos,
                                                        startAdornment: (
                                                            <InputAdornment position="start">
                                                                <SvgIcon
                                                                    desc="icono-name"
                                                                    fill="#626262"
                                                                    width="20px"
                                                                    height="20px"
                                                                />
                                                            </InputAdornment>
                                                        )
                                                    }}
                                                />
                                                {errors.apellidos &&
                                                    touched.apellidos && (
                                                        <Danger>
                                                            {errors.apellidos}
                                                        </Danger>
                                                    )}
                                            </GridItem>

                                            <GridItem xs={12} sm={6} md={6}>
                                                <CustomInput
                                                    labelText="Correo"
                                                    id="email"
                                                    formControlProps={{
                                                        fullWidth: true
                                                    }}
                                                    error={
                                                        !!errors.email &&
                                                        touched.email
                                                    }
                                                    inputProps={{
                                                        type: "email",
                                                        name: "email",
                                                        onChange: handleChange,
                                                        value: values.email,
                                                        startAdornment: (
                                                            <InputAdornment position="start">
                                                                <SvgIcon
                                                                    desc="icono-mail"
                                                                    fill="#626262"
                                                                    width="20px"
                                                                    height="20px"
                                                                />
                                                            </InputAdornment>
                                                        )
                                                    }}
                                                />
                                                {errors.email &&
                                                    touched.email && (
                                                        <Danger>
                                                            {errors.email}
                                                        </Danger>
                                                    )}
                                            </GridItem>

                                            <GridItem xs={12} sm={6} md={6}>
                                                {/* // telefono */}
                                                <CustomInput
                                                    labelText="Número celular"
                                                    id="telefono"
                                                    formControlProps={{
                                                        fullWidth: true,
                                                        maxLength: 10
                                                    }}
                                                    error={
                                                        !!errors.telefono &&
                                                        touched.telefono
                                                    }
                                                    inputProps={{
                                                        type: "text",
                                                        name: "telefono",
                                                        onChange: e =>
                                                            handleChangePhono(
                                                                e,
                                                                handleChange
                                                            ),
                                                        value: values.telefono,

                                                        startAdornment: (
                                                            <InputAdornment position="start">
                                                                <SvgIcon
                                                                    desc="icono-phone"
                                                                    fill="#626262"
                                                                    width="20px"
                                                                    height="20px"
                                                                />
                                                            </InputAdornment>
                                                        )
                                                    }}
                                                />
                                                {errors.telefono &&
                                                    touched.telefono && (
                                                        <Danger>
                                                            {errors.telefono}
                                                        </Danger>
                                                    )}
                                            </GridItem>

                                            <GridItem
                                                xs={12}
                                                sm={6}
                                                md={6}
                                                className={classNames(
                                                    classes.paddingTopPiker,
                                                    classes.mtPickert
                                                )}
                                            >
                                                <InputLabel
                                                    className={classNames(
                                                        classes.label,
                                                        classes.fontLabel
                                                    )}
                                                >
                                                    Fecha de nacimiento
                                                </InputLabel>

                                                <Datetime
                                                    inputProps={{
                                                        name: "fecha_nac",
                                                        id: "fecha_nac",
                                                        readOnly: true
                                                    }}
                                                    defaultValue={
                                                        values.fecha_nac || ""
                                                    }
                                                    // onChange={e =>
                                                    //     handleChange(
                                                    //         moment(e._d).format(
                                                    //             "MM-DD-YYYY"
                                                    //         )
                                                    //     )
                                                    // }
                                                    timeFormat={false}
                                                />
                                            </GridItem>

                                            <GridItem
                                                xs={12}
                                                sm={6}
                                                md={6}
                                                className={classes.centerDate}
                                            >
                                                <FormControl
                                                    fullWidth
                                                    className={classNames(
                                                        classes.selectFormControl,
                                                        classes.ptFormControl
                                                    )}
                                                >
                                                    <InputLabel
                                                        htmlFor="genero"
                                                        className={classNames(
                                                            classes.selectLabel,
                                                            classes.fontLabel
                                                        )}
                                                    >
                                                        Género
                                                    </InputLabel>
                                                    <Select
                                                        MenuProps={{
                                                            className: classNames(
                                                                classes.selectMenu,
                                                                classes.mt0
                                                            )
                                                        }}
                                                        classes={{
                                                            select: classNames(
                                                                classes.select,
                                                                classes.selectText
                                                            )
                                                        }}
                                                        value={values.genero}
                                                        onChange={handleChange}
                                                        required={true}
                                                        inputProps={{
                                                            name: "genero",
                                                            id: "genero"
                                                        }}
                                                    >
                                                        <MenuItem
                                                            disabled
                                                            classes={{
                                                                root:
                                                                    classes.selectMenuItem
                                                            }}
                                                        >
                                                            Seleccione genero
                                                        </MenuItem>

                                                        {!!genero &&
                                                        !!genero.length
                                                            ? setSelect()
                                                            : null}
                                                    </Select>
                                                </FormControl>
                                            </GridItem>

                                            <GridItem xs={12} sm={12} md={12}>
                                                {shoMessage &&
                                                    handleShowMessage()}
                                            </GridItem>
                                            <GridItem
                                                xs={12}
                                                sm={12}
                                                md={12}
                                                className={classNames(
                                                    classes.mt30,
                                                    classes.flexCenter,
                                                    classes.flexColumn
                                                )}
                                            >
                                                {showChapchat()}
                                                <Button
                                                    // disabled={submitting || !tokenCapchat}
                                                    // disabled={submitting}
                                                    color="info"
                                                    size="lg"
                                                    type="submit"
                                                    className={classes.mt15}
                                                >
                                                    Guardar
                                                </Button>
                                            </GridItem>
                                        </GridContainer>
                                    </Form>
                                );
                            }}
                        />
                    </GridItem>

                    <GridItem xs={12} sm={8} md={8}>
                        {!!directionUser && (
                            <ContainerDireciones
                                direction={directionUser}
                            />
                        )}
                    </GridItem>
                </GridContainer>
            </div>
        </div>
    );
};

{
    // input de la tarjeta !!
    /* <GridItem xs={12} sm={6} md={6}>
                                                	
        <CustomInput
            labelText="Numero de Tarjeta"
            id="tarjeta"
            formControlProps={{
                fullWidth: true
            }}
            error={
                !!errors.tarjeta &&
                touched.tarjeta
            }
            inputProps={{
                type: "text",
                name: "tarjeta",
                onChange: handleChange,
                value:
                    values.tarjeta,
                startAdornment: (
                    <InputAdornment position="start">
                        <Icon
                            className={
                                classes.inputIconsColor
                            }
                        >
                            credit_card
                        </Icon>
                    </InputAdornment>
                )
            }}
        />
        {errors.tarjeta &&
            touched.tarjeta && (
                <Danger>
                    {errors.tarjeta}
                </Danger>
            )}
    </GridItem> */
}

const mapStateToProps = ({ userReducer, formReducer, directionsReducer }) => ({
    ...userReducer,
    formReducer,
    directionsReducer
});

const mapDispatchToProps = {
    ...formActions,
    ...userActions,
    ...modalActions
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ProfilePage);
