import {
    cyan,
    colorWhite
} from "assets/jss/material-kit-pro-react.js";

const cardDiscountStyle = {
    discount: {
        position: "absolute",
        top: 0,
        left: 0,
        width: 0,
        height: 0,
        borderLeft: `80px solid #f35e61`,
        borderBottom: "80px solid transparent",
        zIndex: "1"
    },
    discountInverted: {
        position: "absolute",
        top: 0,
        right: 0,
        width: 0,
        height: 0,
        borderRight: `80px solid #f35e61`,
        borderBottom: "80px solid transparent",
        zIndex: "1"
    },
    discountTextInverted: {
        position: "absolute",
        transform: "rotate(45deg)",
        color: colorWhite,
        top: "15px",
        right: "6px",
        zIndex: "2"
    },
    discountText: {
        position: "absolute",
        transform: "rotate(-45deg)",
        color: colorWhite,
        top: "12px",
        left: "12px",
        zIndex: "2"
    }
};

export default cardDiscountStyle;
