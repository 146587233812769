
import { validationNumber } from "utils/validationsInputs";
import * as Yup from "yup";

export const ShemaComplaintBook = Yup.object().shape({
    document_type: Yup.string()
        .required("El tipo de documento es requerido"),
    document_number: Yup.string()
        .max(15, "Máximo digitos permitidos 15")
        .when("document_type", (document_type, shema) => {
            return document_type == "L" ? shema.matches(validationNumber, "Solo se permiten numeros") : shema;
        })
        .required("Email es requerido"),
    fullname: Yup.string()
        .max(150, "Máximo 150 caracteres")
        .required("El nombre es requerido"),
    lastname: Yup.string()
        .max(150, "Máximo 150 caracteres")
        .required("El apellido es requerido"),
    email: Yup.string()
        .email("El correo no es valido")
        .max(150, "Máximo 150 caracteres")
        .required("El correo es requerido"),
    direction: Yup.string()
        .required("El dirección es requerido"),
    phone1: Yup.string()
        .matches(validationNumber, "Solo se permiten numeros")
        .max(11, "Máximo 11 numeros")
        .required("El telefono es requerido"),
    phone2: Yup.string()
        .matches(validationNumber, "Solo se permiten numeros")
        .max(11, "Máximo 11 numeros"),
    complaint: Yup.string()
        .max(1500, "Máximo 1500 caracteres")
        .required("El telefono es requerido"),
    order: Yup.string()
        .max(1500, "Máximo 1500 caracteres")
        .required("El telefono es requerido")
    // terms: Yup.number()
    //     .required("Los terminos son requeridos"),
});

export const INITIAL_VALUES = {
    document_type:"L",
    document_number: "",
    fullname: "",
    lastname: "",
    email: "",
    direction: "",
    phone1: "",
    phone2: "",
    complaint: "",
    order: "",
    terms: false
};