import {
    container,
    title,
    main,
    mainRaised,
    mlAuto,
    description,
    infoColor,
    bgFooter,
    colorLinkBgDark,
    colorTextFooter,
    ffBook
} from "assets/jss/material-kit-pro-react.js";

const contactUsStyle = {
    main,
    mainRaised,
    title,
    mlAuto,
    description,
    colorLinkBgDark,
    ffBook,
    container: {
        ...container,
        maxWidth: "970px !important"
    },
    contactContent: {
        paddingBottom: "40px",
        paddingTop: "40px"
    },
    bigMap: {
        height: "55vh",
        maxHeight: "550px",
        width: "100%",
        display: "block"
    },
    info: {
        paddingBottom: "10px",
        paddingTop: 0
    },
    textCenter: {
        textAlign: "center !important"
    },
    block: {
        // color: "inherit",
        padding: ".6rem 0.9375rem 0 0.9375rem",
        fontWeight: "500",
        fontSize: "12px",
        // textTransform: "uppercase",
        borderRadius: "0",
        textDecoration: "none",
        position: "relative",
        display: "block"
    },
    inlineBlock: {
        display: "inline-block",
        padding: "0px",
        width: "auto"
    },
    list: {
        marginBottom: "0",
        padding: "0",
        marginTop: "0"
    },
    left: {
        float: "left!important",
        display: "block"
    },
    right: {
        padding: "15px 0 5px 0",
        margin: "0",
        float: "right"
    },
    icon: {
        width: "18px",
        height: "18px",
        top: "3px",
        position: "relative"
    },
    fixedBt: {
        position: "fixed",
        bottom: "0"
    },
    noUpper: {
        textTransform: "none"
    },
    centradoFotter: {
        display: "flex",
        justifyContent: "center",
        flexDirection: "column"
    },
    fz15: {
        fontSize: "15px"
    },
    containerIconBeetrack: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginBottom: "-20px"
    },
    iconBeetrack: {
        color: infoColor[0],
        fontSize: "50px"
    },
    contactanos: {
        paddingLeft: "30px",
        paddingRight: "30px",
        textAlign: "justify",
        ...ffBook
    },
    contactanosTitle: {
        textAlign: "center",
        marginBottom: "10px"
    },
    bgFooter: {
        backgroundColor: bgFooter
    },
    colorText: {
        color: colorTextFooter
    },
    footer: {
        minHeight: "160px"
    },
    mt40: {
        marginTop: "40px"
    },
    copiStyle: {
        textAlign: "left",
        paddingLeft: "0.9375rem"
    },
    fz12: {
        fontSize: "12px"
    },
    logo: {
        paddingBottom: "25px",
        marginLeft: "0.9375rem",
        width: "256px",
        "@media (max-width: 550px)": {
            width: "165px"
        }
    }
};

export default contactUsStyle;
