import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import Discount from "components/Card/Discount";
// redux !!
import { connect } from "react-redux";
import { capitalize } from "utils/utils";
import productStyle from "assets/jss/material-kit-pro-react/views/productStyle.js";
import InfoTypeSend from "components/InfoTypeSend/InfoTypeSend";

const useStyles = makeStyles(productStyle);

const BasicInfo = ({ selectProduct, ...props }) => {
    const classes = useStyles();
    return (
        <>
            <Discount
                puntos={selectProduct.puntos}
                offer={selectProduct.puntosoferta}
                inverted
                show={!!selectProduct.puntosoferta}
            />
            <h1 className={classNames(classes.title, classes.titleProduct)}>
                {selectProduct.marca}
            </h1>
            <h3
                className={classNames(
                    classes.title,
                    classes.noTop,
                    classes.subTitle,
                    classes.mb10
                )}
            >
                {selectProduct.nombrecorto}
            </h3>

            <p
                style={{ lineHeight: "1em" }}
                className={classNames(classes.styleMarca, classes.m0)}
            >
                {`SKU: ${selectProduct.sku}`}
            </p>
            <p className={classNames(classes.styleMarca, classes.m0)}>
                {"Código Web: " + selectProduct.codproducto}
            </p>
            <InfoTypeSend envio={selectProduct.envio} />
        </>
    );
};

BasicInfo.propTypes = {};

const mapStateToProps = ({ productsReducer }) => productsReducer;

export default connect(
    mapStateToProps,
    null
)(BasicInfo);
