import {
    SET_SIZE_HEADER,
    IS_SHOW_SEARCH,
    IS_MOBILE
} from "redux/types/headerTypes";
import { SET_SIZE_INNERWIDTH } from "redux/types/headerTypes";

const INITIAL_STATE = {
    isSearch: false,
    isMobile: false,
    sizeHeader: 0,
    sizeWindow: 0
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SET_SIZE_HEADER:
            return { ...state, sizeHeader: action.payload };

        case IS_SHOW_SEARCH:
            return { ...state, isSearch: action.payload };

        case IS_MOBILE:
            return { ...state, isMobile: action.payload };

        case SET_SIZE_INNERWIDTH:
            return { ...state, sizeWindow: action.payload };

        default:
            return state;
    }
};
