import {
    title,
    container,
    colorWhite,
    ffLight
} from "assets/jss/material-kit-pro-react.js";

const bannerStyle = {
    title: {
        ...title,
        fontSize: "40px",
        lineHeight: "48px",
        fontWeight: "0",
        marginTop: "0",
        marginBottom: "5px",
        color: "#121212",
        fontWeight: "bold",
        ...ffLight
    },
    titleMovil: {
        ...title,
        fontSize: "40px",
        lineHeight: "28px",
        fontWeight: "0",
        marginTop: "0",
        marginBottom: "5px",
        color: "#121212",
        fontWeight: "bold",
        ...ffLight
    },
    infoTitle: {
        height: "60px"
    },
    infoParrafo: {
        height: "156px"
    },
    container: {
        ...container,
        zIndex: "2"
    },
    boxInfo: {
        backgroundColor: "red"
    },
    gridContainer: {
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        justifyContent: "center"
    },
    mt50: {
        marginTop: "50px"
    },
    sizeLaptop: {
        marginTop: "50px",
        height: "78vh",
        minHeight: "75vh"
    },
    parrafo: {
        ...ffLight,
        lineHeight: "24px",
        fontSize: "15px",
        color: "#121212"
    },
    paddingBody: {
        paddingLeft: "30px",
        paddingBottom: "25px",
        paddingRight: "25px"
    },
    sizeImg: {
        width: "inherit"
    },
    noBorder: {
        borderRadius: "0"
    },
    boxBtn: {
        marginTop: "-30px"
    },
    noMargin: {
        margin: "0"
    },
    noUpper: {
        textTransform: "none"
    },
    bgCard: {
        color: colorWhite,
        backgroundColor: "#f4f4f4cc"
    },
    fz14: {
        fontSize: "14px"
    },
    fz23: {
        fontSize: "23px"
    }
};

export default bannerStyle;
