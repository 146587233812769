export const GET_PRODUCTS = "get_products";

export const IS_LOADING = "is_loading";

export const ERROR = "error";

export const GET_PRODUCT_BY_ID = "get_product_by_id";

export const SET_PRODUCT_SELECT = "set_product_select";

export const DELETE_PRODUCT_SELECT = "delete_product_select";

export const SEARCT_PRODUTS = "searct_produts";