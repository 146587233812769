import React from "react";
import ReactDOM from "react-dom";
import AppRouter from "routers/AppRouter";

// redux !!
import { createStore, applyMiddleware, compose } from "redux";
import reducers from "./redux/reducers";
import { Provider } from "react-redux";
import reduxThunk from "redux-thunk";

// esta linea es para que funcione el redux tools !!
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(
    reducers,
    {}, // estado inicial !!
    composeEnhancers(applyMiddleware(reduxThunk))
);

ReactDOM.render(
    <Provider store={store}>
        <AppRouter />
    </Provider>,
    document.getElementById("root")
);
