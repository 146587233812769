import React from "react";
import PropTypes from "prop-types";
import { svgConst, svgMap } from "./svgMap";

/**
 * Este componente genera los svg mediante el archivo svgMap, donde estan todos los
 * svg que el banco nos provee !!
 */
const SvgIcon = ({ desc, height, width, fill, className, ...props }) => {
    if (!desc) return null; // validacion !!

    /**
     * busca el svg por su id y le agrega el atributo xlink que no se puede setear directo !!
     * @param {string} id del svg<h1 style= "font-size: 80px;font-family: tahoma, Helvetica, sans-serif;color: #f1f1f1;text-transform: uppercase;line-height: 70px;">¡una</h1> que se esta creando
     */
    const handleSetXlink = id => {
        const svg = document.getElementById(id);
        if (!svg) return;
        svg.setAttribute("xmlns:xlink", "http://www.w3.org/1999/xlink");
    };

    // busca el icono por la desc que viene de las propiedades en el mapa de iconos cargados
    const svgSelected = svgMap.find(
        s => s.desc.toLocaleLowerCase() === desc.toLocaleLowerCase()
    );

    const svgHeight = height || svgSelected.height;
    const svgFill = fill || svgSelected.fill;
    const svgWidth = width || svgSelected.width;

    return (
        <svg
            width={svgWidth}
            height={svgHeight}
            viewBox={svgSelected.viewBox}
            version={svgConst.version}
            xmlns={svgConst.xmlns}
            className={className}
        >
            {handleSetXlink(`svg-${svgSelected.id}`)}
            <desc>{svgSelected.desc}</desc>
            <path
                d={svgSelected.d}
                id={svgSelected.id}
                fill={svgFill}
                fillRule={svgSelected["fill-rule"]}
                stroke={svgSelected.stroke}
            />
        </svg>
    );
};

SvgIcon.propTypes = {
    desc: PropTypes.string.isRequired,
    fill: PropTypes.string,
    height: PropTypes.string,
    width: PropTypes.string
};

export default SvgIcon;
