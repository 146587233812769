import React from "react";
import { Link } from "react-router-dom";
// nodejs library that concatenates classes
import classNames from "classnames";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Button from "@material-ui/core/Button";
import Hidden from "@material-ui/core/Hidden";
import Drawer from "@material-ui/core/Drawer";

// core components
import styles from "assets/jss/material-kit-pro-react/components/headerStyle.js";
import SvgIcon from "components/Svg/SvgIcon";

const useStyles = makeStyles(styles);

export default function Header({
    color,
    links,
    fixed,
    absolute,
    brand,
    brand2,
    changeColorOnScroll,
    showCarInMobile,
    searchButtom,
    fullFluid,
    sizeWindow,
    isMobile
}) {
    const [mobileOpen, setMobileOpen] = React.useState(false);

    const [brandLogo, setBrandLogo] = React.useState(brand);

    const classes = useStyles();
    React.useEffect(() => {
        if (changeColorOnScroll) {
            window.addEventListener("scroll", headerColorChange);
        }
        return function cleanup() {
            if (changeColorOnScroll) {
                window.removeEventListener("scroll", headerColorChange);
            }
        };
    });
    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };
    const headerColorChange = () => {
        const windowsScrollTop = window.pageYOffset;
        if (windowsScrollTop > changeColorOnScroll.height) {
            setBrandLogo(brand);
            document.body
                .getElementsByTagName("header")[0]
                .classList.remove(classes[color]);

            document.body
                .getElementsByTagName("header")[0]
                .classList.add(classes[changeColorOnScroll.color]);
        } else {
            setBrandLogo(brand2);
            document.body
                .getElementsByTagName("header")[0]
                .classList.add(classes[color]);

            document.body
                .getElementsByTagName("header")[0]
                .classList.remove(classes[changeColorOnScroll.color]);
        }
    };

    const appBarClasses = classNames({
        [classes.appBar]: true,
        [classes[color]]: color,
        [classes.absolute]: absolute,
        [classes.fixed]: fixed
    });
    const toolContainer = classNames({
        [classes.container]: true,
        [classes.fullFluid]: sizeWindow <= 550 ? fullFluid : ""
    });
    return (
        <AppBar className={appBarClasses}>
            <Toolbar className={toolContainer}>
                <Button className={classes.title}>
                    <Link to="/">{brandLogo}</Link>
                </Button>
                <Hidden smDown implementation="css" className={classes.hidden}>
                    <div className={classes.collapse}>{links}</div>
                </Hidden>

                <Hidden mdUp>
                    <div
                        className={classNames(
                            classes.collapse,
                            classes.flexRight
                        )}
                    >
                        {searchButtom} {showCarInMobile}
                    </div>
                </Hidden>
                <Hidden mdUp>
                    <Button color="transparent" onClick={handleDrawerToggle}>
                        <SvgIcon desc="icon-menu" fill="#fff" />
                    </Button>
                </Hidden>
            </Toolbar>
            <Hidden mdUp implementation="js">
                <Drawer
                    variant="temporary"
                    anchor={"right"}
                    open={mobileOpen}
                    classes={{
                        paper: classes.drawerPaper
                    }}
                    onClose={handleDrawerToggle}
                >
                    {/* <IconButton
                        color="inherit"
                        aria-label="close drawer"
                        onClick={handleDrawerToggle}
                        className={classes.closeButtonDrawer}
                    >
                        <Close />
                    </IconButton> */}

                    <div
                        className={!isMobile ? classes.appResponsive : ""}
                        onClick={handleDrawerToggle}
                    >
                        {links}
                    </div>
                </Drawer>
            </Hidden>
        </AppBar>
    );
}

Header.defaultProp = {
    color: "white"
};

Header.propTypes = {
    color: PropTypes.oneOf([
        "primary",
        "info",
        "success",
        "warning",
        "danger",
        "transparent",
        "white",
        "rose",
        "dark",
        "navLink"
    ]),
    links: PropTypes.node,
    brand: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    fixed: PropTypes.bool,
    absolute: PropTypes.bool,
    // this will cause the sidebar to change the color from
    // props.color (see above) to changeColorOnScroll.color
    // when the window.pageYOffset is heigher or equal to
    // changeColorOnScroll.height and then when it is smaller than
    // changeColorOnScroll.height change it back to
    // props.color (see above)
    changeColorOnScroll: PropTypes.shape({
        height: PropTypes.number.isRequired,
        color: PropTypes.oneOf([
            "primary",
            "info",
            "success",
            "warning",
            "danger",
            "transparent",
            "white",
            "rose",
            "dark",
            "navLink"
        ]).isRequired
    })
};
