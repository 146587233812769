export const SHOW_MODAL = "show_modal";

export const CLOSE_MODAL = "close_modal";

// generic modals
export const SHOW_DIRECTIONS_MODAL = "show_directions_modal";

export const CLOSE_DIRECTIONS_MODAL = "close_directions_modal";

export const SHOW_NEW_DIRECTION_MODAL = "show_new_direction_modal";

export const CLOSE_NEW_DIRECTION_MODAL = "close_new_direction_modal";

export const SHOW_UPDATE_DIRECTION_MODAL = "show_update_direction_modal";

export const CLOSE_UPDATE_DIRECTION_MODAL = "close_update_direction_modal";

export const SHOW_MESSAGE_NO_POINTS_IN_CARD_MODAL =
    "show_message_no_points_in_card_modal";

export const CLOSE_MESSAGE_NO_POINTS_IN_CARD_MODAL =
    "close_message_no_points_in_card_modal";

export const TOGGLE_LOGIN_MODAL = "toggle_login_modal";

export const TOGGLE_BEETRACK_MODAL = "toggle_beetrack_modal";

export const TOGGLE_GLOBAL_LOADER_MODAL = "TOGGLE_GLOBAL_LOADER_MODAL";

export const TOGGLE_CREDIT_CARD_MODAL = "TOGGLE_CREDIT_CARD_MODAL";

// NOMBRES PARA IDENTIFICAR EL MODAL ACTIVO
export const DIRECTION = "directionsModal";
export const FORM_NEW_DIRECTION = "formNewDirectionModal";
export const FORM_UPDATE_DIRECTION = "formUpdateDirectionModal";
export const LOGIN_MODAL = "loginModal";
export const BEETRACK_MODAL = "beetrackModal";
export const GLOBAL_LOADER_MODAL = "globalLoader";
export const CREDIT_CARD = "creditCard";