import {
    container,
    description,
    cardTitle,
    title,
    blackColor,
    colorTextGray,
    whiteColor,
    grayColor,
    hexToRgb,
    colorClassLinks,
    colorLink
} from "assets/jss/material-kit-pro-react.js";

const signupPageStyle = theme => ({
    description,
    title,
    colorTextGray: {
        color: colorTextGray[0]
    },
    cardTitle: {
        ...cardTitle,
        color: whiteColor + "  !important"
    },
    container: {
        ...container,
        zIndex: "4",
        [theme.breakpoints.down("sm")]: {
            paddingBottom: "100px"
        }
    },
    pageHeader: {
        color: whiteColor,
        border: "0",
        height: "100%",
        margin: "0",
        display: "flex!important",
        padding: "120px 0",
        position: "relative",
        minHeight: "100vh",
        alignItems: "center",
        "&:before": {
            background: "rgba(" + hexToRgb(blackColor) + ", 0.5)"
        },
        "&:before,&:after": {
            position: "absolute",
            zIndex: "1",
            width: "100%",
            height: "100%",
            display: "block",
            left: "0",
            top: "0",
            content: '""'
        }
    },
    form: {
        margin: "0"
    },
    cardHeader: {
        width: "auto",
        textAlign: "center"
    },
    socialLine: {
        marginTop: "1rem",
        textAlign: "center",
        padding: "0"
    },
    inputIconsColor: {
        color: grayColor[13]
    },
    textCenter: {
        textAlign: "center"
    },
    iconButtons: {
        marginRight: "3px !important",
        marginLeft: "3px !important"
    },
    block: {
        color: "inherit",
        padding: "0.9375rem",
        fontWeight: "500",
        fontSize: "12px",
        textTransform: "uppercase",
        borderRadius: "0",
        textDecoration: "none",
        position: "relative",
        display: "block"
    },
    inlineBlock: {
        display: "inline-block",
        padding: "0px",
        width: "auto"
    },
    list: {
        marginBottom: "0",
        padding: "0",
        marginTop: "0"
    },
    left: {
        float: "left!important",
        display: "block",
        "&,& *,& *:hover,& *:focus": {
            color: whiteColor + "  !important"
        }
    },
    right: {
        padding: "15px 0",
        margin: "0",
        float: "right",
        "&,& *,& *:hover,& *:focus": {
            color: whiteColor + "  !important"
        }
    },
    icon: {
        width: "18px",
        height: "18px",
        top: "3px",
        position: "relative"
    },
    footer: {
        position: "absolute",
        width: "100%",
        background: "transparent",
        bottom: "0",
        color: whiteColor,
        zIndex: "2"
    },
    smallPassword: {
        display: "flex",
        justifyContent: "flex-end",
        marginTop: "-10px",
        color: "#777"
    },
    link: {
        textDecoration: "none",
        color: colorLink,
        fontSize: "14px"
    },
    linkLight: {
        textDecoration: "none",
        color: colorClassLinks[0],
        fontSize: "14px"
    },
    // custom
    noPaddingTop: {
        noPaddingTop: "0"
    },
    textCenter: {
        textAlign: "center"
    },
    centerFlex: {
        display: "flex",
        justifyContent: "center"
    },
    fontSizeTitle: {
        fontSize: "35px"
    },
    fontLabel: {
        fontFamily: "BentonSansBBVA Book",
        fontSize: "18px",
        transform: "translate(0, 1.5px) scale(0.75)",
        color: "#aaa"
    },
    showPassword: {
        cursor: "pointer"
    },
    modal: {
        display: "flex",
        alignItems: "center",
        minWidth: "300px"
    },
    ModalcreditCard: {
        padding: "26px",
        paddingTop: "35px"
    },
    mb0: {
        marginBottom: "0"
    },
    loader: {
        zIndex: "1400 !important"
    },
    rootCreditCard: {
        padding: "10px"
    }
});

export default signupPageStyle;
