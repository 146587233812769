const modalBeetrackStyles = theme => ({
    container: {
        display: "none",
        position: "fixed",
        zIndex: 1102,
        left: 0,
        top: 0,
        width: "100%",
        height: "100%",
        overflow: "auto",
        backgroundColor: "rgba(0,0,0,0.4)"
    },
    content: {
        padding: 0,
        backgroundColor: "#fff"
    },
    content2: {
        "&:first-child": {
            padding: 0
        }
    }
});

export default modalBeetrackStyles;
