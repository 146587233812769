import { GET_TYPE_DOCUMENT, GET_TYPE_DOCUMENT_BY_ID } from "../types/formTypes";
import {
	getTipoDocumento,
	getTypeDocumentById
} from "../../services/form/tipoDocumentoServices";

export const getTypeDocument = () => async dispatch => {
	const resp = await getTipoDocumento();

	return dispatch({
		type: GET_TYPE_DOCUMENT,
		payload: resp.response
	});
};

export const getTypedocumentById = id => async dispatch => {
	const resp = await getTypeDocumentById(id);

	return dispatch({
		type: GET_TYPE_DOCUMENT_BY_ID,
		payload: resp.response
	});
};
