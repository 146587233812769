import {
    section,
    container,
    cardTitle,
    coloredShadow,
    mlAuto,
    mrAuto,
    grayColor,
    dangerColor,
    infoColor,
    colorTextGray,
    whiteColor,
    corlosGrayDescuento,
    ffMedium,
    cyan
} from "assets/jss/material-kit-pro-react.js";

import customCheckboxRadioSwitch from "assets/jss/material-kit-pro-react/customCheckboxRadioSwitchStyle.js";

import tooltipsStyle from "assets/jss/material-kit-pro-react/tooltipsStyle.js";

const styles = {
    ...customCheckboxRadioSwitch,
    ...tooltipsStyle,
    checkRoot: {
        padding: "14px",
        "&:hover": {
            backgroundColor: "unset"
        }
    },
    coloredShadow,
    mlAuto,
    mrAuto,
    containerMarcas: {
        maxHeight: "300px",
        overflowY: "hidden"
    },
    verMasText: {
        color: infoColor[0],
        fontSize: "13px",
        cursor: "pointer"
    },
    cardTitle: {
        ...cardTitle,
        textAlign: "center",
        marginBottom: "0px !important",
        marginTop: "0",
        lineHeight: "23px"
    },
    titleCarousel: {
        ...cardTitle,
        textAlign: "center",
        marginBottom: "0px !important",
        fontSize: "1rem"
    },
    cardDescription: {
        textAlign: "left",
        fontSize: "13px",
        height: "25px",
        lineHeight: "normal",
        marginBottom: "13px"
    },
    container: {
        ...container
    },
    description: {
        color: grayColor[0]
    },
    section: {
        ...section,
        padding: "10px 0px"
    },
    sectionCustom: {
        ...section,
        padding: "0px"
    },
    priceContainer: {
        display: "inline-flex",
        flex: 1,
        justifyContent: "flex-start",
        alignItems: "center",
        paddingLeft: "5px"
    },
    price: {
        ...ffMedium,
        fontSize: "13px",
        color: infoColor[0],
        // color: "#121212",
        fontWeight: 300,
        textAlign: "center",
        lineHeight: "1.15em",
        marginBottom: "0",
    },
    borderHover: {
        "&:hover": {
            border: "0.2px solid #f4f4f4"
        }
    },
    carouselFontsize: {
        fontSize: "13px",
    },
    colorDecount: {
        color: cyan
    },
    cardHeight: {
        height: "433px"
    },
    cardHeightMovil: {
        height: "384px"
    },
    priceSoles: {
        fontFamily: "BentonSansBBVA Book",
        color: '#626262',
        margin: '0 0 10px',
        display: 'block',
        textAlign: "left"
    },

    descuento: {
        textDecoration: "line-through",
        color: corlosGrayDescuento,
        marginBottom: "0"
    },
    pullRight: {
        float: "right"
    },
    pointer: {
        cursor: "pointer"
    },
    cardHeaderImage: {
        position: "relative",
        padding: "0",
        zIndex: "1",
        marginLeft: "15px",
        marginRight: "15px",
        // marginTop: "-30px",
        borderRadius: "0",
        "& img": {
            width: "100%",
            borderRadius: "0",
            pointerEvents: "none"
        },
        "& a": {
            display: "block"
        }
    },
    justifyContentBetween: {
        WebkitBoxPack: "justify!important",
        justifyContent: "space-between !important"
    },
    customExpandPanel: {
        // maxHeight: "273px",
        overflowY: "auto",
        "&  label": {
            display: "block"
        }
    },
    categoriBody: {
        minHeight: "55px",
        padding: "0",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: whiteColor
    },
    containerBody: {
        paddingLeft: "6px",
        paddingRight: "6px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        alignItems: "flex-start",
    },
    noPaddingVertical: {
        paddingBottom: "0",
        paddingTop: "0"
    },
    priceSlider: {
        fontWeight: "500"
    },
    refineButton: {
        margin: "-3px 0"
    },
    cardBodyRefine: {
        paddingLeft: "15px",
        paddingRight: "15px"
    },
    textLeft: {
        textAlign: "left"
    },
    containerCarousel: {
        width: "100%",
        height: "500px"
    },
    containerListCarousel: {
        padding: "20px"
    },
    pt_1em: {
        paddingTop: "1em"
    },
    fz12: {
        fontSize: "12px"
    },
    fz14: {
        fontSize: "14px"
    },
    mb0: {
        marginBottom: "0"
    },
    pd30: {
        paddingTop: "30px"
    },
    separatorFilterEnvio: {
        paddingLeft: "2rem"
    },
    boxIcon: {
        padding: "0 6px",
        paddingRight: ".7rem",
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
        cursor: "pointer"
    },
    mbCard: {
        marginBottom: "10px"
    },
    hAuto: {
        height: "auto"
    },
    noMarginBottom: {
        marginBottom: "0",
    },
    containerCard: {
        paddingRight: "10px",
        paddingLeft: "10px"
    },
    envio: {
        color: "#626262",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        fontSize: "12px"
    },
    deleteFilter: {
        display: "flex",
        flex: "1",
        justifyContent: "flex-end",
        fontSize: "13px",
        color: colorTextGray[0],
        fontWeight: "normal",
        cursor: "pointer",
        textDecoration: "underline",
        "&:hover": {
            color: dangerColor[0]
        }
    },
    titleFilter: {
        display: "flex",
        flex: "1",
        color: infoColor[0],
        fontFamily: "BentonSansBBVA Book"
    },
    boxFilterTitle: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        // borderBottom: "1px solid #ddd",
        paddingBottom: "10px"
    },
    arrowCarousel: {
        "display": "block",
        "background": "#b5acac61",
        "width": "40px",
        "height": "40px",
        "borderRadius": "50%",
        "zIndex": "99",
        "&::before": {
            "marginTop": "6px",
            "textAlign": "center"
        }
    },
    arrowNext: {
        marginRight: "10px",
    },
    arrowPrevius: {
        marginLeft: "10px",
    },
    bgRed: {
        backgroundColor: "red"
    },
    imgInCarousel: {
        padding: "10px"
    },
    borderCard: {
        border: "1.4px solid #f4f4f4"
    },
    opacityOverlay: {
        position: "absolute",
        top: 0,
        bottom: 0,
        right: 0,
        left: 0,
        opacity: 0,
        background: "#072146",
        transition: "0.9s ease",
        "&:hover": {
            opacity: "0.3"
        }
    },
    containerBodyRange: {
        padding: "0",
        paddingTop: "10px",
        marginTop: "10px",
        display: "flex",
        flexDirection: "column"
    },
    containerRange: {
        justifyContent: "space-between",
        display: "flex",
    },
    pointer: { // chekear y eliminar
        cursor: "pointer"
    },
    horizontalPd5: {
        paddingLeft: "5px",
        paddingRight: "5px"
    },
    containerSend: {
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center"
    },
    ml5: {
        marginLeft: "5px"
    },
    ml3: {
        marginLeft: "3px"
    },
    pt5: {
        paddingTop: "5px"
    },
    pt0: {
        paddingTop: "0"
    },
    chipRoot: {
        marginRight: "5px",
        marginTop: "5px",
        fontSize: "11px"
    },
    mt15: {
        marginTop: "15px"
    },
    mlMenos5: {
        marginLeft: "-5px"
    },
    pr1: {
        paddingRight: "1px"
    },
    filterHeader: {
        padding: "15px 0",
        borderBottom: "1px solid #ddd"
    },
    checkRoot: {
        padding: "5px 14px"
    },
    containerEnvio: {
        marginTop: "10px"
    }
};

export default styles;
