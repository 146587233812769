import React from 'react';
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from 'prop-types';
import productStyle from "assets/jss/material-kit-pro-react/views/productStyle.js";
import { PUNTOS } from 'config/const/constText';
import { numberFormat } from 'utils/utils';
import Info from 'components/Typography/Info';

const useStyles = makeStyles(productStyle);

const ShowPrice = ({ points, pointsOffert, soles, ...props }) => {
    const classes = useStyles();

    const handleShowPoint = () => {
        const price = pointsOffert || points;
        return `${numberFormat(price)} ${PUNTOS}`;
    }

    return (
        <>
            {
                !!pointsOffert && (
                    <p
                        className={classNames(
                            classes.decount,
                            classes.mainPrice
                        )}
                    >
                        {`${numberFormat(points)} ${PUNTOS}`}
                    </p>
                )
            }
            <Info>
                <h3
                    className={classNames(
                        classes.mainPrice,
                        classes.priceMedium
                    )}
                >
                    {handleShowPoint()}
                </h3>
            </Info>
            <p className={classNames(classes.mainPrice, classes.m0)}>
                o S/
                {numberFormat(soles)}
            </p>
        </>
    )
}

ShowPrice.propTypes = {
    pointsOffert: PropTypes.number,
    points: PropTypes.number.isRequired,
    soles: PropTypes.number,
}

export default ShowPrice;