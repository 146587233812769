import {
	GET_CAR,
	ADD_CAR,
	REMOVE_CAR_BY_ID,
	REMOVE_ALL_CAR,
	SUBTRACT_ITEM_CAR,
	SET_PRODUTS
} from "redux/types/carTypes";

//TRABAJANDO: definir la estructura del los items del carrito de compras !!
const INITIAL_STATE = {
	items: [],
	cant: 0
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case GET_CAR:
			return action.payload;
		case SET_PRODUTS:
			return { ...action.payload }
		case ADD_CAR:
			return { ...action.payload };
		case SUBTRACT_ITEM_CAR:
			return { ...action.payload };
		case REMOVE_CAR_BY_ID:
			return { ...action.payload };
		case REMOVE_ALL_CAR:
			return INITIAL_STATE;
		default:
			return state;
	}
};
