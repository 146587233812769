import React, { useState } from "react";
import { withRouter } from "react-router-dom";

// nodejs library that concatenates classes
import classNames from "classnames";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

//redux!!
import { connect } from "react-redux";
import * as modalActions from "redux/actions/modalActions";

import Info from "components/Typography/Info";
import RemoveRedEye from "@material-ui/icons/RemoveRedEye";
import AccountCircle from "@material-ui/icons/AccountCircle";

// core components
import GridContainer from "components/Grid/GridContainer";
import Button from "components/CustomButtons/Button.js";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import styles from "assets/jss/material-kit-pro-react/views/infoPageStyle";
import { INFO_LANDING_CARD } from "config/const/ladingCardInfo";
import { CONTACTANOS } from "config/const/footer";
import registro from "assets/img/Registro.svg";
import transporte from "assets/img/transporte.svg";
import ModalBeetrack from "components/Modal/ModalBeetrack";

const useStyles = makeStyles(styles);

const SectionInfo = props => {
    const classes = useStyles();
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [contentModal, setContentModal] = useState("");

    const [showModalLogin, setShowModalLogin] = useState(false);

    const handleCloseModal = () => {
        setModalIsOpen(false);
    };

    const handleOpenModal = () => {
        setContentModal(
            <div className={classes.containerModal}>{CONTACTANOS}</div>
        );
        setModalIsOpen(true);
    };

    const handleGoRoute = route => {
        props.history.push(route);
    };

    const handleClickCard = (modal, navigator) => {
        if (modal && !Object.entries(props.userReducer.user).length) {
            props.toggleLoginModal();
            return;
        }
        handleGoRoute(navigator);
    };

    const handleToggleModalLogin = () => {
        setShowModalLogin(!showModalLogin);
    };

    const handleBeetrackModal = () => {
        props.togglebeetrackTrack();
    };

    const handleImage = img => {
        switch (img) {
            case "registro":
                return registro;

            case "transporte":
                return transporte;

            default:
                return registro;
        }
    };

    return (
        <GridContainer>
            {INFO_LANDING_CARD.map((info, i) => {
                return (
                    <GridItem
                        md={6}
                        sm={12}
                        className={classNames(classes.pointer)}
                        key={`infoLanding-${i}`}
                        onClick={() =>
                            handleClickCard(info.modal, info.navigator)
                        }
                    >
                        <Card color="white">
                            <CardBody className={classes.minHgtCard}>
                                <div className={classes.cardInfoLanding}>
                                    {/* <Icon className={classes.iconBeetrack}>
                                        {info.icon}
                                    </Icon> */}
                                    <img
                                        style={{ height: "100px" }}
                                        src={handleImage(info.img)}
                                    />
                                </div>
                                <h3 className={classNames(classes.cardTitle)}>
                                    {info.title}
                                </h3>

                                <p className={classes.cardDescription}>
                                    {" "}
                                    {info.body}{" "}
                                </p>
                                <div className={classes.actionBanner}>
                                    <AccountCircle
                                        className={classes.colorInfo}
                                    />
                                    <Info className={classes.pl5}>
                                        Ingresa aquí para comenzar a canjear
                                    </Info>
                                </div>
                            </CardBody>
                        </Card>
                    </GridItem>
                );
            })}

            <GridItem md={6} sm={12}>
                <Card color="white">
                    <CardBody
                        id="card_beetrack"
                        className={classNames(
                            classes.minHgtCard,
                            classes.pointer
                        )}
                        onClick={handleBeetrackModal}
                    >
                        <div className={classes.cardInfoLanding}>
                            {/* <Icon className={classes.iconBeetrack}>room</Icon> */}
                            <img
                                style={{ height: "100px" }}
                                src={handleImage("transporte")}
                            />
                        </div>
                        <h3 className={classNames(classes.cardTitle)}>
                            ¿Cuando recibiré mis productos?
                        </h3>
                        <p className={classes.cardDescription}>
                            Puedes realizar seguimiento de tu pedido con tu
                            número de documento de identidad.
                        </p>
                        <div className={classes.actionBanner}>
                            <RemoveRedEye className={classes.colorInfo} />
                            <Info className={classes.pl5}>
                                Ingresa aquí y sigue tu pedido en línea
                            </Info>
                        </div>
                    </CardBody>
                </Card>
            </GridItem>
        </GridContainer>
    );
};

const mapStateToProps = ({ userReducer, ModalsReducer }) => {
    return {
        userReducer,
        beetrackModal: ModalsReducer?.beetrackModal
    };
};

const mapDispatchToProps = {
    ...modalActions
};

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(SectionInfo)
);
