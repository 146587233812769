import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// nodejs library that concatenates classes
import classNames from "classnames";
// core components
import styles from "assets/jss/material-kit-pro-react/components/typographyStyle.js";

const useStyles = makeStyles(styles);

export default function Info(props) {
    const { children } = props;
    const classes = useStyles();
    return (
        <div
            className={classNames(
                classes.defaultFontStyle,
                classes.infoText,
                props.className
            )}
        >
            {children}
        </div>
    );
}

Info.propTypes = {
    children: PropTypes.node
};
