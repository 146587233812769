/*eslint-disable*/
import React, { useEffect, useState } from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";

// redux
import { connect } from "react-redux";
import * as userActions from "../../redux/actions/userActions";
import * as formActions from "../../redux/actions/formActions";

// @material-ui/icons
import Slide from "@material-ui/core/Slide";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import Spinner from "../../components/spinner/Spinner";

import loginPageStyle from "assets/jss/material-kit-pro-react/views/loginPageStyle.js";

import FormLogin from "./FormLogin";

const useStyles = makeStyles(loginPageStyle);

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});

Transition.displayName = "Transition";

// component !!
const LoginPage = props => {
    const [loginModal, setLoginModal] = useState(false);

    const classes = useStyles();

    const handleSuccessLogin = () => {
        props.history.replace("/");
    };

    return (
        <div>
            <div
                className={classes.pageHeader}
                style={{
                    backgroundColor: "#0598AB"
                }}
            >
                <div className={classes.container}>
                    <Dialog
                        classes={{
                            root: classes.modalRoot,
                            paper: classes.modal + " " + classes.modalLogin
                        }}
                        open={loginModal}
                        TransitionComponent={Transition}
                        keepMounted
                        aria-labelledby="login-modal-slide-title"
                        aria-describedby="login-modal-slide-description"
                    >
                        <Spinner />
                    </Dialog>

                    <GridContainer justify="center">
                        <GridItem xs={12} sm={12} md={4}>
                            <Card>
                                <FormLogin successLogin={handleSuccessLogin} />
                            </Card>
                        </GridItem>
                    </GridContainer>
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = ({ formReducer, userReducer }) => (
    userReducer, formReducer
);

const mapDispatchToProps = {
    ...userActions,
    ...formActions
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(LoginPage);
