import React from 'react'
// @material-ui/core components
import {
    makeStyles
} from "@material-ui/core/styles";
import Card from 'components/Card/Card'
import CardBody from 'components/Card/CardBody';
import fondo from "./TC-Visa-Signature_280.png";
import { cutCharacters } from "utils/utils";
import styles from "assets/jss/material-kit-pro-react/components/creditCardStyle.js";
import "./style.css";

const useStyles = makeStyles(styles);

const CreditCard = ({ cardNumber, userName, typeBackground, ...props }) => {
    const classes = useStyles();

    return (
        <Card className={classes.rootCreditCard} style={{ backgroundImage: `url(${fondo})` }}>
            <CardBody
                className={classes.body}
                style={{ color: "#fff" }}
            >
                <span className="numbers">{cardNumber}</span>
                <span className={classes.textName}>
                    {cutCharacters(userName, 30)}
                </span>
            </CardBody>
        </Card >
    )
}

export default CreditCard
