import React from "react";
import { connect } from "react-redux";

const WrapperMovile = props => {
    // no se por que puse las medidas en hard code, pero funciona :D
    // margen top del banner, dependiendo del tamaño del header !!
    const handleMarginTop = () => {
        let margin = props.sizeHeader + 4 + "px"; // los 4 es el padding que tiene el header!!
        if (props.isSearch) {
            margin = "56px";
        }

        //  si el usuario no esta logueado !!
        if (!Object.keys(props.user).length) {
            margin = props.sizeHeader;
            if (props.isSearch) {
                margin = "28px";
            }
        }

        return margin;
    };

    return <div style={{ marginTop: handleMarginTop() }}>{props.children}</div>;
};

const mapStateToProps = ({ userReducer, headerReducer }) => ({
    ...userReducer,
    ...headerReducer
});

export default connect(
    mapStateToProps,
    null
)(WrapperMovile);
