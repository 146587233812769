import {
    SHOW_DIRECTIONS_MODAL,
    CLOSE_DIRECTIONS_MODAL,
    SHOW_NEW_DIRECTION_MODAL,
    CLOSE_NEW_DIRECTION_MODAL,
    SHOW_UPDATE_DIRECTION_MODAL,
    CLOSE_UPDATE_DIRECTION_MODAL,
    SHOW_MESSAGE_NO_POINTS_IN_CARD_MODAL,
    CLOSE_MESSAGE_NO_POINTS_IN_CARD_MODAL,
    FORM_UPDATE_DIRECTION,
    FORM_NEW_DIRECTION,
    DIRECTION,
    TOGGLE_LOGIN_MODAL,
    LOGIN_MODAL,
    BEETRACK_MODAL,
    TOGGLE_BEETRACK_MODAL,
    GLOBAL_LOADER_MODAL,
    TOGGLE_GLOBAL_LOADER_MODAL,
    TOGGLE_CREDIT_CARD_MODAL,
    CREDIT_CARD
} from "../../types/modalTypes";

const INITIAL_STATE = {
    directionsModal: {
        show: false
    },
    formNewDirectionModal: {
        show: false
    },
    formUpdateDirectionModal: {
        show: false
    },
    messageNoPointsModal: {
        show: false
    },
    loginModal: {
        show: false
    },
    beetrackModal: {
        show: false
    },
    globalLoader: {
        show: false
    },
    creditCard: {
        show: false
    }
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SHOW_DIRECTIONS_MODAL:
            return showModal(state, DIRECTION);

        case CLOSE_DIRECTIONS_MODAL:
            return closeModal(state, DIRECTION);

        case SHOW_NEW_DIRECTION_MODAL:
            return showModal(state, FORM_NEW_DIRECTION);

        case CLOSE_NEW_DIRECTION_MODAL:
            return closeModal(state, FORM_NEW_DIRECTION);

        case SHOW_UPDATE_DIRECTION_MODAL:
            return showModal(state, FORM_UPDATE_DIRECTION);

        case CLOSE_UPDATE_DIRECTION_MODAL:
            return closeModal(state, FORM_UPDATE_DIRECTION);

        case SHOW_MESSAGE_NO_POINTS_IN_CARD_MODAL:
            return { ...state, messageNoPointsModal: { show: true } };

        case CLOSE_MESSAGE_NO_POINTS_IN_CARD_MODAL:
            return { ...state, messageNoPointsModal: { show: false } };

        case TOGGLE_LOGIN_MODAL:
            return toggleModal(state, state.loginModal.show, LOGIN_MODAL);

        case TOGGLE_BEETRACK_MODAL:
            return toggleModal(state, state.beetrackModal.show, BEETRACK_MODAL);

        case TOGGLE_GLOBAL_LOADER_MODAL:
            return toggleModal(state, state.globalLoader.show, GLOBAL_LOADER_MODAL);

        case TOGGLE_CREDIT_CARD_MODAL:
            return toggleModal(state, state.creditCard.show, CREDIT_CARD);

        default:
            return state;
    }
};

/**
 * Cambia al estado contrario el modal enviado !!
 * @param {Object} state El estagdo del reducer actual
 * @param {Bollean} isShow El estagdo del modal, true or falce
 * @param {String} modal El nombre del modal a afectar
 */
const toggleModal = (state, isShow, modal) => {
    const newState = { ...state };
    newState[modal].show = !isShow;

    return newState;
};

const showModal = (state, modal) => {
    const newState = { ...state };
    newState[modal].show = true;

    return newState;
};

const closeModal = (state, modal) => {
    const newState = { ...state };
    newState[modal].show = false;

    return newState;
};
