import React from "react";

export const MAX_AMOUNT_ALLOWED = 10;

export const PUNTOS = "Puntos BBVA";

export const PUNTOS_DELIVERY = "Puntos Deivery";

export const MAS_PEDIDOS = "No dejes pasar nuestros productos recomendados";

export const NAME_PAGE_FOOTER = `${PUNTOS}`;

export const CANJEAR = "canjear";

export const CANJEA = "canjea";

// ========== mensajes de error de las tarjetas =========== //
export const MESSAGE_NO_POINTS_IN_CARD = (
    <p>
        <span style={{ display: "block" }}>
            Al parecer la tarjeta BBVA registrada en Puntos Delivery aún no
            posee Puntos BBVA o se encuentra inactiva.
        </span>
        <span style={{ display: "block" }}>
            Haz clic en Agregar tarjeta para agregar una tarjeta activa donde
            acumules Puntos BBVA.
        </span>
    </p>
);

export const MESSAGE_BLOCK_CARD = (
    <p>
        <span style={{ display: "block", marginBottom: "5px" }}>
            Para poder visualizar y canjear tus Puntos BBVA, debes escribirnos
            por el chat web o a puntosdelivery@rewardsperu.com solicitando la{" "}
            <b>Sincronización de tus Puntos BBVA</b>, indicando nombre completo
            y DNI.
        </span>
        <span style={{ display: "block", marginBottom: "5px" }}>
            Recuerda que para que la sincronización sea exitosa debes utilizar
            el mismo correo registrado en BBVA y tener encendida tu tarjeta de
            crédito BBVA en tu app de Banca móvil.
        </span>
        <span style={{ display: "block" }}>
            <a
                href="https://rewardsperuhelp.zendesk.com/hc/es-419/articles/13533082050587"
                target="_blank"
            >
                ¿Cómo lo hago?
            </a>
        </span>
    </p>
);

export const MESSAGE_ERROR_CONNECTION_POINTS =
    "¡Opss!, Ocurrió un error al cargar tus puntos, intenta iniciar sesión nuevamente";

export const MESSAGE_CARD_NO_VALID = MESSAGE_BLOCK_CARD;

export const MESSAGE_CARD_NO_EXIST = MESSAGE_BLOCK_CARD;

export const MESSAGE_NO_ACCUMULATE_POINTS = (
    <p>
        <span style={{ display: "block" }}>
            La Tarjeta BBVA que tenemos registrada en nuestra web, no acumula
            Puntos, por favor ve a tu perfil en el icono al costado de tu nombre
            y registra otra tarjeta.
        </span>
        <span style={{ display: "block" }}>
            Haz clic en Agregar tarjeta para agregar a tu perfil tu Tarjeta BBVA
            que acumula Puntos.
        </span>
        <span style={{ display: "block" }}>
            Si luego de agregarla no ves tus puntos disponibles, verifica que
            esté encendida en tu app de Banca Móvil BBVA.
        </span>
    </p>
);

// ========== mensajes de error de las tarjetas fin!! =========== //

export const NO_TIPE_DIRECTION = "Sin tipo de dirección";

export const TIPO_DIRECTION = "Tipo de dirección";

export const ENVIO_NACIONAL = "Envío Nacional";

export const ENVIO_LIMA_CALLAO = "Solo Lima y Callao";

// mensaje cuando no hay puntos o da error!!

// registra tu tarjeta BBVA
