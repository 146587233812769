import React from "react";

// nodejs library that concatenates classes
import classNames from "classnames";
// core components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Parallax from "components/Parallax/Parallax";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import { makeStyles } from "@material-ui/core/styles";
import { baseRouteImages, pathBanner } from "../../../config/config.json";
import bannerStyle from "assets/jss/material-kit-pro-react/components/bannerStyle";
import useResize from "Hooks/useResize/UseResize";

const useStyles = makeStyles(bannerStyle);

export const BannerDesk = ({ banner, goRoute }) => {
    const classes = useStyles();

    const [_, isMobil, isLaptop] = useResize();

    const handleResolveRouteImg = img => {
        return !!banner ? `${baseRouteImages}${pathBanner}${img}` : "";
    };

    return (
        <Parallax
            image={handleResolveRouteImg(banner.imgfondo)}
            small
            className={isLaptop ? classes.sizeLaptop : ""}
        >
            <div className={classes.container}>
                <GridContainer>
                    <GridItem
                        md={5}
                        sm={12}
                        lg={5}
                        className={classes.gridContainer}
                    >
                        <Card
                            className={classNames(
                                classes.noBorder,
                                classes.bgCard
                            )}
                        >
                            <CardBody className={classes.paddingBody}>
                                <h3 className={classes.title}>
                                    {banner.titulo}
                                </h3>
                                <p className={classes.parrafo}>
                                    {banner.descripcion}
                                </p>
                            </CardBody>
                        </Card>
                        <div className={classes.boxBtn}>
                            <Button
                                color="bbva"
                                noRound
                                shadow
                                className={classNames(
                                    classes.noMargin,
                                    classes.noUpper,
                                    classes.fz14
                                )}
                                onClick={() =>
                                    goRoute(banner.urlboton, banner.newScreen)
                                }
                                // onClick={handleClick}
                            >
                                {banner.textoboton}
                            </Button>
                        </div>
                    </GridItem>
                    <GridItem
                        md={7}
                        sm={12}
                        lg={7}
                        className={classNames(
                            classes.gridContainer,
                            classes.mt50
                        )}
                    >
                        <Card plain className={classes.noBorder}>
                            <img
                                className={classes.sizeImg}
                                src={handleResolveRouteImg(banner.imgprincipal)}
                            />
                        </Card>
                    </GridItem>
                </GridContainer>
            </div>
        </Parallax>
    );
};
