import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import Slider from "react-slick";
import { withRouter } from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import ImageLazy from "components/Images/ImageLazy";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import { numberFormat, capitalize } from "utils/utils";
import { PUNTOS } from "config/const/constText";
import Discount from "components/Card/Discount";
import SvgIcon from "components/Svg/SvgIcon";
import AirplanemodeActive from "@material-ui/icons/AirplanemodeActive";
import styles from "assets/jss/material-kit-pro-react/views/ecommerceSections/productsStyle.js";
import { ENVIO_NACIONAL, ENVIO_LIMA_CALLAO } from "config/const/constText";

const useStyles = makeStyles(styles);

const MultiCarouselProducts = ({ product, cardClass, ...props }) => {
    const classes = useStyles();

    const SampleNextArrow = props => {
        const classes = useStyles();
        const { className, style, onClick } = props;
        return (
            <div
                className={classNames(className, classes.arrowCarousel)}
                onClick={onClick}
            ></div>
        );
    };

    const SamplePrevArrow = props => {
        const classes = useStyles();
        const { className, style, onClick } = props;
        return (
            <div
                className={classNames(className, classes.arrowCarousel)}
                onClick={onClick}
            ></div>
        );
    };

    const handlePoints = product => {
        if (product.puntosoferta) {
            return numberFormat(product.puntosoferta);
        }

        return numberFormat(product.puntos);
    };

    const handleInfoEnvio = envio => {
        return envio === "L" ? (
            <>
                {" "}
                <SvgIcon
                    desc="icono-delivery"
                    fill="#626262"
                    width="20px"
                    height="20px"
                />
                &nbsp;{ENVIO_LIMA_CALLAO}
            </>
        ) : (
            <>
                {" "}
                <AirplanemodeActive /> {ENVIO_NACIONAL}
            </>
        );
    };

    const handleRouter = product => {
        props.history.push(`/producto/${btoa(product.id)}`, {
            product: product.marca
        });
    };

    const handlePrice = product => {
        if (product.preciooferta > 0) {
            return product.preciooferta;
        }

        return product.precio;
    };

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 5,
        slidesToScroll: 1,
        autoplay: true,
        // autoplaySpeed: 5000,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true
                    // dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1
                    // initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1
                }
            }
        ]
    };

    const handleShowCard = () => {
        if (!product) return null;
        return product.map((product, i) => (
            <Card
                key={`${product.nombrecorto + i} `}
                color="white"
                product
                className={classNames(
                    cardClass,
                    classes.borderHover,
                    classes.mb0,
                    classes.pointer
                )}
                onClick={() => handleRouter(product)}
                fullHeight
            >
                <CardHeader noShadow image className={classes.imgInCarousel}>
                    {product.puntosoferta > 0 && (
                        <Discount
                            puntos={product.puntos}
                            offer={product.puntosoferta}
                        />
                    )}
                    <ImageLazy
                        src={product.imgproducto}
                        alt={product.brand.nombre}
                    />
                </CardHeader>
                <CardBody
                    plain
                    grow
                    className={classNames(
                        classes.noPaddingVertical,
                        classes.mb0
                    )}
                >
                    <div
                        className={classes.containerBody}
                        onClick={() => handleRouter(product)}
                    >
                        <h4
                            style={{
                                // REFACTOR: HERE!!
                                fontFamily: "BentonSansBBVA Medium",
                                fontWeight: "normal",
                                fontSize: "12px"
                            }}
                            className={classes.cardTitle}
                        >
                            {product.brand.nombre}
                        </h4>

                        <p
                            className={classNames(
                                classes.cardDescription,
                                classes.carouselFontsize
                            )}
                        >
                            {product.nombrecorto}
                        </p>

                        <p
                            className={classNames(
                                classes.price,
                                classes.carouselFontsize
                            )}
                        >
                            {handlePoints(product)}
                            {" " + PUNTOS}
                            <span
                                className={classNames(
                                    classes.fz12,
                                    classes.priceSoles
                                )}
                            >
                                o {`S/${numberFormat(handlePrice(product))}`}
                            </span>
                        </p>

                        <p className={classes.envio}>
                            {handleInfoEnvio(product.envio)}
                        </p>
                    </div>
                </CardBody>
            </Card>
        ));
    };

    return <Slider {...settings}>{handleShowCard()}</Slider>;
};

MultiCarouselProducts.propTypes = {};

export default withRouter(MultiCarouselProducts);
