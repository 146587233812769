export const START_BUTTON_CASH_VISA = "start_button_cash_visa";

export const SET_DATA_SESSION = "set_data_session";

export const GET_DATA_SESSION = "get_data_session";

export const REMOVE_DATA = "remove_data";

export const ERROR_SESSION_VISA = "error_session_visa";

export const IS_LOADING = "is_loading";
