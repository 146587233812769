import React, { useState, useEffect } from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// nodejs library to set properties for components
import PropTypes from "prop-types";
import SwipeableViews from "react-swipeable-views";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";

// icon svg !!
import SvgIcon from "components/Svg/SvgIcon";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import styles from "assets/jss/material-kit-pro-react/components/navPillsStyle.js";

const useStyles = makeStyles(styles);
/**
 *
 * @param {tapRenderIcon} props Sirve para pasarle el nombre de la libreria svg interna
 */
export default function NavPills(props) {
    const [active, setActive] = useState(props.active);
    useEffect(() => {
        // custom, para que se actualize desde fuera !!
        setActive(props.active);
    }, [props.active]);
    const handleChange = (event, active) => {
        props.onChange(active); // custom, para que se actualize desde fuera !!
        setActive(active);
    };
    const handleChangeIndex = index => {
        setActive(index);
    };
    const { tabs, direction, color, horizontal, alignCenter } = props;
    const classes = useStyles();
    const flexContainerClasses = classNames({
        [classes.flexContainer]: true,
        [classes.horizontalDisplay]: horizontal !== undefined
    });
    const tabButtons = (
        <Tabs
            classes={{
                root: classes.root,
                fixed: classes.fixed,
                flexContainer: flexContainerClasses,
                indicator: classes.displayNone
            }}
            value={active}
            onChange={handleChange}
            centered={alignCenter}
        >
            {tabs.map((prop, key) => {
                var icon = {};
                if (prop.tabIcon !== undefined) {
                    icon["icon"] = <prop.tabIcon className={classes.tabIcon} />;
                }
                if (prop.tapRenderIcon) {
                    if (key === active) {
                        icon["icon"] = (
                            <SvgIcon
                                desc={prop.tapRenderIcon}
                                fill="#fff"
                                width="25px"
                                height="25px"
                            />
                        );
                    } else {
                        icon["icon"] = (
                            <SvgIcon
                                desc={prop.tapRenderIcon}
                                fill="#1d73b2"
                                width="25px"
                                height="25px"
                            />
                        );
                    }
                }
                const pillsClasses = classNames({
                    [classes.pills]: true,
                    [classes.horizontalPills]: horizontal !== undefined,
                    [classes.pillsWithIcons]: prop.tabIcon !== undefined
                });
                return (
                    <Tab
                        label={prop.tabButton}
                        key={key}
                        {...icon}
                        classes={{
                            root: pillsClasses,
                            label: classes.label,
                            selected: classes[color]
                        }}
                    />
                );
            })}
        </Tabs>
    );
    const tabContent = (
        <div className={classes.contentWrapper}>
            <SwipeableViews
                axis={direction === "rtl" ? "x-reverse" : "x"}
                index={active}
                onChangeIndex={handleChangeIndex}
            >
                {tabs.map((prop, key) => {
                    return (
                        <div className={classes.tabContent} key={key}>
                            {prop.tabContent}
                        </div>
                    );
                })}
            </SwipeableViews>
        </div>
    );
    return horizontal !== undefined ? (
        <GridContainer>
            <GridItem {...horizontal.tabsGrid}>{tabButtons}</GridItem>
            <GridItem {...horizontal.contentGrid}>{tabContent}</GridItem>
        </GridContainer>
    ) : (
            <div>
                {tabButtons}
                {tabContent}
            </div>
        );
}

NavPills.defaultProps = {
    active: 0,
    color: "primary"
};

NavPills.propTypes = {
    // index of the default active pill
    active: PropTypes.number,
    tabs: PropTypes.arrayOf(
        PropTypes.shape({
            tabButton: PropTypes.string,
            tabIcon: PropTypes.object,
            tabContent: PropTypes.node,
            tapRenderIcon: PropTypes.string
        })
    ).isRequired,
    color: PropTypes.oneOf([
        "primary",
        "warning",
        "danger",
        "success",
        "info",
        "rose"
    ]),
    direction: PropTypes.string,
    horizontal: PropTypes.shape({
        tabsGrid: PropTypes.object,
        contentGrid: PropTypes.object
    }),
    alignCenter: PropTypes.bool
};
