import React, { useEffect, useState } from "react";
import Button from "components/CustomButtons/Button.js";
import Home from "@material-ui/icons/Home";
import Print from "@material-ui/icons/Print";
import classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import checkoutStyle from "assets/jss/material-kit-pro-react/views/checkoutPage.js";
import queryString from "query-string";

//redux
import { connect } from "react-redux";
import * as visaCashActions from "../../redux/actions/visaCashActions";
import * as cardActions from "../../redux/actions/carActions";

import { ModalLoader } from "components/Modal/ModalLoader";
import { saveChangeVisa } from "services/visaCash/visaCashServices";
import {
    getPointsStorage,
    removePointsStorage
} from "services/localStorage/dataChangePoints";
import { updateAuthCash } from "services/canje/canjeServices";
import { getUserStorage } from "services/localStorage/userStorage";
import WrapperMovile from "hoc/WrapperMovile";
import { setAuthStorage } from "services/localStorage/authStorage";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Table from "components/Table/Table.js";

import style from "assets/jss/material-kit-pro-react/views/componentsSections/contentAreas.js";
import { getCanjeBYNumber } from "services/canje/canjeServices";
import { capitalizeFirst } from "utils/utils";
import ModalGericInfo from "components/Modal/ModalGericInfo";

const useStyles = makeStyles(style);

const useStylesCheckout = makeStyles(checkoutStyle);

// REFACTOR: esta en hardcode, solo en caso de exito!!
const RespuestaCanjePage = props => {
    const classesCheck = useStylesCheckout();
    const classes = useStyles();
    const [message, setMessage] = useState("");
    const [isLoading, setIsLoading] = useState(true);
    const [dataCanje, setDataCanje] = useState(null);
    const [dataPedido, setDataPedido] = useState(null);
    const [dataProducts, setDataProducts] = useState(null);
    const [error, setError] = useState(null);
    const [criticalError, setCriticalError] = useState(false);

    useEffect(() => {
        const { location } = props;
        goTop();

        if (location.state) {
            const { status, request } = location.state;
            const msg =
                status != "ok"
                    ? "Ocurrio un error con su canje de puntos, intentelo de nuevo"
                    : "Canje exitoso";

            setMessage(msg);
            setIsLoading(false);
            if (status == "ok") {
                const { token } = getUserStorage();
                handleGetPedido({
                    numPedido: request.response.num_pedido,
                    token
                });
                props.deleteAllCar();
            }
        }

        handleHasSearch();
    }, []);

    const goTop = () => {
        window.scrollTo(0, 0);
        document.body.scrollTop = 0;
    };

    const handleHasSearch = () => {
        const { location } = props;
        if (!location.search) {
            return;
        }

        const tToken = queryString.parse(location.search);
        let dataStorage = getPointsStorage();
        if (!dataStorage) {
            handleGoHome();
            return;
        }
        // elimina la data del pedido que esta en este storage
        removePointsStorage();

        props.deleteAllCar(); // aqui se guardan los datos necesarios para terminar el flujo del checkout !!
        let finalData = { ...dataStorage, transactionToken: tToken.c };
        setDataPedido(finalData);
        handleLastRequest(finalData);
    };

    const handleGoHome = () => {
        props.history.replace("/", null);
    };

    const handleLastRequest = async body => {
        try {
            const { token } = getUserStorage();
            body.token = token;
            let res = await saveChangeVisa(body);

            if (res.status == "error") {
                if (res.message["status"] == 412) {
                    setAuthStorage();
                }

                if (!res.response) {
                    setError(res.message);
                    setMessage("Error en el canje");
                } else if (res.response.hasOwnProperty("data")) {
                    setError(res.response.data.ACTION_DESCRIPTION);
                    setMessage(res.response.data.ACTION_DESCRIPTION);
                } else {
                    setError(res.message);
                    setMessage("Error en el canje");
                }

                setIsLoading(false);

                return;
            }

            if (res.response.substring(1, -1) == "{") {
                // no viene en json, hay que convertirlo !!
                res = JSON.parse(res.response);
                setDataCanje(res);
            }

            if (typeof res != "object") {
                setMessage("Error en el canje");
                setIsLoading(false);
            } else {
                const data = {
                    num_autorizacion_soles: res.order.authorizationCode,
                    num_pedido: body.numPedidoDB,
                    token
                };
                handleGetPedido({ numPedido: body.numPedidoDB, token });

                const resp = await updateAuthCash(data);

                if (resp == "ok") {
                    setMessage("El pedido se realizo con exito");
                    // removeCarStorage();
                    props.deleteAllCar();
                    setIsLoading(false);
                } else {
                    setMessage("Error en el pedido");
                    setIsLoading(false);
                }
            }
        } catch (error) {
            setIsLoading(false)
            setCriticalError(true)
        }
        
    };

    // busca los datos del pedido !!
    const handleGetPedido = async body => {
        const pedido = await getCanjeBYNumber(body);
        setDataProducts(pedido.response);
    };

    const handleTableBody = () => {
        return dataProducts.details.map(item => {
            return [item.codproducto, item.nombre, item.cantidad];
        });
    };

    const handlePrint = () => {
        window.print();
    };

    const handleCapitalizeTipoPago = tipoPago => {
        const pago = tipoPago.split(" ");
        if (pago.length >= 1) {
            return capitalizeFirst(pago[1]);
        }
        return pago;
    };

    const handleNumPedido = () => {
        if (dataProducts && dataProducts.num_pedido) {
            return <p>Número de pedido: {dataProducts.num_pedido}</p>;
        }
        return null;
    };

    const handleNumPedidoMixto = () => {
        if (dataPedido && dataPedido.purchaseNumber) {
            return <p>Número de pedido mixto: {dataPedido.purchaseNumber}</p>;
        }

        return null;
    };

    const handleNumTarjeta = () => {
        if (dataCanje) {
            return <p>Número de tarjeta: {dataCanje.dataMap.CARD} </p>;
        }

        return null;
    };

    const handlePuntosUtilizados = () => {
        if (dataProducts && dataProducts.puntos) {
            return <p>Puntos utilizados: {dataProducts.puntos}</p>;
        }

        return null;
    };

    // el tittulo con un padding como los demas
    // productos canjeados en negrita
    // quitar la negrita y poner en lig en la tabla
    // expandir la regilla
    // separar los botones

    return (
        <WrapperMovile>
            <ModalGericInfo
                showModal={criticalError}
                onClose={!criticalError}
                title="Hubo un problema al intentar procesar"
                classContent={classes.pt0}
                classTitle={classes.textCenter}
                noButtonClose
                classFooter={classes.containerFotterModal}
                butonAction={{
                    title: "Ir al home",
                    click: () => handleGoHome()
                }}
            >
                <p className={classes.mb0}>
                    Hubo un problema al intentar procesar el pago de los soles, por favor escríbanos un mensaje para ayudarlo a culminar el pago.
                </p>

            </ModalGericInfo>
            <div
                className={classNames(
                    classesCheck.main,
                    classesCheck.mainRaised,
                    classesCheck.containerResponesta,
                    classesCheck.heigthSizeContainer
                )}
            >
                <div
                    className={classNames(
                        classesCheck.container,
                        classesCheck.resetPd,
                        classesCheck.mt4,
                        classesCheck.paddingHorizontal5
                    )}
                >
                    <GridContainer>
                        <GridItem md={6} sm={6} xs={12}>
                            <ModalLoader showModal={isLoading} />
                            {isLoading ? (
                                <p>Espere por favor...</p>
                            ) : (
                                <>
                                    <h2>{message}</h2>
                                    {error ? (
                                        <>
                                            <p>
                                                Cliente:{" "}
                                                {`${props.user.nombres} ${props.user.apellidos}`}{" "}
                                            </p>
                                            <p>
                                                Número de pedido:{" "}
                                                {dataPedido &&
                                                    dataPedido.purchaseNumber}
                                            </p>
                                            <p>Error: {error}</p>
                                        </>
                                    ) : (
                                        <>
                                            <p>
                                                Cliente:{" "}
                                                {`${props.user.nombres} ${props.user.apellidos}`}{" "}
                                            </p>
                                            {handleNumPedido()}

                                            {handleNumPedidoMixto()}

                                            {handleNumTarjeta()}

                                            <p>
                                                Fecha y hora del pedido:{" "}
                                                {dataProducts &&
                                                    dataProducts.fecha}
                                            </p>
                                            <p>
                                                Tipo de canje:{" "}
                                                {dataProducts &&
                                                    handleCapitalizeTipoPago(
                                                        dataProducts.tipo_pago
                                                    )}
                                            </p>
                                            {dataCanje &&
                                                dataCanje.order.amount && (
                                                    <p>
                                                        Importe de la
                                                        transacción en soles:
                                                        {dataCanje.order.amount}
                                                    </p>
                                                )}

                                            {handlePuntosUtilizados()}

                                            {dataCanje && (
                                                <p>Tipo de moneda: Soles</p>
                                            )}
                                            <p>Productos canjeados: </p>
                                            {dataProducts && (
                                                <Table
                                                    tableHead={[
                                                        "Código",
                                                        "Producto",
                                                        "Cantidad"
                                                    ]}
                                                    tableData={handleTableBody()}
                                                    customCellClasses={[
                                                        classes.textCenter,
                                                        classes.textRight,
                                                        classes.textRight
                                                    ]}
                                                    customClassesForCells={[
                                                        0,
                                                        4,
                                                        5
                                                    ]}
                                                    customHeadCellClasses={[
                                                        classes.textCenter,
                                                        classes.textRight,
                                                        classes.textRight
                                                    ]}
                                                    customHeadClassesForCells={[
                                                        0,
                                                        4,
                                                        5
                                                    ]}
                                                />
                                            )}
                                        </>
                                    )}
                                </>
                            )}
                            <Button color="bbva" onClick={handleGoHome}>
                                <Home></Home>
                                Regresar al Inicio
                            </Button>
                            <Button color="bbva" onClick={handlePrint}>
                                <Print />
                                Imprimir
                            </Button>
                        </GridItem>
                    </GridContainer>
                </div>
            </div>
        </WrapperMovile>
    );
};

const mapStateToProps = ({ visaCashReducer, userReducer }) => {
    return {
        ...visaCashReducer,
        ...userReducer
    };
};

const mapStateToDispatch = {
    ...visaCashActions,
    ...cardActions
};

export default connect(
    mapStateToProps,
    mapStateToDispatch
)(RespuestaCanjePage);
