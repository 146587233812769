import { AUTH } from "./const/constStorage";

import { objectToString, stringToObject } from "../../utils/utils";

// regresa los datos del usuario !!
export const getAuthStorage = () => {
    const token = localStorage.getItem(AUTH);
    return !!token ? stringToObject(token) : "";
};

// agrega los datos del usuario al storage !!
export const setAuthStorage = () => {
    localStorage.setItem(AUTH, objectToString({ Auth: true }));
};

// elimina usuario del storage !!
export const removeAuthStorage = () => {
    localStorage.removeItem(AUTH);
};
