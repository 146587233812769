import { whiteColor } from "assets/jss/material-kit-pro-react";
import { colorYellow } from "assets/jss/material-kit-pro-react";
import {
    container,
    title,
    main,
    mainRaised,
    colorLineSeparator,
    colorWhite,
    parrafoDefault,
    colorLinkLight,
    colorTextMenu,
    colorBlueLight2,
    ffBookButtom,
    cyan,
    ffBook
} from "assets/jss/material-kit-pro-react.js";
import headerLinksStyle from "assets/jss/material-kit-pro-react/components/headerLinksStyle.js";

const navbarsStyle = theme => ({
    container,
    main,
    mainRaised,
    parrafoDefault,
    ffBookButtom,
    ffBook,
    ...headerLinksStyle(theme),
    section: {
        padding: "70px 0",
        paddingBottom: "0"
    },
    categoriList: {
        paddingLeft: "10px"
    },
    container: {
        ...container,
        zIndex: "2"
    },
    title: {
        ...title,
        marginTop: "30px",
        minHeight: "32px",
        textDecoration: "none"
    },
    navbar: {
        marginBottom: "-20px",
        zIndex: "100",
        position: "relative",
        overflow: "hidden",
        "& header": {
            borderRadius: "0",
            zIndex: "unset"
        }
    },
    navigation: {
        backgroundPosition: "50%",
        backgroundSize: "cover",
        marginTop: "0",
        minHeight: "740px"
    },
    formControl: {
        margin: "0 !important",
        paddingTop: "7px",
        paddingBottom: "7px"
    },
    inputRootCustomClasses: {
        margin: "0!important"
    },
    pdSearchButtom: {
        padding: "0"
    },
    searchIcon: {
        width: "20px",
        height: "20px",
        color: "inherit"
    },
    img: {
        width: "40px",
        height: "40px",
        borderRadius: "0"
    },
    imgSmall: {
        width: "24px ",
        height: "24px ",
        marginRight: "15px"
    },
    imageDropdownButton: {
        padding: "0px",
        borderRadius: "0",
        marginLeft: "5px"
    },
    textUser: {
        fontSize: "14px",
        margin: "0",
        paddingTop: "2px",
        marginTop: "4px"
    },
    bgSearch: {
        background: "#004481",
        width: "100%"
    },
    inputSearchBox: {
        background: "#004481",
        // marginTop: "77px !important",
        position: "fixed",
        width: "100%",
        zIndex: 9
    },
    flexCenter: {
        display: "flex",
        alignItems: "center"
    },
    noBorder: {
        borderRadius: "0"
    },
    pd1: {
        padding: "1em 0"
    },
    ml0: {
        marginLeft: "0"
    },
    m0: {
        margin: "0"
    },
    textLoad: {
        marginTop: "4px",
        paddingTop: "2px",
        fontSize: "12px"
    },
    pt0Mt0: {
        marginTop: 0,
        paddingTop: 0
    },
    pt5: {
        paddingTop: "5px"
    },
    fz15: {
        fontSize: "15px"
    },
    fz13Buttom: {
        "& > span": {
            fontSize: "13px"
        }
    },
    textWhite: {
        color: `${whiteColor} !important`
    },
    pr0: {
        paddingRight: "0"
    },
    searchInput: {
        paddingLeft: ".5rem"
    },
    pdl10: {
        paddingLeft: "10px"
    },
    mt21: {
        marginTop: "21px"
    },
    colorWhite: {
        color: colorWhite
    },
    noUpper: {
        textTransform: "none"
    },
    lineSeparator: {
        borderBottom: `1px solid ${colorLineSeparator}`
    },
    noPdLeft: {
        paddingLeft: "0"
    },
    colorTextCategories: {
        color: colorTextMenu
    },
    colorIconCategories: {
        color: colorLinkLight
    },
    bgLightBlue: {
        backgroundColor: colorBlueLight2
    },
    listItemMenu: {
        margin: "0 8px"
    },
    buttomBox: {
        display: "flex",
        alignItems: "center",
        position: "relative"
    },
    floatBadge: {
        fontSize: "9px",
        backgroundColor: "#028484",
        borderRadius: "50%",
        padding: "1px 5px",
        position: "absolute",
        top: "-9px",
        right: "-8px"
    },
    colorPoints: {
        color: cyan
    },
    centerChildren: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    },
    hiden: {
        display: "none"
    },
    dBlock: {
        display: "block"
    },
    textRegister: {
        fontSize: "14px",
        paddingLeft: "7px"
    },
    buttonRegister: {
        color: colorYellow,
        "&:focus": {
            color: colorYellow,
        }
    },
    textYellow: {
        color: colorYellow
    },
    mr15: {
        marginRight: "15px"
    }
});

export default navbarsStyle;
