import React, { useEffect } from "react";

import { makeStyles } from "@material-ui/core/styles";

// prop types
import PropTypes from "prop-types";

// core
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Slide from "@material-ui/core/Slide";

// components

// redux
import { connect } from "react-redux";
import * as modalActions from "../../redux/actions/modalActions";

// styles
import javascriptStyles from "assets/jss/material-kit-pro-react/views/componentsSections/javascriptStyles.js";

import FormularioDirecciones from "views/ProfilePage/sections/FormularioDirecciones";

const useStyles = makeStyles(javascriptStyles);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

Transition.displayName = "Transition";

// component !!
const ModalFormNewDirection = props => {

  const classes = useStyles();

  useEffect(() => {
    handleDetectShow();

  }, [props.modal, props.modalShow]);

  const handleDetectShow = () => {
    const { modal } = props;

    return modal.formNewDirectionModal.show
  };

  return (
    <Dialog
      classes={{
        root: classes.modalRoot,
        paper: classes.modal
      }}
      open={handleDetectShow()}
      TransitionComponent={Transition}
      keepMounted
      onClose={props.hidenModalFormDirections}
      aria-labelledby="classic-modal-slide-title"
      aria-describedby="classic-modal-slide-description"
    >
      <DialogContent
        id="classic-modal-slide-description"
        className={classes.modalBody}
      >
        <h4>{!!props.isUpdate ? "Actualiza tu dirección" : "Nueva dirección"}</h4>
        <FormularioDirecciones />
      </DialogContent>
    </Dialog>
  );
};

ModalFormNewDirection.propTypes = {
  modalShow: PropTypes.string,
  content: PropTypes.node,
  actions: PropTypes.node,
  title: PropTypes.string
};

const mapStateToProps = ({ ModalsReducer }) => {
  return { modal: ModalsReducer };
};

export default connect(mapStateToProps, { ...modalActions })(
  ModalFormNewDirection
);
