import {
    MARCAS_SET_FILTER,
    MARCAS_REMOVE_FILTER,
    RANGO,
    RESET_FILTER,
    ENVIO,
    ORDER_FILTER
} from "../types/filterProductsTypes";

export const changeFilterMarcas = id_marca => dispatch => {
    return dispatch({
        type: MARCAS_SET_FILTER,
        payload: id_marca
    });
};

export const changeRemoveFilter = index => dispatch => {
    return dispatch({
        type: MARCAS_REMOVE_FILTER,
        payload: index
    });
};

export const setEnvio = envio => dispatch => {
    return dispatch({
        type: ENVIO,
        payload: envio
    });
};

export const changeRank = (start, end) => dispatch => {
    return dispatch({
        type: RANGO,
        payload: { start, end }
    });
};

export const orderBy = ({ orderBy, typeOrder }) => dispatch => {
    return dispatch({
        type: ORDER_FILTER,
        payload: { orderBy, typeOrder }
    });
}

export const resetAllFilters = () => dispatch => {
    return dispatch({
        type: RESET_FILTER
    });
};
