import React, { useState, useEffect } from "react";
import Footer from "components/Footer/Footer";
import { List, ListItem } from "@material-ui/core";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import { withRouter } from "react-router-dom";
import logo1 from "assets/img/logos/LogoPuntosDeliveryBlanco.svg";

import classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";

// components
import Button from "../../components/CustomButtons/Button";

//redux!!
import { connect } from "react-redux";
import * as modalActions from "redux/actions/modalActions";

// router
import { Link } from "react-router-dom";

// styles
import styles from "assets/jss/material-kit-pro-react/views/contactUsStyle.js";

// Config, names !!
import { NAME_PAGE_FOOTER } from "config/const/constText";
import ModalTerminos from "components/Modal/ModalTerminos";
import { PUNTOS_BBVA_LINK } from "config/const/footer";
import { CONTACTANOS } from "config/const/footer";

const useStyles = makeStyles(styles);

const FooterGlobal = props => {
    const classes = useStyles();

    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [contentModal, setContentModal] = useState("");
    const [preguntas, setPreguntas] = useState(null);
    const [showFotter, setShowFotter] = useState(true);

    useEffect(() => {
        const { location } = props;
        if (
            location.pathname == "/checkout" ||
            location.pathname == "/carrito"
        ) {
            setShowFotter(false);
        } else {
            setShowFotter(true);
        }
    }, [props.location]);

    const handleCloseModal = () => {
        setModalIsOpen(false);
    };

    const handleOpenModal = text => {
        setPreguntas(null);
        setContentModal(
            <>
                <h3 className={classes.contactanosTitle}>Contáctanos</h3>
                <div className={classes.contactanos}>{text}</div>
            </>
        );
        setModalIsOpen(true);
    };

    //
    const handleSeguimiento = () => {
        props.togglebeetrackTrack();
    };

    return !showFotter ? null : (
        <Footer
            className={classNames(classes.bgFooter, classes.footer)}
            content={
                <div className={classes.centradoFotter}>
                    <ModalTerminos // este modal sirve para renderizar los terminos y condiciones y otras cosas que se le envien
                        showModal={modalIsOpen}
                        closeModal={handleCloseModal}
                        content={contentModal}
                    >
                        {preguntas}
                    </ModalTerminos>

                    <GridContainer>
                        <GridItem md={8} xs={12}>
                            <div>
                                <img src={logo1} className={classes.logo} />
                            </div>
                            <div className={classes.left}>
                                <List className={classes.list}>
                                    <ListItem className={classes.inlineBlock}>
                                        <a
                                            href={PUNTOS_BBVA_LINK}
                                            target="_blank"
                                            className={classNames(
                                                classes.block,
                                                classes.colorLinkBgDark
                                            )}
                                        >
                                            {NAME_PAGE_FOOTER}
                                        </a>
                                    </ListItem>
                                    <ListItem className={classes.inlineBlock}>
                                        <Button
                                            simple
                                            className={classNames(
                                                classes.block,
                                                classes.noUpper,
                                                classes.colorLinkBgDark
                                            )}
                                            onClick={handleSeguimiento}
                                        >
                                            Seguimiento de Pedidos
                                        </Button>
                                    </ListItem>
                                    <ListItem className={classes.inlineBlock}>
                                        <a
                                            href="https://rewardsperuhelp.zendesk.com/hc/es-419/sections/13533035137819-Preguntas-frecuentes"
                                            className={classNames(
                                                classes.block,
                                                classes.colorLinkBgDark
                                            )}
                                            target="_blank"
                                        >
                                            Preguntas Frecuentes
                                        </a>
                                    </ListItem>
                                    <ListItem className={classes.inlineBlock}>
                                        <a
                                            href="https://rewardsperuhelp.zendesk.com/hc/es-419/sections/13533082025243-T%C3%A9rminos-y-Condiciones"
                                            className={classNames(
                                                classes.block,
                                                classes.colorLinkBgDark
                                            )}
                                            target="_blank"
                                        >
                                            Términos y Condiciones
                                        </a>
                                    </ListItem>
                                    <ListItem className={classes.inlineBlock}>
                                        <Link
                                            to="/reclamaciones"
                                            className={classNames(
                                                classes.block,
                                                classes.colorLinkBgDark
                                            )}
                                            // target="_blank"
                                        >
                                            Libro de Reclamaciones
                                        </Link>
                                    </ListItem>
                                    <ListItem className={classes.inlineBlock}>
                                        <Button
                                            simple
                                            className={classNames(
                                                classes.block,
                                                classes.noUpper,
                                                classes.colorLinkBgDark
                                            )}
                                            onClick={() =>
                                                handleOpenModal(CONTACTANOS)
                                            }
                                        >
                                            Contáctanos
                                        </Button>
                                    </ListItem>
                                </List>
                                <div
                                    className={classNames(
                                        classes.fz12,
                                        classes.colorText,
                                        classes.ffBook,
                                        classes.mt40,
                                        classes.copiStyle
                                    )}
                                >
                                    &copy; {1900 + new Date().getYear()}, Puntos
                                    Delivery | Desarrollado y Administrado por
                                    Rewards
                                </div>
                            </div>
                        </GridItem>
                        <GridItem md={4} xs={12}></GridItem>
                    </GridContainer>
                </div>
            }
        />
    );
};

const mapDispatchToProps = {
    ...modalActions
};

export default withRouter(
    connect(
        null,
        mapDispatchToProps
    )(FooterGlobal)
);
