import { cnnAjax } from "services/connect/apiPuntos";

export const getBanners = async () => {
    const { response } = await cnnAjax("publicidad", "GET");

    return response;
};

export const getBannersPromoServices = async () => {
    const response = await cnnAjax("banners/promo", "GET");

    return response;
};
