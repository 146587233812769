import React from "react";
import Chip from "@material-ui/core/Chip";
import { makeStyles } from "@material-ui/core/styles";
// redux
import { connect } from "react-redux";
import * as filterProductsActions from "../../../redux/actions/filterProductsActions";

import { transforFormatNumber } from "utils/utils";

import styles from "assets/jss/material-kit-pro-react/views/ecommerceSections/productsStyle.js";

const useStyles = makeStyles(styles);

// ========== Internal componente !! ============= !!
const ChipTab = ({ label, onClick, onDelete }) => {
    const classes = useStyles();

    return (
        <Chip
            classes={{ root: classes.chipRoot }}
            variant="outlined"
            size="small"
            label={label}
            onClick={onClick}
            onDelete={onDelete}
        />
    );
};

const TagsFilters = props => {
    const handleSetNameFilter = filter => {
        switch (filter[0]) {
            case "betweenstart":
                return `Desde: ${transforFormatNumber(filter[1])}`;

            case "betweenend":
                return `Hasta: ${transforFormatNumber(filter[1])}`;

            case "idmarca":
                return filter[1];

            case "envio":
                return "Envío Nacional: Si";

            default:
                return "filtro";
        }
    };

    const handleRemoveFilter = filter => {
        const { changeRank, setEnvio } = props;
        switch (filter[0]) {
            case "betweenstart":
                changeRank("", "");
                break;
            case "betweenend":
                changeRank("", "");
                break;
            case "envio":
                setEnvio("");
                break;

            default:
                break;
        }
    };

    const handleShowSingleTags = () => {
        const filters = Object.entries(props.filterProductsReducer);
        return filters.map((filter, i) => {
            if (filter[0] === "orderBy" || filter[0] === "typeOrder")
                return null;

            if (!!filter[1] && filter[0] !== "idmarca") {
                return (
                    <ChipTab
                        key={`${filter[0]}-${i}`}
                        label={handleSetNameFilter(filter)}
                        onClick={() => handleRemoveFilter(filter)}
                        onDelete={() => handleRemoveFilter(filter)}
                    />
                );
            } else if (filter[0] == "idmarca") {
                return <ChipMarcas filter={filter} />;
            }
        });
    };

    // mini component for chip brand !!
    const ChipMarcas = ({ filter }) => {
        const { changeRemoveFilter } = props;
        return filter[1].map((filt, i) => {
            const { marca, idmarca } = handleGetPropsMarcar(filt);
            return (
                <ChipTab
                    key={`${filt}-${i}`}
                    label={marca}
                    onClick={() => changeRemoveFilter(idmarca)}
                    onDelete={() => changeRemoveFilter(idmarca)}
                />
            );
        });
    };

    // busca la data de la marca por si id !!
    const handleGetPropsMarcar = filter => {
        if (!!filter) {
            const { marcas } = props.marcasReducer;
            const marca = marcas.find(m => m.idmarca === filter);

            return marca;
        }
    };

    return handleShowSingleTags();
};

const mapStateToProps = ({ filterProductsReducer, marcasReducer }) => {
    return {
        filterProductsReducer,
        marcasReducer
    };
};

const mapDispatchToProps = {
    ...filterProductsActions
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(TagsFilters);
