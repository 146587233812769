import React from 'react';
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import productStyle from "assets/jss/material-kit-pro-react/views/productStyle.js";

const useStyles = makeStyles(productStyle);

const FixedBottom = ({ classBody, ...props }) => {
    const classes = useStyles();

    return (
        <Card color="darkBlue" className={classes.fixedBottom}>
            <CardBody className={classBody}>
                {props.children}
            </CardBody>
        </Card>
    )
}

export default FixedBottom
