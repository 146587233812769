import React from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-kit-pro-react/views/signupPageStyle.js";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import CustomInput from "components/CustomInput/CustomInput";

const useStyles = makeStyles(styles);

const SelectInput = props => {
    const classes = useStyles();

    return (
        <div style={{ display: "flex", alignItems: "center" }}>
            <FormControl fullWidth style={{ width: "80px" }}>
                <Select
                    // MenuProps={{
                    //     className: classes.selectMenu
                    // }}
                    // classes={{
                    //     select: classes.select
                    // }}
                    value={props.selectValue}
                    onChange={props.handleChange}
                    required={true}
                    inputProps={{
                        name: "tipo_doc"
                    }}
                >
                    <MenuItem
                        // classes={{
                        //     root: classes.selectMenuItem
                        // }}
                        value="L"
                    >
                        DNI
                    </MenuItem>
                    <MenuItem
                        // classes={{
                        //     root: classes.selectMenuItem
                        // }}
                        value="E"
                    >
                        CE
                    </MenuItem>
                </Select>
            </FormControl>
            <CustomInput
                labelText="*Número de documento"
                labelClassName={classes.labelSize}
                formControlProps={{
                    fullWidth: true
                }}
                inputProps={{
                    style: { marginBottom: "10px" },
                    value: props.inputValue,
                    name: "documento_identidad",
                    onChange: props.handleChange
                }}
            />
        </div>
    );
};

SelectInput.propTypes = {};

export default SelectInput;
