export const PAGINATE = "paginate";

export const MARCAS_SET_FILTER = "marcas_set_filter";

export const MARCAS_REMOVE_FILTER = "marcas_remove_filter";

export const RANGO = "rango";

export const CHANGE_FILTER = "change_filter";

export const RESET_FILTER = "reset_filter";

export const ENVIO = "envio";

export const ORDER_FILTER = "order_filter";