import {
	GET_PRODUCTS_RECOMMENDED,
	IS_LOADING,
	ERROR
} from "../../types/productsRecommendedTypes";

const INITIAL_STATE = {
	productsRecommended: [],
	isLoading: false,
	error: ""
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case GET_PRODUCTS_RECOMMENDED:
			return {
				...state,
				productsRecommended: action.payload,
				isLoading: false
			};
		case IS_LOADING:
			return { ...state, isLoading: true };
		case ERROR:
			return { ...state, error: action.payload, isLoading: false };

		default:
			return state;
	}
};
