import { getProducts as getProductsApi } from "services/home/productsServices";
import {
    ERROR,
    IS_LOADING,
    GET_PRODUCTS,
    GET_PRODUCT_BY_ID,
    SET_PRODUCT_SELECT,
    DELETE_PRODUCT_SELECT
} from "redux/types/productsTypes";
import { getProductById } from "services/home/productsServices";
import { getFeaturesByCod } from "services/home/productsServices";
import { SEARCT_PRODUTS } from "redux/types/productsTypes";
import { searchProdctServices } from "services/home/productsServices";
import { getProductsOffers } from "services/home/productsServices";

export const getProductsAction = (category, body = {}) => async dispatch => {
    dispatch({
        type: IS_LOADING
    });

    try {
        const products = await getProductsApi(category, body);

        return dispatch({
            type: GET_PRODUCTS,
            payload: products
        });
    } catch (error) {
        return dispatch({
            type: ERROR,
            payload: error
        });
    }
};

// request para optener un producto por [id]
export const getProdutcById = id => async dispatch => {
    dispatch({
        type: IS_LOADING
    });

    dispatch({ type: DELETE_PRODUCT_SELECT });

    try {
        const { response } = await getProductById(id);

        const features = await getFeaturesByCod(response.codproducto);
        response.caracteristicas = features.response;

        return dispatch({
            type: GET_PRODUCT_BY_ID,
            payload: response
        });
    } catch (error) {
        return dispatch({
            type: ERROR,
            payload: error
        });
    }
};

export const searchProduct = search => async dispatch => {
    dispatch({
        type: IS_LOADING
    });

    const products = await searchProdctServices(search);

    return dispatch({
        type: SEARCT_PRODUTS,
        payload: { searchProducts: products, keySearchProduts: search }
    });
};

// agrega un producto seleccionado al estado !!
export const setProductSelect = product => async dispatch => {
    dispatch({ type: DELETE_PRODUCT_SELECT });
    try {
        const features = await getFeaturesByCod(product.codproducto);
        product.caracteristicas = features.response;

        return dispatch({
            type: SET_PRODUCT_SELECT,
            payload: product
        });
    } catch (error) {
        return dispatch({
            type: ERROR,
            payload: error
        });
    }
};

export const getProductsOffersAction = () => async dispatch => {
    dispatch({
        type: IS_LOADING
    });

    try {
        const products = await getProductsOffers();
        return dispatch({
            type: GET_PRODUCTS,
            payload: products
        });
    } catch (error) {
        return dispatch({
            type: ERROR,
            payload: error
        });
    }
};
