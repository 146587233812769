import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";
import Info from "components/Typography/Info";
import RemoveRedEyeIcon from "@material-ui/icons/RemoveRedEye";
import RemoveRedEyeOutlinedIcon from "@material-ui/icons/RemoveRedEyeOutlined";

// CAPCHAT
import ReCAPTCHA from "react-google-recaptcha";
import { CAPCHA_KEY } from "config/const/capchat";

// core components
import Button from "components/CustomButtons/Button.js";
import CardBody from "components/Card/CardBody.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Danger from "components/Typography/Danger.js";
import SnackbarContent from "components/Snackbar/SnackbarContent.js";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
// redux
import { connect } from "react-redux";
import * as userActions from "../../redux/actions/userActions";
import * as formActions from "../../redux/actions/formActions";

import loginPageStyle from "assets/jss/material-kit-pro-react/views/loginPageStyle.js";
import moment from "moment";
// formik
import { Formik, Form } from "formik";
import { ModalLoader } from "components/Modal/ModalLoader";
import { login } from "services/user/userServices";
import { setTimeStorage } from "services/localStorage/timeStorage";
import { SignupSchema } from "./shema/loginShema";

// styles
const useStyles = makeStyles(loginPageStyle);

const ErrorLogin = "Documento o contraseña, incorrectos";

/**
 * Formulario de login, cuando todo sale bien, llama a una funcion {successLogin} que se envia desde fuera !!
 * @param {function} props.successLogin: Es una funcion que se llama cuando el login es correcto
 */
const FormLogin = props => {
    const classes = useStyles();
    const [inputLogin, SetInputLogin] = useState({
        numero_documento: "",
        password: "",
        tipo_doc: "L"
    });
    const [loginModal, setLoginModal] = useState(false);
    const [incorrectUser, setIncorrectUser] = useState(false);
    const [tiposDocumentos, setTiposDocumentos] = useState([]);
    const [tokenCapchat, setTokenCapchat] = useState(null);
    const [showPassword, setShowPassword] = useState(false);

    useEffect(() => {
        handleTipoDocumento();
    }, []);

    useEffect(() => {
        setTokenCapchat(null);
        return () => {
            setTokenCapchat(null);
        };
    }, [props.loginModal.show]);

    const handleLogin = async inputForm => {
        if (!tokenCapchat) return; // si no existe el token, no deja pasar !!

        let formLogin = { ...inputForm };
        setLoginModal(true);
        setIncorrectUser(false);

        formLogin.password = btoa(formLogin.password);
        formLogin.numero_documento = btoa(formLogin.numero_documento);
        formLogin.tipo_doc = btoa(formLogin.tipo_doc);
        formLogin.captcha = tokenCapchat;

        const resp = await login(formLogin);

        if ((!resp && resp.status === "error") || !resp.auth) {
            setLoginModal(false);
            setIncorrectUser(true);
            return;
        }

        const { response, auth } = resp;

        handleHasCredictCard(response);

        setLoginModal(false);
        props.setUserInfo(response);
        setTimeStorage(moment());
        // setAuthStorage(`${auth.token_type} ${auth.token}`);
        props.successLogin(); // cuando todo sale bien, llama a esta funcion que se le pasa el componente!!
    };

    // genera las opciones del select box !!
    const setSelect = () => {
        return props.type_document.map((tipo, index) => (
            <MenuItem
                key={`select-${index}`}
                classes={{
                    root: classes.selectMenuItem,
                    selected: classes.selectMenuItemSelected
                }}
                value={tipo.tipo_doc}
            >
                {tipo.nombre}
            </MenuItem>
        ));
    };

    // busca los tipos de documnetos aceptados !!
    const handleTipoDocumento = async () => {
        if (!props.type_document.length) {
            const resp = await props.getTypeDocument();
            setTiposDocumentos(resp);
        }
    };

    // verifica si el usuario tiene numero de tarjeta y realiza la peticion de puntos!!
    const handleHasCredictCard = user => {
        const body = {
            token: user.token
        };

        props.getPointsUser(body);
    };

    const onChangeCapcha = value => {
        setTokenCapchat(value);
    };

    const showChapchat = () => {
        if (props.loginModal.show && !loginModal) {
            return (
                <div className={classes.centerFlex}>
                    <ReCAPTCHA sitekey={CAPCHA_KEY} onChange={onChangeCapcha} />
                </div>
            );
        }

        return null;
    };

    return (
        <>
            <ModalLoader showModal={loginModal} />
            <Formik
                initialValues={inputLogin}
                validationSchema={SignupSchema}
                onSubmit={handleLogin}
                render={({ handleChange, values, touched, errors }) => {
                    return (
                        <Form className={classes.form}>
                            <CardBody className={classes.noPaddingTop}>
                                <h1
                                    className={classNames(
                                        classes.colorTextGray,
                                        classes.textCenter,
                                        classes.fontSizeTitle
                                    )}
                                >
                                    ¡Bienvenido!
                                </h1>
                                <h5>&nbsp;</h5>
                                <FormControl
                                    fullWidth
                                    className={classes.selectFormControl}
                                >
                                    <InputLabel
                                        htmlFor="tipoDocumento"
                                        className={classes.fontLabel}
                                    >
                                        Tipo de documento
                                    </InputLabel>
                                    <Select
                                        MenuProps={{
                                            className: classes.selectMenu
                                        }}
                                        classes={{
                                            select: classes.select
                                        }}
                                        value={values.tipo_doc}
                                        onChange={handleChange}
                                        required={true}
                                        inputProps={{
                                            name: "tipo_doc",
                                            id: "tipoDocumento"
                                        }}
                                    >
                                        <MenuItem
                                            disabled
                                            classes={{
                                                root: classes.selectMenuItem
                                            }}
                                        >
                                            Seleccione tipo de documento
                                        </MenuItem>

                                        {!!props.type_document &&
                                        !!props.type_document.length
                                            ? setSelect()
                                            : null}
                                    </Select>
                                </FormControl>

                                <CustomInput
                                    labelText="Número de documento"
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    error={
                                        !!errors.numero_documento &&
                                        touched.numero_documento
                                    }
                                    inputProps={{
                                        type: "text",
                                        // placeholder: "Numero de documento",
                                        name: "numero_documento",
                                        onChange: handleChange,
                                        value: values.numero_documento,
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <Icon
                                                    className={
                                                        classes.inputIconsColor
                                                    }
                                                >
                                                    assignment_ind
                                                </Icon>
                                            </InputAdornment>
                                        )
                                    }}
                                />
                                {errors.numero_documento &&
                                    touched.numero_documento && (
                                        <Danger>
                                            {errors.numero_documento}
                                        </Danger>
                                    )}

                                <CustomInput
                                    labelText="Contraseña"
                                    error={
                                        !!errors.password && touched.password
                                    }
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    inputProps={{
                                        // placeholder: "Contraseña",
                                        type: showPassword
                                            ? "text"
                                            : "password",
                                        name: "password",
                                        value: values.password,
                                        onChange: handleChange,
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <Icon
                                                    className={
                                                        classes.inputIconsColor
                                                    }
                                                >
                                                    lock_utline
                                                </Icon>
                                            </InputAdornment>
                                        ),
                                        endAdornment: (
                                            <InputAdornment
                                                className={classes.showPassword}
                                                onClick={() =>
                                                    setShowPassword(
                                                        !showPassword
                                                    )
                                                }
                                            >
                                                {showPassword ? (
                                                    <RemoveRedEyeIcon />
                                                ) : (
                                                    <RemoveRedEyeOutlinedIcon />
                                                )}
                                            </InputAdornment>
                                        ),
                                        autoComplete: "off"
                                    }}
                                />
                                <div>
                                    {/* TODO: Agregar un enlace a este mensaje */}
                                    <Link
                                        className={classNames(
                                            classes.smallPassword,
                                            classes.linkLight
                                        )}
                                        to="/olvido/clave"
                                        onClick={props.closeModal}
                                    >
                                        Olvidé mi contraseña
                                    </Link>
                                </div>
                                {errors.password && touched.password && (
                                    <Danger>{errors.password}</Danger>
                                )}
                            </CardBody>
                            {incorrectUser && (
                                <SnackbarContent
                                    message={
                                        <span>
                                            <b>Error : </b>
                                            {ErrorLogin}
                                        </span>
                                    }
                                    close
                                    color="danger"
                                    icon="info_outline"
                                />
                            )}
                            {showChapchat()}
                            <div className={classes.textCenter}>
                                <Button
                                    color="info"
                                    size="lg"
                                    type="submit"
                                    disabled={!tokenCapchat}
                                >
                                    INGRESAR
                                </Button>
                            </div>
                        </Form>
                    );
                }}
            />
            <GridContainer justify="center">
                <GridItem xs={12} sm={12} md={12}>
                    <div className={classes.textCenter}>
                        ¿Aún no te has registrado?
                        <div>
                            {" "}
                            <Link
                                to="/registro"
                                className={classes.infoText}
                                onClick={props.closeModal}
                            >
                                <Info>
                                    Regístrate {"  "}
                                    Aquí
                                </Info>
                            </Link>
                        </div>
                    </div>
                </GridItem>
            </GridContainer>
        </>
    );
};

const mapStateToProps = ({ formReducer, userReducer, ModalsReducer }) => ({
    ...userReducer,
    ...formReducer,
    ...ModalsReducer
});

const mapDispatchToProps = {
    ...userActions,
    ...formActions
};

FormLogin.defaultProps = {
    successLogin: () => {}
};

FormLogin.propTypes = {
    successLogin: PropTypes.func
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(FormLogin);
