import {
	GET_TYPE_DOCUMENT,
	GET_TYPE_DOCUMENT_BY_ID,
	VALIDATE_EMAIL
} from "../../types/formTypes";

const INITIAL_STATE = {
	type_document: [],
	select_document: {}
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case GET_TYPE_DOCUMENT:
			return { ...state, type_document: action.payload };

		case GET_TYPE_DOCUMENT_BY_ID:
			return { ...state, select_document: action.payload };

		default:
			return state;
	}
};
