import { MenuItem, makeStyles } from "@material-ui/core";
import React from "react";
import profilePageStyle from "assets/jss/material-kit-pro-react/views/profilePageStyle.js";

const useStyles = makeStyles(profilePageStyle);

const ListItems = ({ listItems, selectId, selectName }) => {
    const classes = useStyles();
    // if (!listItems.length) return null;

    return listItems.map((ubigeo, index) => (
        <MenuItem
            key={`select-${ubigeo[selectId]}-${index}`}
            classes={{
                root: classes.selectMenuItem,
                selected: classes.selectMenuItemSelected
            }}
            value={ubigeo[selectId]}
        >
            {ubigeo[selectName]}
        </MenuItem>
    ));
};

export default ListItems;
