import { cnn, cnnAjax } from "../connect/apiPuntos";

//REFACTOR: si este archivo crece mucho, crear una clase !!

// el path base de las direccion !!
const BASE_PATH = "direcciones";

/** All <-
 * Obtiene todos los [departamentos] !!
 */
export const getDepartamentos = async () => {
    const path = `${BASE_PATH}/departamentos`;
    const { data } = await cnn(path, "GET");
    return data;
};

/**  All <-
 * Obtiene todos las [provincias] que pertenecen a ese departamento !!
 * @param {string} idDepartamento id del departamento seleccionado
 */
export const getProvincias = async idDepartamento => {
    const path = `${BASE_PATH}/provincias/${idDepartamento}`;
    const { data } = await cnn(path, "GET");

    return data;
};

/** All <-
 * Obtiene todas los [distritos] de esa pronvincia !!
 * @param {string} idProvincia id de la provincia seleccionada
 */
export const getDistritos = async idProvincia => {
    const path = `${BASE_PATH}/distritos/${idProvincia}`;
    const { data } = await cnn(path, "GET");
    return data;
};

////////////////////////// Singles !! //////////////////////

/** @OJO Single <-
 * Obtiene un [departamento] por [id] !!
 * @param {string} id id del departamento !
 */
export const getDepartamento = async id => {
    const path = `${BASE_PATH}/departamento/${id}`;
    const { data } = await cnn(path, "GET");
    return data;
};

/** @OJO Single <-
 * Obtiene la [provincia] por su [id] !!
 * @param {string} id id del la provincia !!
 */
export const getProvincia = async id => {
    const path = `${BASE_PATH}/provincia/${id}`;
    const { data } = await cnn(path, "GET");
    return data;
};

/** Single <-
 * Obtiene el [distrito] por su id !!
 * @param {string} id id del distrito seleccionada
 */
export const getDistrito = async id => {
    const path = `${BASE_PATH}/distrito/${id}`;
    const data = await cnnAjax(path, "GET");

    return data;
};

export const getPathComplete = async (
    idDepartamento,
    idProvincia,
    idDistrito
) => {

    const path = `${BASE_PATH}/departamento/${idDepartamento}/provincia/${idProvincia}/distrito/${idDistrito}`;
    const data = await cnnAjax(path, "GET");

    return data;
};
////////////////////// Singles End ////////////////////////////

/**
 * Optiene las direccions de un usuario !!
 * @param {string} id id del usuario
 */
export const directionByUser = async token => {
    const response = await cnnAjax(`${BASE_PATH}/user`, "GET", null, {
        token
    });

    return response;
};

/**
 * Crear una direccion al usuario
 * @param {object} body Los datos a guadar
 */
export const createDirectionByUserId = async body => {
    const data = await cnnAjax(`${BASE_PATH}/create`, "POST", null, body);

    return data;
};

/**
 * Actualiza la direccion de usuario
 * @param {object} direction Direccion del usuario actualizada
 */
export const updateDirection = async direction => {
    const data = await cnnAjax(`${BASE_PATH}/update`, "POST", null, direction);

    return data;
};

export const updateActiveDirection = async (directionId, token) => {
    const resp = await cnnAjax(
        `${BASE_PATH}/active/${directionId}`,
        "POST",
        null,
        { token }
    );

    return resp;
};

export const removeDirectionServices = async (idDirection, token) => {
    const data = await cnnAjax(
        `${BASE_PATH}/delete/${idDirection}`,
        "POST",
        null,
        { token }
    );

    return data;
};
