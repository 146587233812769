import { cnnAjax } from "services/connect/apiPuntos"

const BASE_PATH = "cupon";

export const searchCupon = async (codecupon, token) => {
    const path = `${BASE_PATH}/search`;
    const data = { token, codigo: codecupon};
    const { cupon } = await cnnAjax(path, "POST", null, data);

    return cupon;
}