
const creditCardStyle = {
    rootCreditCard: {
        borderRadius: "15px",
        width: "280px",
        height: "440px",
        backgroundSize: "cover"
    },
    body: { display: "flex", alignItems: "center", justifyContent: "center" },
    textName: {
        position: "absolute",
        bottom: "130px",
        left: "30px",
        fontFamily: "Source Sans Pro",
        textTransform: "uppercase"
    }
}

export default creditCardStyle; 