import {
    PAGINATE,
    RANGO,
    MARCAS_SET_FILTER,
    MARCAS_REMOVE_FILTER,
    RESET_FILTER,
    ENVIO,
    ORDER_FILTER
} from "../../types/filterProductsTypes";

const INITIAL_STATE = {
    betweenstart: "",
    betweenend: "",
    idmarca: [],
    envio: "",
    orderBy: "marca",
    typeOrder: "asc"
};

export default (state = { ...INITIAL_STATE }, action) => {
    switch (action.type) {
        case MARCAS_SET_FILTER:
            return setBrand(state, action);

        case MARCAS_REMOVE_FILTER:
            return removeBrand(state, action);

        case ENVIO:
            return { ...state, envio: action.payload };

        case RANGO:
            return {
                ...state,
                betweenstart: action.payload.start,
                betweenend: action.payload.end
            };

        case ORDER_FILTER:
            return {
                ...state,
                orderBy: action.payload.orderBy,
                typeOrder: action.payload.typeOrder
            };

        case RESET_FILTER:
            return {
                ...INITIAL_STATE,
                idmarca: []
            };

        default:
            return state;
    }
};

const setBrand = (state, action) => {
    const newState = { ...state };
    newState.idmarca.push(action.payload);

    return newState;
};

const removeBrand = (state, action) => {
    const newState = { ...state };
    newState.idmarca = newState.idmarca.filter(id => id !== action.payload);

    return newState;
};
