import React, { useEffect, useState } from "react";
import WrapperMovile from "hoc/WrapperMovile";
import classNames from "classnames";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// styles !!
import signupPageStyle from "assets/jss/material-kit-pro-react/views/signupPageStyle.js";

// CAPCHAT
import ReCAPTCHA from "react-google-recaptcha";
import { CAPCHA_KEY } from "config/const/capchat";

import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";

import { REGISTRE_INFO_AREA_TITLE } from "config/const/registreText";
import { REGISTRE_INFO_AREA_DESCRIPTION } from "config/const/registreText";

// redux
import { connect } from "react-redux";
import * as userActions from "../../redux/actions/userActions";
import { toggleCreditCard } from "../../redux/actions/modalActions";

import { getUserStorage } from "../../services/localStorage/userStorage";
import InfoAreaBbva from "components/InfoAreaBbva/InfoAreaBbva";
import SelectInput from "components/SelectInput/SelectInput";
import CustomInput from "components/CustomInput/CustomInput";
import { Checkbox, FormControlLabel } from "@material-ui/core";
import SnackbarContent from "components/Snackbar/SnackbarContent.js";
import Button from "components/CustomButtons/Button.js";
import { Check } from "@material-ui/icons";
import { PUNTOS } from "config/const/constText";
import { capitalize } from "utils/utils";
import { userHomeBanking } from "services/user/userServices";
import { saveRegistre } from "services/user/userServices";
import queryString from "query-string";
import useResize from "Hooks/useResize/UseResize";
import { baseRouteImages } from "config/config.json";

const useStyles = makeStyles(signupPageStyle);

const SignupPage = props => {
    const classes = useStyles();

    const [checked, setChecked] = useState({
        novedades: false,
        terminos: false
    });
    const [formValues, setFormValues] = useState({
        tipo_doc: "L",
        documento_identidad: "",
        nombres: "",
        apellidos: "",
        email: "",
        tipo_usuario: "S"
    });
    const [btnDisabled, setBtnDisabled] = useState(true);
    const [errorRegistre, setErrorRegistre] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [tokenCapchat, setTokenCapchat] = useState(null); //  captcha
    const [showCapchat, setShowCapchat] = useState(true);
    const [saveRegister, setSaveRegister] = useState(false);

    const [_, isMobil] = useResize();

    useEffect(() => {
        setBtnDisabled(!checked.terminos);
    }, [checked]);

    useEffect(() => {
        const { t } = queryString.parse(window.location.search);
        if (t) {
            handleGetUserBanking(t);
        }
        goTop();
        return () => {
            setTokenCapchat(null);
        };
    }, []);

    useEffect(() => {
        const { show } = props.ModalsReducer.creditCard;
        if (saveRegister && !show) {
            handleAutoLogin();
        }
    }, [props.ModalsReducer]);

    const goTop = () => {
        window.scrollTo(0, 0);
        document.body.scrollTop = 0;
    };

    const handleGetUserBanking = async token => {
        const user = await userHomeBanking({ token });
        if (!!user.response) {
            setFormValues({ ...formValues, ...user.response });
        }
    };

    const onChangeCapcha = value => {
        setTokenCapchat(value);
    };

    const handleChange = event => {
        const target = event.target;
        const name = target.name;
        const value = target.value;

        setFormValues({ ...formValues, [name]: value });
    };

    //submit, registra al usuario y lo logea !!
    const handleSubmit = async event => {
        event.preventDefault();
        if (!tokenCapchat) return; // si no existe el token, no deja pasar !!

        setShowCapchat(false);
        setErrorRegistre("");
        setIsLoading(true);

        formValues.password_confirmation = formValues.password;
        formValues.captcha = tokenCapchat;
        handleCapitalizeDataForm(formValues);

        const data = await saveRegistre(formValues);
        if (data.status === "error") {
            if (
                data.message.status === 500 ||
                typeof data.message != "string"
            ) {
                data.message = "Ops!, Ha ocurrido un error inesperado";
            }
            setErrorRegistre(data.message);
            setIsLoading(false);
            setShowCapchat(true);
            return;
        }
        setSaveRegister(true);
        props.setUserInfo(data.response);
        setIsLoading(false);
        handleAutoLogin();
    };

    const handleAutoLogin = () => {
        const data = getUserStorage();
        // props.getPointsUser({ token: data.token });
        props.history.push("/", { register: true });
    };

    const handleCapitalizeDataForm = formValues => {
        formValues.nombres = capitalize(formValues.nombres.trim());
        formValues.apellidos = capitalize(formValues.apellidos.trim());
    };

    const showChapchat = () => {
        if (showCapchat) {
            return (
                <div
                    className={classNames(
                        classes.centerFlex,
                        classes.marginCaptcha
                    )}
                >
                    <ReCAPTCHA sitekey={CAPCHA_KEY} onChange={onChangeCapcha} />
                </div>
            );
        }

        return null;
    };

    const handleImagenRegister = () => {
        return baseRouteImages + "home/register.svg";
    };

    return (
        <WrapperMovile>
            <div
                className={classNames(
                    classes.container,
                    isMobil ? classes.pt0 : ""
                )}
            >
                <GridContainer>
                    <GridItem
                        xs={12}
                        sm={6}
                        md={6}
                        className={isMobil ? classes.hidden : ""}
                    >
                        <InfoAreaBbva
                            title={REGISTRE_INFO_AREA_TITLE}
                            description={REGISTRE_INFO_AREA_DESCRIPTION}
                            isDanger
                            image={
                                <img
                                    src={handleImagenRegister()}
                                    alt="Logo register"
                                    className={classes.image}
                                />
                            }
                        />
                    </GridItem>
                    <GridItem
                        xs={1}
                        sm={2}
                        md={2}
                        className={isMobil ? classes.hidden : ""}
                    ></GridItem>
                    <GridItem xs={12} sm={4} md={4}>
                        <h2 className={classes.cardTitle}>Crear una cuenta</h2>
                        <form onSubmit={handleSubmit}>
                            <GridContainer>
                                <GridItem
                                    xs={12}
                                    sm={12}
                                    md={12}
                                    className={classes.containerSelectInput}
                                >
                                    <SelectInput
                                        handleChange={handleChange}
                                        selectValue={formValues.tipo_doc}
                                        inputValue={
                                            formValues.documento_identidad
                                        }
                                    />
                                </GridItem>

                                <GridItem
                                    xs={12}
                                    sm={12}
                                    md={12}
                                    className={classes.gridInputs}
                                >
                                    <CustomInput
                                        labelText="*Nombres"
                                        labelClassName={classes.labelSize}
                                        formControlProps={{
                                            className: classes.formControl,
                                            className:
                                                classes.separationFirstInput
                                        }}
                                        inputProps={{
                                            required: true,
                                            name: "nombres",
                                            onChange: handleChange
                                        }}
                                    />
                                    <CustomInput
                                        labelText="*Apellidos"
                                        labelClassName={classes.labelSize}
                                        formControlProps={{
                                            className: classes.formControl,
                                            className:
                                                classes.separationFirstInput
                                        }}
                                        inputProps={{
                                            required: true,
                                            name: "apellidos",
                                            onChange: handleChange
                                        }}
                                    />
                                </GridItem>
                                <GridItem
                                    xs={12}
                                    sm={12}
                                    md={12}
                                    className={classes.gridInputs}
                                >
                                    <CustomInput
                                        labelClassName={classes.labelSize}
                                        labelText="*Correo electrónico"
                                        formControlProps={{
                                            fullWidth: true,
                                            className: classes.separationInputs
                                        }}
                                        inputProps={{
                                            name: "email",
                                            required: true,

                                            type: "email",
                                            onChange: handleChange
                                        }}
                                    />
                                </GridItem>
                                <GridItem
                                    xs={12}
                                    sm={12}
                                    md={12}
                                    className={classes.gridInputs}
                                >
                                    <CustomInput
                                        labelText="*Contraseña"
                                        formControlProps={{
                                            fullWidth: true,
                                            className: classes.separationInputs
                                        }}
                                        labelClassName={classes.labelSize}
                                        inputProps={{
                                            type: "password",
                                            required: true,
                                            name: "password",
                                            onChange: handleChange
                                        }}
                                    />
                                </GridItem>
                                {/* <GridItem
                                    xs={12}
                                    sm={12}
                                    md={12}
                                    className={classes.gridInputs}
                                >
                                    <CustomInput
                                        labelText="*Validacion de Contraseña"
                                        labelClassName={classes.labelSize}
                                        formControlProps={{
                                            fullWidth: true,
                                            className: classes.separationInputs
                                        }}
                                        inputProps={{
                                            required: true,
                                            type: "password",
                                            required: true,
                                            name: "password2"
                                        }}
                                    />
                                </GridItem> */}
                                <GridItem
                                    xs={12}
                                    sm={12}
                                    md={12}
                                    className={classNames(
                                        classes.gridInputs,
                                        classes.mt12
                                    )}
                                >
                                    <FormControlLabel
                                        classes={{
                                            label: classes.label
                                        }}
                                        control={
                                            <Checkbox
                                                tabIndex={-1}
                                                onClick={ev =>
                                                    setChecked({
                                                        ...checked,
                                                        novedades:
                                                            ev.target.checked
                                                    })
                                                }
                                                checkedIcon={
                                                    <Check
                                                        className={
                                                            classes.checkedIcon
                                                        }
                                                    />
                                                }
                                                icon={
                                                    <Check
                                                        className={
                                                            classes.uncheckedIcon
                                                        }
                                                    />
                                                }
                                                classes={{
                                                    checked: classes.checked,
                                                    root: classes.checkRoot
                                                }}
                                                checked={checked.novedades}
                                                inputProps={{
                                                    name: "novedades"
                                                }}
                                            />
                                        }
                                        label={
                                            <span
                                                className={classNames(
                                                    classes.ffBook,
                                                    classes.labelSize12
                                                )}
                                            >
                                                Acepto recibir promociones con{" "}
                                                {PUNTOS}
                                            </span>
                                        }
                                    />
                                </GridItem>
                                <GridItem
                                    xs={12}
                                    sm={12}
                                    md={12}
                                    className={classNames(
                                        classes.gridInputs,
                                        classes.mt12
                                    )}
                                >
                                    <FormControlLabel
                                        classes={{
                                            label: classes.label
                                        }}
                                        //Acepto recibir vía e-mail Novedades y Promociones
                                        control={
                                            <Checkbox
                                                tabIndex={-2}
                                                onClick={ev => {
                                                    setChecked({
                                                        ...checked,
                                                        terminos:
                                                            ev.target.checked
                                                    });
                                                }}
                                                checkedIcon={
                                                    <Check
                                                        className={
                                                            classes.checkedIcon
                                                        }
                                                    />
                                                }
                                                icon={
                                                    <Check
                                                        className={
                                                            classes.uncheckedIcon
                                                        }
                                                    />
                                                }
                                                classes={{
                                                    checked: classes.checked,
                                                    root: classes.checkRoot
                                                }}
                                                checked={checked.terminos}
                                                inputProps={{
                                                    name: "terminos"
                                                }}
                                            />
                                        }
                                        label={
                                            <span
                                                className={classNames(
                                                    classes.ffBook,
                                                    classes.labelSize12
                                                )}
                                            >
                                                Acepto los
                                                <b className={classes.ffBook}>
                                                    <a
                                                        href="https://rewardsperuhelp.zendesk.com/hc/es-419/sections/13533082025243-T%C3%A9rminos-y-Condiciones"
                                                        className={classNames(
                                                            classes.ffBook,

                                                            classes.colorBlue
                                                        )}
                                                        target="_black"
                                                    >
                                                        {" "}
                                                        Términos y Condiciones
                                                    </a>
                                                </b>{" "}
                                                {/* REFACTOR: */}
                                                de Puntos Delivery
                                            </span>
                                        }
                                    />
                                </GridItem>
                                <div className={classes.centerBox}>
                                    {showChapchat()}
                                </div>

                                {!!errorRegistre.length && (
                                    <GridItem
                                        xs={12}
                                        sm={12}
                                        md={12}
                                        className={classNames(
                                            classes.gridInputs,
                                            classes.mt12
                                        )}
                                    >
                                        <SnackbarContent
                                            message={
                                                <span>
                                                    <b>Error:</b>
                                                    {errorRegistre}
                                                </span>
                                            }
                                            close
                                            color="danger"
                                            icon="info_outline"
                                        />
                                    </GridItem>
                                )}

                                <div className={classes.centerBox}>
                                    <Button
                                        round
                                        fullWidth
                                        size="lg"
                                        color="info"
                                        type="submit"
                                        disabled={
                                            btnDisabled ||
                                            isLoading /*||
                                            !tokenCapchat*/
                                        }
                                    >
                                        {isLoading ? (
                                            <i className="loader"></i>
                                        ) : (
                                            "Registrarme"
                                        )}
                                    </Button>
                                </div>
                            </GridContainer>
                        </form>
                    </GridItem>
                </GridContainer>
            </div>
        </WrapperMovile>
    );
};

const mapStateToProps = ({ userReducer, ModalsReducer }) => {
    return { ...userReducer, ModalsReducer };
};

const mapDispatchToProps = {
    ...userActions,
    toggleCreditCard
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SignupPage);
