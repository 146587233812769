import {
    section,
    container,
    cardTitle,
    main,
    mainRaised,
    mrAuto,
    mlAuto,
    ffBook
} from "assets/jss/material-kit-pro-react.js";

import buttonGroup from "assets/jss/material-kit-pro-react/buttonGroupStyle.js";
import tooltips from "assets/jss/material-kit-pro-react/tooltipsStyle.js";

const styles = {
    ffBook,
    main,
    mainRaised,
    mrAuto,
    mlAuto,
    cardTitle,
    ...buttonGroup,
    ...tooltips,
    section: {
        padding: "70px 0px",
        height: "65vh"
    },
    container: {
        ...container,
        zIndex: 1
    },
    mt4: {
        marginTop: "4em"
    },
    mb0: {
        marginBottom: "0"
    },
    mt10: {
        marginTop: "10px"
    },
    pdl15: {
        paddingLeft: "15px"
    },
    bgWhite: {
        backgroundColor: "#fff"
    },
    center: {
        display: "flex",
        justifyContent: "center"
    },
    title: {
        paddingLeft: "18px",
        paddingBottom: "10px"
    },
    pdTittle: {
        padding: "15px"
    }
};

export default styles;
