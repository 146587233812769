import { cnn } from "../connect/apiPuntos";

// regresa todos los tipos de documentos!!
export const getTipoDocumento = async () => {
	const { data } = await cnn("tipodocumento", "GET");

	return data;
};

/**
 * Busca el tipo de documento por el id !!
 * @param {string} id [1] caracter
 */
export const getTypeDocumentById = async id => {
	const { data } = await cnn(`tipodocumento/${id}`, "GET");
	return data;
};
