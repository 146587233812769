
import React from 'react';
import { LazyLoadImage, trackWindowScroll } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import noImagen from "assets/img/no-image.svg";
import {
    baseRouteImages,
    pathImagenBig,
    pathImagenMedium,
    pathImagenSmall
} from "../../config/config.json";

/* *********   REFACTOR:  *********
* Mejorar el componente, añadiendo mas propiedades para si edicion y agregando cambio de 
* imaneges dependiendo de la resolucion de la pantalla !!
* url de la lib: https://www.npmjs.com/package/react-image-gallery 
**/
const ImageLazy = ({ src, alt, scrollPosition, ...props }) => {

    const handleRouteImage = image => {
        return `${baseRouteImages}${pathImagenSmall}${image}`;
    };

    // maneja el error de la carga de la imagen y coloca una por defecto !!
    const handleError = (e, image) => {
        // e.target.src = noImagen // setea una imagen por defecto!!
        e.target.src = `${baseRouteImages}${pathImagenBig}${image}`
    }

    return (
        <LazyLoadImage
            alt={alt}
            effect="blur"
            src={handleRouteImage(src)}
            scrollPosition={scrollPosition}
            onError={e => handleError(e, src)}
            {...props}
        />
    )
}

export default trackWindowScroll(ImageLazy);


