import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-kit-pro-react/components/infoAreaBbvaStyle.js";

const useStyles = makeStyles(styles);

const InfoAreaBbva = ({ title, description, image, isDanger, className }) => {
    const classes = useStyles();

    const infoAreaClasses = classNames({
        [classes.infoArea]: true,
        [className]: className !== undefined
    });

    const handleShowImage = () => {
        if (!image) return null;

        if (typeof image == "string") {
            return <img src={image} />;
        }

        return image;
    };

    const handleRenderDescription = () => {
        if (isDanger) {
            return (
                <p
                    className={classNames(classes.description)}
                    dangerouslySetInnerHTML={{ __html: description }}
                ></p>
            );
        }

        return <p className={classes.description}>{description}</p>;
    };

    return (
        <div className={infoAreaClasses}>
            <div className={classes.imageCenter}>{handleShowImage()}</div>
            <h2 className={classes.title}>{title}</h2>
            {handleRenderDescription()}
        </div>
    );
};

InfoAreaBbva.propTypes = {
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    image: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    isDanger: PropTypes.bool,
    className: PropTypes.string
};

export default InfoAreaBbva;
