import React, { useState, useEffect, useRef } from "react";

import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

// nodejs library that concatenates classes
import classNames from "classnames";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import { Link } from "react-router-dom";
import Icon from "@material-ui/core/Icon";
import PowerSettingsNew from "@material-ui/icons/PowerSettingsNew";

// redux
import * as categoriesActions from "redux/actions/categoriesActions";
import * as userActions from "redux/actions/userActions";
import * as productsActions from "redux/actions/productsActions";
import * as carActions from "redux/actions/carActions";
import * as filterProductsActions from "redux/actions/filterProductsActions";
import * as modalActions from "redux/actions/modalActions";
import * as headerActions from "redux/actions/headerActions";

// core components
import Header from "components/Header/Header.js";
import Button from "components/CustomButtons/Button.js";

import navbarsStyle from "assets/jss/material-kit-pro-react/views/componentsSections/navbarsStyle.js";
import typographyStyle from "assets/jss/material-kit-pro-react/views/componentsSections/typographyStyle.js";
import "../../assets/css/styles.css";
import CustomDropdown from "components/CustomDropdown/CustomDropdown";

// logos
import logo1 from "assets/img/logos/LogoPuntosDeliveryBlanco.svg";
import { getUserStorage } from "services/localStorage/userStorage";
import { validateLengthObject } from "utils/utils";
import { removeAuthStorage } from "services/localStorage/authStorage";
import { removeUserStorage } from "services/localStorage/userStorage";
import Info from "components/Typography/Info";
import { capitalize } from "utils/utils";
import ModalAddCar from "components/Modal/ModalAddCar";
import { PUNTOS } from "config/const/constText";
import ModalTerminos from "components/Modal/ModalTerminos";
import {
    MESSAGE_NO_POINTS_IN_CARD,
    MESSAGE_ERROR_CONNECTION_POINTS,
    MESSAGE_BLOCK_CARD,
    MESSAGE_CARD_NO_VALID,
    MESSAGE_CARD_NO_EXIST,
    MESSAGE_NO_ACCUMULATE_POINTS
} from "config/const/constText";
import { cutCharacters } from "utils/utils";
import BottomBarInfo from "./Sections/BottomBarInfo";
import CustomInput from "components/CustomInput/CustomInput.js";
import FormLogin from "views/LoginPage/FormLogin";
import SvgIcon from "components/Svg/SvgIcon.js";
import { getAuthStorage } from "services/localStorage/authStorage";
import { getTimeStorage } from "services/localStorage/timeStorage";

import { ModalLoader } from "components/Modal/ModalLoader";
import moment from "moment";
import { capitalizeFirst } from "utils/utils";
import ModalBeetrack from "components/Modal/ModalBeetrack";

const useStylesImagen = makeStyles(typographyStyle);
const useStyles = makeStyles(navbarsStyle);

const HeaderCustom = props => {
    const classes = useStyles();
    const classesImg = useStylesImagen();

    const inputSearch = useRef(null);

    const [categories, setCategories] = useState([]);
    const [userPoints, setUserPoints] = useState(0);
    const [showMessage, setShowMessage] = useState(true);
    const [isMobile, setIsMobile] = useState(false);
    const [headerTransparent, setHeaderTransparent] = useState(true);
    const [iShowSearch, setIShowSearch] = useState(false);
    const [terminoSearch, setTerminoSearch] = useState("");
    const [headerHeight, setHeaderHeight] = useState(0);
    const [isHoverRegister, setIsHoverRegister] = useState(false);
    const [heightModalBeetrack, setHeightModalBeetrack] = useState("310px");

    // use Effects !!
    useEffect(() => {
        if (!!getAuthStorage()) {
            handleSingout();
        }
    });

    useEffect(() => {
        if (iShowSearch) {
            inputSearch.current.focus();
        }
    }, [iShowSearch]);

    useEffect(() => {
        function updateSize() {
            // trabajando en mostrar la tarjeta o el fixed !!
            handleResizeHeight();
            props.setSizeWindow(window.innerWidth);
            if (window.innerWidth <= 959) {
                setIsMobile(true);
                props.setIsMobile(true);
            } else {
                setIsMobile(false);
                props.setIsMobile(false);
            }

            if (window.innerWidth < 500 && heightModalBeetrack !== "330px") {
                setHeightModalBeetrack("330px");
            } else if (
                window.innerWidth > 500 &&
                heightModalBeetrack !== "310px"
            ) {
                setHeightModalBeetrack("310px");
            }
        }
        window.addEventListener("resize", updateSize);
        updateSize();
    }, [window.innerWidth]);

    useEffect(() => {
        handleResizeHeight();
    }, [headerHeight]);

    useEffect(() => {
        const user = getUserStorage();

        if (validateLengthObject(user)) {
            // setUserData(user);
            props.setUserInfo(user);
        }
        props.getItemsCar();
        categoriaApi();

        timeLoginUser();
    }, []);

    useEffect(() => {
        props.resetAllFilters();
        const { pathname, state } = props.location;
        if (pathname === "/carrito") {
            setHeaderTransparent(false);
            return;
        }

        if (!!state && state.hasOwnProperty("register")) {
            props.toggleCreditCard();
        }

        if (!headerTransparent) {
            setHeaderTransparent(true);
            return;
        }

        if (pathname === "/respuesta") {
            const { token } = props.userReducer.user;
            const data = {
                token
            };
            props.getPointsUser(data);
        }
    }, [props.location.pathname]);

    useEffect(() => {
        const { user } = props.userReducer;
        setUserPoints(user.puntos || 0);
    }, [props.userReducer.user.puntos]);

    useEffect(() => {
        const { user, codeVisa } = props.userReducer;
        if (user && !!codeVisa && codeVisa !== "0000000") {
            props.showMessageNoPointsInCard();
        }
    }, [props.userReducer.codeVisa]);

    // esta funcion sirve para verificar el tiempo del usuario cuando inicia sesion y desconectarlo
    // luego de un dia !!
    const timeLoginUser = () => {
        const user = getUserStorage();
        if (!getTimeStorage() && validateLengthObject(user)) {
            handleSingout();
        } else if (getTimeStorage() && validateLengthObject(user)) {
            const oldSession = getTimeStorage();
            const now = moment();
            if (now.diff(oldSession, "days") != 0) {
                handleSingout();
            }
        }
    };

    // llamada al api para traer las categorias !!
    // REFACTOR: parasar a redux
    const categoriaApi = async () => {
        const { payload } = await props.getCategories();
        setCategories(payload);
    };

    const handleResizeHeight = () => {
        const header = document.getElementsByTagName("header");
        let height = header[0].clientHeight;

        setHeaderHeight(height);
        props.setSizeHeader(height);
    };

    // navega
    const goRoute = route => {
        props.history.push(route);
    };

    const handleGoLogin = () => {
        goRoute("/login");
    };

    const handlePerfile = () => {
        goRoute("/perfil");
    };

    const handleGoRegister = () => {
        goRoute("/registro");
    };

    const handleSingout = () => {
        setUserPoints(0);
        props.singout();
        removeAuthStorage();
        removeUserStorage();
        goRoute("/");
    };

    const handleToggleModalLogin = () => {
        props.toggleLoginModal();
        // setShowModalLogin(!showModalLogin);
    };

    /// si se esta realizando la peticion al api de puntos, muestra un texto de espera !!
    const handleCheckLoaddingPoints = (tWhite = false) => {
        const isWhite = !!tWhite ? classes.textWhite : null;
        const { user, isLoadingPoints } = props.userReducer;

        if (isLoadingPoints) {
            return (
                <p
                    className={classNames(
                        classes.textLoad,
                        isWhite,
                        classes.parrafoDefault
                    )}
                >
                    Cargando puntos disponibles...
                </p>
            );
        }

        if (isLoadingPoints) {
            if (user.puntos == 0 && !!showMessage) {
                if (props.location.pathname !== "/respuesta") {
                    props.showMessageNoPointsInCard();
                }
                setShowMessage(false);
            }
        }

        if (!user.puntos && !isLoadingPoints) {
            return (
                <div
                    // style={{ cursor: "pointer" }}
                    className={classNames(classes.parrafoDefault, isWhite)}
                    // onClick={props.toggleCreditCard}
                >
                    Para canjear
                    <Info
                        className={classNames(
                            classes.parrafoDefault,
                            classes.colorPoints
                        )}
                    >
                        Puntos BBVA
                    </Info>{" "}
                    escríbenos al chat web
                </div>
            );
        }

        return (
            <div className={classNames(classes.parrafoDefault, isWhite)}>
                Tienes{" "}
                <Info
                    className={classNames(
                        classes.parrafoDefault,
                        classes.colorPoints
                    )}
                >
                    {" "}
                    {userPoints}
                    {" " + PUNTOS}
                </Info>{" "}
                disponibles.
            </div>
        );
    };

    const dropCategories = () => {
        if (!categories) {
            return [];
        }

        let allCategory = [];

        let listCategorires = categories.map((categori, index) => {
            if (categori.id == 17) {
                allCategory.push(
                    <Link
                        key={categori.slug + index}
                        to={{
                            pathname: `/tienda/${capitalize(
                                categori.slug,
                                "-"
                            )}`,
                            search: "?page=1"
                        }}
                        className={classNames(
                            isMobile
                                ? classes.dropdownLinkMoBile
                                : classes.dropdownLink,
                            isMobile ? classes.lineSeparator : "",
                            isMobile ? classes.colorTextCategories : ""
                        )}
                    >
                        <Icon
                            className={classNames(
                                classes.dropdownIcons,
                                classes.colorIconCategories
                            )}
                        >
                            {categori.icono}
                        </Icon>
                        {capitalizeFirst(categori.nombre)}
                    </Link>
                );
                return null;
            } else {
                return (
                    <Link
                        key={categori.slug + index}
                        to={{
                            pathname: `/tienda/${capitalize(
                                categori.slug,
                                "-"
                            )}`,
                            search: "?page=1"
                        }}
                        className={classNames(
                            isMobile
                                ? classes.dropdownLinkMoBile
                                : classes.dropdownLink,
                            isMobile ? classes.lineSeparator : "",
                            isMobile ? classes.colorTextCategories : ""
                        )}
                    >
                        <Icon
                            className={classNames(
                                classes.dropdownIcons,
                                classes.colorIconCategories
                            )}
                        >
                            {categori.icono}
                        </Icon>
                        {capitalize(categori.nombre)}
                    </Link>
                );
            }
        });

        return [...listCategorires, ...allCategory];
    };

    const strucMobile = () => (
        <>
            <List
                className={classNames(
                    classes.list,
                    classes.categoriList,
                    classes.noPdLeft
                )}
            >
                {loginOrUser()}

                <ListItem className={classNames(classes.listItem)}>
                    {dropCategories()}
                </ListItem>

                {validateLengthObject(props.userReducer.user) && (
                    <ListItem
                        className={classNames(
                            classes.listItem,
                            classes.lineSeparator
                            // classes.bgLightBlue
                        )}
                    >
                        <div
                            onClick={handleSingout}
                            style={{
                                // REFACTOR:  carmbiar esto a una clase !!
                                flexDirection: "row",
                                display: "flex",
                                alignItems: "center",
                                padding: "0.55rem 0.75rem 1rem 0.75rem",
                                cursor: "pointer"
                            }}
                        >
                            <PowerSettingsNew
                                className={classNames(
                                    classes.pt5,
                                    classes.imgSmall,
                                    classes.colorWhite
                                )}
                            />
                            <div className="center-box direction-colum align-start line-height-0">
                                <p
                                    className={classNames(
                                        classes.textUser,
                                        classes.noUpper
                                    )}
                                >
                                    <p
                                        className={classNames(
                                            classes.textUser,
                                            classes.pt0Mt0,
                                            classes.fz15,
                                            classes.colorWhite
                                        )}
                                    >
                                        Cerrar sesion
                                    </p>
                                </p>
                            </div>
                        </div>
                    </ListItem>
                )}
                <ListItem style={{ height: "6rem" }}></ListItem>
            </List>
        </>
    );

    const structDesktop = () => (
        <>
            <List className={classes.list}>
                <ListItem
                    className={classNames(
                        classes.listItem,
                        classes.pd1,
                        classes.pdl10,
                        classes.listItemMenu
                    )}
                >
                    <CustomDropdown
                        buttonText="Categorías"
                        componentIcon={
                            <i className="icon__menu margin-right--5"></i>
                        }
                        dropPlacement="top-start"
                        buttonProps={{
                            className: classNames(
                                classes.navLink,
                                classes.imageDropdownButton
                            ),

                            color: "transparent"
                        }}
                        dropdownList={dropCategories()}
                    />
                </ListItem>
            </List>

            <List className={classNames(classes.list, classes.mlAuto)}>
                {loginOrUser()}
                <ListItem
                    className={classNames(
                        classes.listItem,
                        classes.listItemMenu
                    )}
                >
                    <Button
                        justIcon
                        color="transparent"
                        onClick={() => {
                            setIShowSearch(!iShowSearch);
                            props.setShowSearch(!iShowSearch);
                            // inputSerachRef.current.focus();
                        }}
                    >
                        {/* <Search className={classes.searchIcon} /> */}
                        <i className="icon__search"></i>
                        {/* <SvgIcon desc="Search" fill="#fff" /> */}
                    </Button>
                </ListItem>
                <ListItem
                    className={classNames(
                        classes.listItem,
                        classes.listItemMenu
                    )}
                >
                    <Button
                        justIcon
                        color="transparent"
                        onClick={() => goRoute("../carrito")} // llama a la pagina del carrito !!
                    >
                        {/* <Icon>shopping_cart</Icon>  */}
                        <div className={classes.buttomBox}>
                            {/* <SvgIcon desc="icon-shopping" fill="#fff" /> */}
                            <i className="icon__shopping"></i>
                            <div className={classes.floatBadge}>
                                {props.carReducer.cant}
                            </div>
                        </div>
                    </Button>
                </ListItem>
            </List>
        </>
    );

    const navBar = () => {
        if (isMobile) {
            return strucMobile();
        }
        return structDesktop();
    };

    const handleShowButtonRegister = () => {
        if (props.location.pathname !== "/registro") {
            return (
                <Button
                    className={classes.buttonRegister}
                    simple
                    onMouseEnter={() => setIsHoverRegister(true)}
                    onMouseLeave={() => setIsHoverRegister(false)}
                    onClick={handleGoRegister}
                >
                    <i
                        className={`icon__create-account${
                            isHoverRegister ? "--white" : ""
                        }`}
                    ></i>
                    <span
                        className={classNames(
                            classes.textRegister,
                            isHoverRegister ? classes.textWhite : ""
                        )}
                    >
                        Crear una cuenta
                    </span>
                </Button>
            );
        }

        return null;
    };

    const loginOrUser = () => {
        if (validateLengthObject(props.userReducer.user)) {
            const noMarginTop = isMobile ? classes.pt0Mt0 : "";

            if (isMobile) {
                return (
                    <ListItem
                        className={classNames(
                            classes.listItem,
                            classes.bgLightBlue,
                            classes.lineSeparator
                        )}
                    >
                        <div
                            onClick={handlePerfile}
                            style={{
                                flexDirection: "row",
                                display: "flex",
                                alignItems: "center",
                                padding: "0.75rem 1.25rem 0.75rem 0.75rem",
                                cursor: "pointer"
                            }}
                        >
                            {/* <AccountCircle
                                className={classNames(
                                    classes.imgSmall,
                                    classes.colorWhite
                                )}
                            /> */}

                            <SvgIcon
                                desc="icono-login"
                                fill="#fff"
                                className={classes.imgSmall}
                            />

                            <div className="center-box direction-colum align-start menu-user-info">
                                <div
                                    className={classNames(
                                        classes.textUser,
                                        classes.noUpper
                                    )}
                                >
                                    <p
                                        className={classNames(
                                            classes.textUser,
                                            classes.colorWhite,
                                            classes.parrafoDefault,
                                            noMarginTop
                                        )}
                                    >
                                        {cutCharacters(
                                            capitalize(
                                                props.userReducer.user.nombres
                                            )
                                        )}
                                    </p>
                                </div>
                                <small
                                    className={classNames(
                                        classes.colorWhite,
                                        classes.ffBook
                                    )}
                                >
                                    Ver perfil
                                </small>
                            </div>
                        </div>
                    </ListItem>
                );
            }

            return (
                <>
                    <ListItem
                        className={classNames(
                            classes.listItem,
                            classes.listItemMenu
                        )}
                    >
                        <CustomDropdown
                            left
                            caret={false}
                            hoverColor="info"
                            dropPlacement="bottom-start"
                            classDrop={classes.mt21}
                            buttonText={
                                <div
                                    style={{
                                        flexDirection: "row",
                                        display: "flex",
                                        alignItems: "center"
                                    }}
                                >
                                    {/* <AccountCircle
                                        className={classNames(
                                            classes.imgSmall,
                                            classes.colorWhite
                                        )}
                                    /> */}
                                    <SvgIcon
                                        desc="icono-login"
                                        fill="#fff"
                                        className={classNames(
                                            classes.imgSmall,
                                            classes.colorWhite
                                        )}
                                    />
                                    {/* // para cuando se tengan imagenes de usuario se habilita esto */}
                                    {/* <img
										src={noAvatar}
										className={classes.imgSmall}
										alt="profile"
									/> */}
                                </div>
                            }
                            buttonProps={{
                                className: classNames(
                                    classes.navLink,
                                    classes.imageDropdownButton,
                                    classes.dropdownLink
                                ),
                                color: "transparent"
                            }}
                            dropdownList={[
                                <Button
                                    color="transparent"
                                    className={classNames(
                                        classes.dropdownLink,
                                        classes.noUpper,
                                        classes.ffBookButtom,
                                        classes.fz13Buttom
                                    )}
                                    simple
                                    size="sm"
                                    onClick={handlePerfile}
                                >
                                    <SvgIcon
                                        desc="icono-login"
                                        height="15px"
                                        fill="#5bbeff"
                                        className={classNames(
                                            classes.dropdownIcons
                                        )}
                                    />
                                    Mi Perfil
                                </Button>,
                                <Button
                                    color="transparent"
                                    className={classNames(
                                        classes.dropdownLink,
                                        classes.noUpper,
                                        classes.ffBookButtom,
                                        classes.fz13Buttom
                                    )}
                                    simple
                                    size="sm"
                                    onClick={handleSingout}
                                >
                                    <PowerSettingsNew
                                        className={classNames(
                                            classes.colorIconCategories,
                                            classes.fz15,
                                            classes.dropdownIcons
                                            // classes.colorIconCategories
                                        )}
                                    />
                                    Cerrar Sesión
                                </Button>
                            ]}
                        />
                    </ListItem>
                    <ListItem
                        className={classNames(
                            classes.listItem,
                            classes.listItemMenu
                        )}
                    >
                        <div className="center-box direction-colum align-start line-height-0">
                            <p
                                className={
                                    classes.textUser + " " + classes.noUpper
                                }
                            >
                                <span
                                    className={classNames(
                                        classes.textUser,
                                        classes.colorWhite,
                                        classes.parrafoDefault,
                                        noMarginTop
                                    )}
                                >
                                    {cutCharacters(
                                        capitalize(
                                            props.userReducer.user.nombres
                                        )
                                    )}
                                </span>
                            </p>
                            {!isMobile && (
                                <p
                                    className={
                                        classes.textUser + " " + classes.noUpper
                                    }
                                >
                                    {handleCheckLoaddingPoints(true)}
                                </p>
                            )}
                        </div>
                    </ListItem>
                </>
            );
        }

        return isMobile ? (
            <>
                <div className={classes.lineSeparator}>
                    <div
                        className={classNames(
                            classes.dropdownLinkMoBile,
                            // classes.lineSeparator,
                            classes.colorWhite
                        )}
                        onClick={handleToggleModalLogin}
                    >
                        <SvgIcon
                            desc="icono-login"
                            fill="#fff"
                            width="20px"
                            height="20px"
                            className={classNames(
                                classes.mr15,
                                classes.colorWhite
                            )}
                        />
                        <span className={classes.ffBook}>Iniciar Sesión</span>
                    </div>
                </div>
                <div className={classes.lineSeparator}>
                    <div
                        className={classNames(
                            classes.dropdownLinkMoBile,
                            classes.lineSeparator,
                            classes.colorWhite
                        )}
                        onClick={handleGoRegister}
                    >
                        <i
                            className={classNames(
                                "icon__create-account",
                                classes.mr15
                            )}
                        ></i>
                        <span
                            className={classNames(
                                classes.ffBook,
                                classes.textYellow
                            )}
                        >
                            Crear una cuenta
                        </span>
                    </div>
                </div>
            </>
        ) : (
            <ListItem
                className={classNames(classes.listItem, classes.listItemMenu)}
            >
                {handleShowButtonRegister()}
                <Button
                    color="bbva"
                    className={classNames(
                        classes.navLink,
                        classes.noBorder,
                        classes.colorWhite,
                        classes.noUpper
                    )}
                    onClick={handleToggleModalLogin}
                >
                    Iniciar Sesión
                </Button>
            </ListItem>
        );
    };

    const handleContectMessageNoPoints = () => {
        let message = "";
        const { codeVisa } = props.userReducer;

        switch ("MPE2068") {
            case 400:
                message = MESSAGE_ERROR_CONNECTION_POINTS;
                break;
            case "MPE2068":
                message = MESSAGE_BLOCK_CARD;
                break;
            case "MPE0113":
                message = MESSAGE_CARD_NO_VALID;
                break;
            case "MPE0007": // no tiene tarjeta registrada en la bd
                message = MESSAGE_CARD_NO_EXIST;
                break;
            case "MPE2068":
                message = MESSAGE_NO_ACCUMULATE_POINTS;
                break;
            case "MC000013":
                message = MESSAGE_NO_POINTS_IN_CARD;
                break;
            default:
                message = MESSAGE_ERROR_CONNECTION_POINTS;
                break;
        }

        return <div style={{ padding: "10px" }}>{message}</div>;
    };

    const handleShowModalTerminosNoContent = () => {
        const { codeVisa } = props.userReducer;
        const codesErros = [
            "MPE2068",
            "MPE0113",
            "MPE0007",
            "MPE2068",
            "MC000013"
        ];

        return !!codesErros.find(error => error === codeVisa);
    };

    const handleClickModalMessage = () => {
        props.closeMessageNoPointsInCard();
        props.toggleCreditCard(); // se elimino la opcion para agregar tarjeta!!
    };

    const handleAnimateSearh = () => {
        return iShowSearch ? "animate__fadeInDown" : "";
    };

    const handleToggleModalBeetrack = () => {
        props.togglebeetrackTrack();
    };

    const { ModalsReducer } = props;
    // ----------------------- return !! ---------------------------//
    return (
        <>
            <ModalLoader showModal={ModalsReducer.globalLoader.show} />
            <ModalAddCar />
            <ModalBeetrack
                show={ModalsReducer.beetrackModal.show}
                onClose={handleToggleModalBeetrack}
                height={heightModalBeetrack}
            />
            {/* <ModalCreditCard /> */}
            {/* //REFACTOR: */}
            <ModalTerminos
                showModal={ModalsReducer.messageNoPointsModal.show}
                closeModal={props.closeMessageNoPointsInCard}
                noButtonClose={handleShowModalTerminosNoContent()}
                classContent={classesImg.padding0}
                content={
                    handleShowModalTerminosNoContent()
                        ? null
                        : handleContectMessageNoPoints()
                }
            >
                {handleShowModalTerminosNoContent() &&
                    handleContectMessageNoPoints()}
            </ModalTerminos>
            <ModalTerminos
                showModal={ModalsReducer.loginModal.show}
                closeModal={handleToggleModalLogin}
                noButtonClose
            >
                <FormLogin
                    successLogin={handleToggleModalLogin}
                    closeModal={handleToggleModalLogin}
                />
            </ModalTerminos>
            <Header
                isMobile={isMobile}
                sizeWindow={props.headerReducer.sizeWindow}
                fullFluid
                brand={
                    <img
                        src={logo1}
                        alt="Puntos bbva"
                        className={`
							${classesImg.imgRounded} 

							${classesImg.imgFluid} 

							${classesImg.sizeLogo}`}
                    />
                }
                brand2={
                    <img
                        src={logo1}
                        alt="Puntos bbva"
                        className={`
							${classesImg.imgRounded} 

							${classesImg.imgFluid} 

							${classesImg.sizeLogo}`}
                    />
                }
                links={navBar()}
                fixed
                color={"navLink"}
                changeColorOnScroll={{
                    height: 200,
                    color: "navLink"
                }}
                showCarInMobile={
                    <Button
                        color="transparent"
                        className={classNames(classes.ml0, classes.pr0)}
                        onClick={() => goRoute("../carrito")} // llama a la pagina del carrito !!
                    >
                        <div className={classes.buttomBox}>
                            <i className="icon__shopping"></i>

                            <div className={classes.floatBadge}>
                                {props.carReducer.cant}
                            </div>
                        </div>
                    </Button>
                }
                searchButtom={
                    <Button
                        color="transparent"
                        className={classes.pr0}
                        onClick={() => {
                            setIShowSearch(!iShowSearch);
                            props.setShowSearch(!iShowSearch);
                            // inputSerachRef.current.focus();
                        }}
                    >
                        {/* <Search className={classes.searchIcon} /> */}
                        <SvgIcon desc="Search" fill="#fff" />
                    </Button>
                }
            />

            {iShowSearch && (
                <div
                    style={{
                        marginTop: `${headerHeight}px`
                        // paddingBottom: "18px",
                        // marginBottom: !isMobile ? "-3em" : "0",
                    }}
                >
                    <div
                        className={classNames(
                            classes.bgSearch,
                            classes.inputSearchBox,
                            "animate__animated",
                            "animate__faster",
                            handleAnimateSearh()
                        )}
                    >
                        <CustomInput
                            white
                            underlineNone
                            inputRootCustomClasses={classNames(
                                classes.inputRootCustomClasses,
                                classes.container
                            )}
                            formControlProps={{
                                className: classNames(
                                    classes.formControl,
                                    classes.flexCenter
                                )
                            }}
                            inputProps={{
                                placeholder: "Buscar aquí",
                                onChange: e => setTerminoSearch(e.target.value),
                                value: terminoSearch,
                                startAdornment: (
                                    <SvgIcon desc="Search" fill="#fff" />
                                ),
                                endAdornment: (
                                    <Button
                                        justIcon
                                        color="transparent"
                                        onClick={() => {
                                            setIShowSearch(!iShowSearch);
                                            props.setShowSearch(!iShowSearch);
                                            setTerminoSearch("");
                                        }}
                                    >
                                        <SvgIcon
                                            desc="icon-close"
                                            fill="#fff"
                                        />
                                    </Button>
                                ),
                                inputProps: {
                                    ref: inputSearch,
                                    "aria-label": "Busqueda",
                                    className: classNames(
                                        classes.searchInput,
                                        classes.placeholderGray
                                    )
                                },
                                onKeyPress: e => {
                                    if (e.key === "Enter") {
                                        goRoute(`../busqueda/${terminoSearch}`);
                                        setIShowSearch(!iShowSearch);
                                        props.setShowSearch(!iShowSearch);
                                        setTerminoSearch("");
                                    }
                                }
                            }}
                        />
                    </div>
                </div>
            )}
            {isMobile && validateLengthObject(props.userReducer.user) && (
                <BottomBarInfo iShowSearch={iShowSearch}>
                    <div style={{ padding: "5px 0", textAlign: "center" }}>
                        {handleCheckLoaddingPoints()}
                    </div>
                </BottomBarInfo>
            )}
        </>
    );
};
const mapStateToProps = ({
    categoriesReducer,
    userReducer,
    carReducer,
    ModalsReducer,
    headerReducer,
    directionsReducer
}) => {
    return {
        userReducer,
        categoriesReducer,
        carReducer,
        ModalsReducer,
        headerReducer,
        directionsReducer
    };
};

const mapDispatchToProps = {
    ...userActions,
    ...categoriesActions,
    ...productsActions,
    ...carActions,
    ...filterProductsActions,
    ...modalActions,
    ...headerActions
};

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(HeaderCustom)
);
