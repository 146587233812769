import { GET_CATEGORIES } from "redux/types/categoriesTypes";
import { allCategories } from "../../services/home/categoriesServices";

// TRABAJANDO: llamar al api !!
export const getCategories = state => async dispatch => {
    const categories = await allCategories();

    return dispatch({
        type: GET_CATEGORIES,
        payload: categories.data.response
    });
};
