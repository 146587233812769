import React from 'react';
import { makeStyles } from "@material-ui/core/styles";
import cardDiscountStyle from "../../assets/jss/material-kit-pro-react/components/cardDiscountStyle"
import PropTypes from 'prop-types';

const useStyles = makeStyles(cardDiscountStyle);

const Discount = (props) => {
    const classes = useStyles();

    let discount = classes.discount;
    let discountText = classes.discountText;
    if (props.inverted) {
        discount = classes.discountInverted;
        discountText = classes.discountTextInverted;
    }

    const handleDiscount = () => {
        const calculo = props.puntos - props.offer;

        return `-${Math.round((calculo * 100) / props.puntos)}%`;
    }


    return !props.show ? null : (
        <>
            {/* // triangulo de descuento!! */}
            <div className={discount} >
            </div>

            {/* Texto del descuento, Ej: 33 */}
            <span className={discountText}>
                {handleDiscount()}
            </span>
        </>
    )
}

Discount.defaultProps = {
    show: true,
    inverted: false
}

Discount.prototype = {
    inverted: PropTypes.bool,
    puntos: PropTypes.number.isRequired,
    offer: PropTypes.number.isRequired,
    show: PropTypes.bool,
}

export default Discount
