import {
    GET_INFO,
    SET_INFO,
    REMOVE_INFO,
    GET_POINTS_USERS,
    ERROR_USER,
    LOADING_POINTS,
    GET_USER_DATA,
    UPDATE_PROFILE,
    UPDATE_POINTS_USER,
    SET_CODE_VISA_POINT
} from "../../types/userTypes";
import { getUserStorage } from "../../../services/localStorage/userStorage";

const INITIAL_STATE = {
    user: getUserStorage() || {},
    isLoading: false,
    isLoadingPoints: false,
    codeVisa: "",
    error: ""
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case GET_INFO:
            return { ...state, user: action.payload, error: "" };

        case GET_USER_DATA:
            return newDataUSer(state, action.payload);

        case SET_INFO:
            return { ...state, user: action.payload, error: "" };

        case REMOVE_INFO:
            return { ...state, user: {}, error: "" };

        case UPDATE_PROFILE:
            return update(state, action);

        case GET_POINTS_USERS:
            return setPoints(state, action.payload);

        case UPDATE_POINTS_USER:
            return setUpdatePoints(state, action.payload);

        case LOADING_POINTS:
            return { ...state, isLoadingPoints: action.payload };

        case SET_CODE_VISA_POINT:
            return { ...state, codeVisa: action.payload };

        case ERROR_USER:
            return { ...state, error: action.payload, isLoadingPoints: false };
        default:
            return state;
    }
};

const update = (state, action) => {
    const newState = { ...state };

    return {
        ...newState,
        user: { ...newState.user, ...action.payload },
        error: ""
    };
};

const setUpdatePoints = (state, points) => {
    const newState = { ...state };
    newState.isLoadingPoints = false;
    newState.error = "";
    newState.user.puntos = points || 0;

    return newState;
};

const setPoints = (state, points) => {
    const newState = { ...state };

    let puntos = points || 0;

    newState.isLoadingPoints = false;
    newState.error = "";
    // if (!Object.keys(points).length) return newState;
    newState.user.puntos = puntos;

    return newState;
};

const newDataUSer = (state, user) => {
    let newState = { ...state };
    const userPoints = newState.user.puntos;
    newState.user = user;
    newState.error = "";
    newState.user.puntos = userPoints;

    return newState;
};
