import {
	GET_PRODUCTS_RECOMMENDED,
	ERROR,
	IS_LOADING
} from "../types/productsRecommendedTypes";
import { getProductRecommended } from "services/home/productsServices";

export const getProductsRecommended = () => async dispatch => {
	dispatch({
		type: IS_LOADING
	});

	try {
		const { response } = await getProductRecommended();

		return dispatch({
			type: GET_PRODUCTS_RECOMMENDED,
			payload: response
		});
	} catch (error) {
		return dispatch({
			type: ERROR,
			payload: error
		});
	}
};
