import { START_BUTTON_CASH_VISA } from "redux/types/visaCashTypes";
import { SET_DATA_SESSION } from "redux/types/visaCashTypes";
import { GET_DATA_SESSION } from "redux/types/visaCashTypes";
import { REMOVE_DATA } from "redux/types/visaCashTypes";
import { ERROR_SESSION_VISA, IS_LOADING } from "redux/types/visaCashTypes";

const INITIAL_STATE = {
    isLoader: false,
    data: null,
    error: null
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case START_BUTTON_CASH_VISA:
            return { ...state, data: action.payload, isLoader: false };
        case IS_LOADING:
            return { ...state, isLoader: true }
        case SET_DATA_SESSION:
            break;
        case GET_DATA_SESSION:
            break;
        case REMOVE_DATA:
            break;
        case ERROR_SESSION_VISA:
            return { ...state, error: action.payload, isLoader: false };
        default:
            return state;
    }
};
