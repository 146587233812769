import React, { useState, useEffect } from "react";

const BREAKPOINT_MOBIL = 768;
const BREAKPOINT_LAPTOP = 1400;
// este hook permite saber el tamaño de la pantalla en todo momento!!
const useResize = () => {
    const [isMobil, setIsMobil] = useState(false);
    const [isLaptop, setIsLaptop] = useState(false);
    const [width, setWidth] = useState(0);

    useEffect(() => {
        window.addEventListener("resize", handleUpdate);

        handleUpdate();

        return () => window.removeEventListener("resize", handleUpdate);
    }, [window.innerWidth]);

    const handleUpdate = () => {
        let currentWidth = window.innerWidth;

        setIsMobil(currentWidth <= BREAKPOINT_MOBIL);

        if (
            currentWidth > BREAKPOINT_MOBIL &&
            currentWidth <= BREAKPOINT_LAPTOP
        ) {
            setIsLaptop(true);
        } else {
            setIsLaptop(false);
        }

        setWidth(currentWidth);
    };

    return [width, isMobil, isLaptop];
};

export default useResize;
