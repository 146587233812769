import {
    container,
    title,
    cardTitle,
    main,
    mainRaised,
    mrAuto,
    whiteColor,
    grayColor,
    mlAuto,
    ffBook,
    colorMute,
    colorTextBlack,
    colorWhite,
    bbvaColor,
    colorTextGray,
    ffMedium
} from "assets/jss/material-kit-pro-react.js";

import buttonGroup from "assets/jss/material-kit-pro-react/buttonGroupStyle.js";
import tooltips from "assets/jss/material-kit-pro-react/tooltipsStyle.js";

const styles = {
    main,
    mainRaised,
    mrAuto,
    mlAuto,
    cardTitle,
    ffBook,
    ffMedium,
    ...buttonGroup,
    ...tooltips,
    cardTitleMobile: {
        ...cardTitle,
        paddingLeft: "15px"
    },
    section: {
        backgroundColor: colorWhite,
        padding: "70px 0px"
    },
    container: {
        ...container,
        zIndex: 1
    },
    title: {
        ...title,
        "&, & + h4": {
            color: whiteColor
        }
    },
    block: {
        color: "inherit",
        padding: "0.9375rem",
        fontWeight: "500",
        fontSize: "12px",
        // textTransform: "uppercase",
        borderRadius: "0",
        textDecoration: "none",
        position: "relative",
        display: "block"
    },
    inlineBlock: {
        display: "inline-block",
        padding: "0px",
        width: "auto"
    },
    list: {
        marginBottom: "0",
        padding: "0",
        marginTop: "0"
    },
    left: {
        float: "left !important",
        display: "block"
    },
    right: {
        padding: "15px 0",
        margin: "0",
        float: "right"
    },
    icon: {
        width: "18px",
        height: "18px",
        top: "3px",
        position: "relative"
    },
    imgContainer: {
        width: "120px",
        maxHeight: "160px",
        overflow: "hidden",
        display: "block",
        "& img": {
            width: "100%"
        }
    },
    imgContainerMobile: {
        width: "120px",
        maxHeight: "160px",
        overflow: "hidden",
        display: "block",
        "& img": {
            width: "90%"
        }
    },
    containerFlexCenter: {
        display: "flex",
        alignItems: "center"
    },
    description: {
        maxWidth: "150px"
    },
    tdName: {
        minWidth: "200px",
        fontWeight: "400",
        fontSize: "1.4em",
        lineHeight: "1.1em"
    },
    tdNameAnchor: {
        color: colorTextBlack,
        fontSize: "14px",
        textTransform: "uppercase"
    },
    tdNameSmall: {
        color: colorMute,
        fontSize: "13px",
        fontWeight: "300"
    },
    tdPointsSmnall: {
        color: colorTextBlack,
        fontSize: "13px",
        fontWeight: "300"
    },
    tdNumber: {
        textAlign: "right",
        minWidth: "150px",
        fontWeight: "300",
        fontSize: "1.125em !important"
    },
    tdNumberSmall: {
        marginRight: "3px"
    },
    tdNumberAndButtonGroup: {
        lineHeight: "1 !important",
        "& svg,& .fab,& .fas,& .far,& .fal,& .material-icons": {
            marginRight: "0"
        }
    },
    lineHeightPuntos: {
        lineHeight: "1.1em"
    },
    customFont: {
        fontSize: "16px !important"
    },
    actionButton: {
        margin: "0px",
        padding: "5px"
    },
    textCenter: {
        textAlign: "center"
    },
    pdLefRig05: {
        padding: "0 .5em"
    },
    textRight: {
        textAlign: "right"
    },
    cardPrice: {
        padding: "25px",
        display: "flex",
        alignItems: "flex-start",
        justifyContent: "space-between"
    },
    flexStart: {
        
        justifyContent: "flex-start"
    },
    ml16: {
        marginLeft: "16px"
    },
    mt4: {
        marginTop: "4em"
    },
    mt0: {
        marginTop: "0"
    },
    pt0: {
        paddingTop: "0"
    },
    mb0: {
        marginBottom: "0"
    },
    pt2: {
        paddingTop: "2em"
    },
    pl2: {
        paddingLeft: "2em"
    },
    pr2: {
        paddingRight: "2em"
    },
    styleTitle: {
        fontWeight: "600",
        color: "#3C4858"
    },
    m0: {
        margin: "0"
    },
    textMuted: {
        color: colorMute
    },
    wrap: {
        flexWrap: "wrap"
    },
    fixedBottom: {
        marginBottom: "0",
        position: "fixed",
        bottom: "0",
        zIndex: "4",
        paddingBottom: "30px"
    },
    badge: {
        backgroundColor: bbvaColor[0],
        borderRadius: "50%",
        padding: "1px 7px",
        color: colorWhite
    },
    alignCenter: {
        alignItems: "center"
    },
    paddingFixed: {
        padding: ".4em 1.5em"
    },
    bgWhite: {
        backgroundColor: "#fff"
    },
    cellDelete: {
        minWidth: "5px"
    },
    pdVerticalCard: {
        padding: "10px 0"
    },
    mtSearch: {
        marginTop: "130px"
    },
    paddingVerticalSearch: {
        padding: "10px 0"
    },
    noPaddingHorizontal: {
        paddingRight: "0",
        paddingLeft: "0"
    },
    flexNoPaddingLeft: {
        display: "flex",
        paddingLeft: "0"
    },
    priceOffert: {
        textDecoration: "line-through"
    },
    fz11: {
        fontSize: "11px"
    },
    fz12: {
        fontSize: "12px"
    },
    fz14: {
        fontSize: "14px"
    },
    fz16: {
        fontSize: "16px"
    },
    pointer: {
        cursor: "pointer"
    },
    paddingBottomMobile: {
        paddingBottom: "160px"
    },
    textWhite: {
        color: whiteColor
    },
    lightWhite: {
        color: colorWhite
    },
    separator: {
        marginTop: "18px",
        borderTop: "1px solid " + colorTextGray,
        paddingTop: "15px",
        marginLeft: "0",
        marginRight: "0"
    },
    oPorSmall: {
        margin: "0",
        marginTop: "-6px",
        fontSize: "12px"
    },
    flexCenter: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
    spaceBtw: {
        justifyContent: "space-between"
    },
    directionColumn: {
        flexDirection: "column"
    },
    centerEnvio: {
        display: "flex",
        alignItems: "center",
        fontSize: "12px"
    },
    containerFotterModal: {
        display: "flex",
        justifyContent: "space-evenly"
    }
};

export default styles;
