import {
    SET_SIZE_HEADER,
    IS_SHOW_SEARCH,
    IS_MOBILE
} from "redux/types/headerTypes";
import { SET_SIZE_INNERWIDTH } from "redux/types/headerTypes";

export const setSizeHeader = size => dispatch => {
    return dispatch({
        type: SET_SIZE_HEADER,
        payload: size
    });
};

export const setShowSearch = value => dispatch => {
    return dispatch({
        type: IS_SHOW_SEARCH,
        payload: value
    });
};

export const setIsMobile = value => dispatch => {
    return dispatch({
        type: IS_MOBILE,
        payload: value
    });
};

export const setSizeWindow = value => dispatch => {
    return dispatch({
        type: SET_SIZE_INNERWIDTH,
        payload: value
    });
};
