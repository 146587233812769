import React from "react";

// nodejs library that concatenates classes
import classNames from "classnames";

import { makeStyles } from "@material-ui/core/styles";

import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import ErrorOutline from "@material-ui/icons/ErrorOutline";

// Utils !!
import { numberFormat } from "utils/utils";

import checkoutStyle from "assets/jss/material-kit-pro-react/views/checkoutPage.js";

const useStylesCheckout = makeStyles(checkoutStyle);

// component !!
const NotSent = ({ items }) => {

    const classesCheck = useStylesCheckout();
    return !items.length ? null : (
        <Card color="white">
            <CardHeader
                color="danger"
                className={classNames(
                    classesCheck.noMarginHorizon,
                    classesCheck.cardHeader
                )}
            >
                <ErrorOutline />
                <p className={classesCheck.textHeader}>
                    Este producto no puede ser enviado con a esta dirección.
                </p>
            </CardHeader>

            <CardBody>
                {items.map(item => (
                    <div
                        className={classNames(
                            classesCheck.containerCard,
                            classesCheck.row,
                            classesCheck.borderBt
                        )}
                    >
                        <div>
                            <p className={classesCheck.m0}>
                                {" "}
                                <small> {item.titulo}</small>
                            </p>
                            <p className={classesCheck.m0}>
                                {" "}
                                <small> {item.marca}</small>
                            </p>
                            <p className={classesCheck.m0}>
                                <small>Cantidad: {item.cantidad}</small>
                            </p>
                        </div>
                        <div className={classesCheck.sizePoints}>
                            {numberFormat(item.puntos * item.cantidad)} Puntos
                        </div>
                    </div>
                ))}
            </CardBody>
        </Card>
    );
};

export default NotSent;
