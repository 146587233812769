import React from 'react';
import PropTypes from 'prop-types';
import { connect } from "react-redux";

// muesta el contenido solo cuando es tamaño deskto !!
const WrapperDeskto = (props) => {
    return props.sizeScreen.isMobile ? null : (
        <>
            {props.children}
        </>
    )
}

WrapperDeskto.propTypes = {

}

const mapStateToProps = ({ headerReducer }) => ({ sizeScreen: headerReducer });

export default connect(mapStateToProps, null)(WrapperDeskto);
