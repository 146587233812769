import {
	getCarStorage,
	setCarStorage,
	removeCarStorage
} from "../../services/localStorage/carStorage";
import {
	ADD_CAR,
	GET_CAR,
	REMOVE_CAR_BY_ID,
	REMOVE_ALL_CAR,
	SUBTRACT_ITEM_CAR,
	SET_PRODUTS
} from "../types/carTypes";
import {MAX_AMOUNT_ALLOWED} from "../../config/const/constText";

// Constantes !!
const ADD_ITEM = "add_item"; // es la bandera para agregar mas cantidad a un item !!
const SUBTRACT = "subtract"; // es la bandera para restar mas cantidad a un item !!

// agrega al reducer los items del carrito !!
export const getItemsCar = () => dispatch => {
	const currentCar = getCarStorage();

	return dispatch({
		type: GET_CAR,
		payload: { items: currentCar || [], cant: currentCar.length || 0 }
	});
};

export const setProductsCheckout = (items) => dispatch => {
	setCarStorage(items);
	dispatch({
		type: SET_PRODUTS,
		payload: { items, cant: items.length }
	});
}

// agrega uno o mas items al carrito
// REFACTOR: validar si se envia un array de items a agregar !!
export const addToCar = items => dispatch => {
	const currentCar = getCarStorage();
	
	const newCar = addOrSubtractItemIsExist(currentCar, items, ADD_ITEM);
	
	setCarStorage(newCar);

	return dispatch({
		type: ADD_CAR,
		payload: {
			items: newCar,
			cant: newCar.length || 0
		}
	});
};

/**
 * elimina un producto del carrito por su id !!
 * @param {string} id El id del producto a eliminar
 */
export const removeItemById = id => dispatch => {
	// TRABAJANDO: buscar si existe mas de 1 item igual, y eliminar uno y calcular el precio
	let currentCar = getCarStorage();
	const newCar = currentCar.filter(item => item.id !== id);
	setCarStorage(newCar);

	dispatch({
		type: REMOVE_CAR_BY_ID,
		payload: { items: newCar, cant: newCar.length }
	});
};

/**
 * Funcion que sustrae en [1] la cantidad de un producto seleccionado !!
 * @param {Object} item El obejto del carrito a sustraerle una cantidad
 */
export const subtractQuantityItem = item => dispatch => {
	const currentCar = getCarStorage();

	const newCar = addOrSubtractItemIsExist(currentCar, item, SUBTRACT);
	setCarStorage(newCar);

	dispatch({
		type: SUBTRACT_ITEM_CAR,
		payload: { items: newCar, cant: newCar.length }
	});
};

/**
 * Borrar todo el carrito, tanto del reducer como del storage !!
 */
export const deleteAllCar = () => dispatch => {
	removeCarStorage();

	dispatch({
		type: REMOVE_ALL_CAR
	});
};

////////////////////////////!! Acciones !!//////////////////////////////////////////
/**
 * Agrega o sustrae pruductos al storage del carrito de compras !!
 * @param {Object} currenItem Los Porductos guardados en el storage
 * @param {Object} newItem Los datos del producto a tratar
 * @param {string} action El tipo de accion que realizara la funcion
 */
const addOrSubtractItemIsExist = (currenItem, newItem, action = ADD_ITEM) => {
	let currentCar = currenItem;
	if (currenItem.findIndex(item => item.id === newItem.id) !== -1) {
		return currenItem.map(item => {
			if (item.id === newItem.id) {
				if (action === ADD_ITEM) {
					addItem(item, newItem)
				} else {
					subtractItem(item);
				}
			}
			return item;
		});
	}

	currentCar.push(newItem);

	return currentCar;
};

const getQuantityItem = (item, newItem) => {
	const cantidad = item.cantidad + newItem.cantidad;
	if ( cantidad >= MAX_AMOUNT_ALLOWED ) {
		return MAX_AMOUNT_ALLOWED;
	}

	return cantidad;
}

const addItem = (item, newItem) => {
	item.cantidad = getQuantityItem(item, newItem);

	item.precioFinal = !!item.puntosOferta
		? parseInt(newItem.puntosOferta) * parseInt(item.cantidad)
		: parseInt(newItem.puntos) * parseInt(item.cantidad);
}

// validacion de resta de cantidad, no se regresa nada, por que el valor
// se esta pasando por [referencia]!!
const subtractItem = item => {
	if (item.cantidad <= 1) return item;

	item.cantidad -= 1;
	item.precioFinal = !!item.puntosOferta
		? parseInt(item.puntosOferta) * parseInt(item.cantidad)
		: parseInt(item.puntos) * parseInt(item.cantidad);
};
