import React from "react";
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-kit-pro-react/views/ecommerceStyle";
import { Link } from "react-router-dom";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import { baseRouteImages } from "config/config.json";

const useStyles = makeStyles(styles);

const SectionBannerPromo = ({ banners }) => {
    const classes = useStyles();

    const showBanner = () => {
        return banners.map((banner, i) => (
            <GridItem
                key={`${banner.link + i}`}
                xs={banner.xs || 12}
                sm={banner.sm || 6}
                md={banner.md || 6}
                className={classNames(
                    classes.heightContainer,
                    classes[banner.className]
                )}
            >
                <Link to={banner.link}>
                    <img
                        src={baseRouteImages + `/home/${banner.image}`}
                        style={{
                            width: "100%",
                            height: "100%",
                            ...banner
                        }}
                    />
                </Link>
            </GridItem>
        ));
    };

    if (!banners.length) return null;

    return (
        <GridContainer className={classes.container}>
            {showBanner()}
        </GridContainer>
    );
};

export default SectionBannerPromo;
