import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";

import classNames from "classnames";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import styles from "assets/jss/material-kit-pro-react/components/typographyStyle.js";

const useStyles = makeStyles(styles);

export default function Danger(props) {
    const { children, onClick } = props;
    const classes = useStyles();
    return (
        <div
            className={classNames(
                classes.defaultFontStyle,
                classes.dangerText,
                props.className
            )}
            onClick={onClick}
        >
            {children}
        </div>
    );
}

Danger.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string
};
