export const svgMap = [
    {
        width: "22px",
        height: "22px",
        viewBox: "0 0 22 22",
        desc: "Search",
        id: "icon-search",
        d:
            "M18 9C18 10.9749 17.3634 12.8009 16.2849 14.2849L22 20L20 22L14.2849 16.2849C12.8008 17.3634 10.9749 18 9 18C4.0294 18 0 13.9706 0 9C0 4.0294 4.0294 0 9 0C13.9706 0 18 4.0294 18 9ZM9 5C11.2091 5 13 6.7909 13 9L15 9C15 5.6863 12.3137 3 9 3L9 5Z",
        fill: "#1973B8",
        "fill-rule": "evenodd",
        stroke: "none"
    },
    {
        width: "22px",
        height: "15px",
        viewBox: "0 0 22 16",
        desc: "icon-menu",
        id: "icon-menu",
        d:
            "M0 3L0 0L22 0L19 3L0 3ZM0 6.5L0 9.5L16 9.5L19 6.5L0 6.5ZM0 16L0 13L16 13L13 16L0 16Z",
        fill: "#fff",
        "fill-rule": "evenodd",
        stroke: "none"
    },
    {
        width: "19px",
        height: "22px",
        viewBox: "0 0 19 22",
        desc: "icon-shopping",
        id: "icon-shopping",
        d:
            "M14.6667 5.49413L14.6667 5.5L18.3333 5.5L18.3333 21.0833C18.3333 21.5896 17.916 22 17.4242 22L0.90915 22C0.407 22 0 21.5832 0 21.0833L0 5.5L3.66667 5.5L3.66667 5.49413C3.66667 2.45978 6.13534 0 9.16667 0C12.2042 0 14.6667 2.46922 14.6667 5.49413ZM9.16648 1.83331C7.14762 1.83331 5.50321 3.47433 5.5 5.49998L12.833 5.49998C12.8298 3.47451 11.1895 1.83331 9.16648 1.83331ZM5.49984 11.9167L3.6665 13.75L3.6665 9.16669L5.49984 9.16669L5.49984 11.9167ZM14.6668 11.9167L12.8335 13.75L12.8335 9.16669L14.6668 9.16669L14.6668 11.9167Z",
        fill: "#1973B8",
        "fill-rule": "evenodd",
        stroke: "none"
    },
    {
        width: "16px",
        height: "16px",
        viewBox: "0 0 16 16",
        desc: "icon-close",
        d:
            "M14.546 -3.32185e-15L7.93455 6.61057L1.45489 0.131837L0.132772 1.45395L6.61244 7.93361L0 14.5451L1.45489 16L8.06639 9.38756L14.6779 16L16 14.6779L9.38943 8.06545L16 1.45395L14.546 -3.32185e-15Z",
        fill: "#1973B8",
        "fill-rule": "none",
        stroke: "none"
    },
    {
        width: "20px",
        height: "22px",
        viewBox: "0 0 20 22",
        desc: "icono-login",
        d:
            "M15 5C15 7.7614 12.7614 10 10 10C7.2386 10 5 7.7614 5 5C5 2.2386 7.2386 0 10 0C12.7614 0 15 2.2386 15 5ZM10 12C11.1285 12 12.1937 11.7314 13.1377 11.257C17.0765 12.2179 20 15.7667 20 20L20 22L0 22L0 20C0 15.7668 2.9183 12.2169 6.8616 11.2566C7.8058 11.7312 8.8712 12 10 12ZM13 19L10 19L10 17L15 17L13 19Z",
        fill: "#1973B8",
        "fill-rule": "evenodd",
        stroke: "none"
    },
    {
        width: "22px",
        height: "17px",
        viewBox: "0 0 22 17",
        desc: "icono-delivery",
        d:
            "M21.8167 7.46964L19.019 3.24142C18.8166 2.93654 18.4753 2.75321 18.1097 2.75321L13.75 2.75321L13.75 1.02777C13.7373 0.46255 13.2806 0.00861667 12.716 0L0 0L0 2.75321L9.16667 2.75321L7.33333 4.58874L0 4.58874L0 6.42437L5.5 6.42437L3.66667 8.25981L0 8.25981L0 14.6842L1.83333 14.6842C1.83333 12.6568 3.4749 11.0131 5.5 11.0131C7.5251 11.0131 9.16667 12.6568 9.16667 14.6842L12.8333 14.6842C12.8333 12.6568 14.4749 11.0131 16.5 11.0131C18.5251 11.0131 20.1667 12.6568 20.1667 14.6842L20.9082 14.6842C21.5122 14.6832 22.001 14.1922 22 13.5874L22 8.07629C21.9995 7.86042 21.9358 7.64949 21.8167 7.46964ZM14.6665 4.58862L14.6665 8.25969L20.1482 8.25969L17.7135 4.58862L14.6665 4.58862ZM7.01993 13.658C6.45637 12.8262 5.32602 12.6094 4.49516 13.1736C3.66447 13.7378 3.44777 14.8696 4.01143 15.7013C4.575 16.5331 5.70534 16.75 6.53612 16.1858C7.25432 15.698 7.52786 14.7701 7.18952 13.97C7.14332 13.8608 7.08657 13.7562 7.01993 13.658ZM15.4952 13.1736C16.326 12.6094 17.4564 12.8262 18.0199 13.658C18.0864 13.7563 18.1432 13.8608 18.1895 13.97C18.5279 14.7701 18.2543 15.698 17.5361 16.1858C16.7053 16.75 15.575 16.5331 15.0114 15.7013C14.4478 14.8696 14.6645 13.7378 15.4952 13.1736Z",
        fill: "#1973B8",
        "fill-rule": "evenodd",
        stroke: "none"
    },
    {
        width: "20px",
        height: "22px",
        viewBox: "0 0 20 22",
        desc: "icono-delete",
        d:
            "M13.001 0L6.999 0Q6.90061 0 6.8041 0.0191955Q6.7076 0.038391 6.6167 0.0760443Q6.52579 0.113698 6.44398 0.168362Q6.36217 0.223026 6.2926 0.2926Q6.22303 0.362175 6.16836 0.443985Q6.1137 0.525796 6.07604 0.616699Q6.03839 0.707602 6.01919 0.804105Q6 0.900607 6 0.999L6 2L0 2L0 5L2 5L2 21.001Q2 21.0994 2.0192 21.1959Q2.03839 21.2924 2.07604 21.3833Q2.1137 21.4742 2.16836 21.556Q2.22303 21.6378 2.2926 21.7074Q2.36217 21.777 2.44398 21.8316Q2.5258 21.8863 2.6167 21.924Q2.7076 21.9616 2.8041 21.9808Q2.90061 22 2.999 22L17.001 22Q17.0994 22 17.1959 21.9808Q17.2924 21.9616 17.3833 21.924Q17.4742 21.8863 17.556 21.8316Q17.6378 21.777 17.7074 21.7074Q17.777 21.6378 17.8316 21.556Q17.8863 21.4742 17.924 21.3833Q17.9616 21.2924 17.9808 21.1959Q18 21.0994 18 21.001L18 5L20 5L20 2L14 2L14 0.999Q14 0.900607 13.9808 0.804105Q13.9616 0.707602 13.924 0.616699Q13.8863 0.525796 13.8316 0.443985Q13.777 0.362175 13.7074 0.2926Q13.6378 0.223026 13.556 0.168362Q13.4742 0.113698 13.3833 0.0760443Q13.2924 0.038391 13.1959 0.0191955Q13.0994 0 13.001 0ZM7 11L7 19L5 17L5 11L7 11ZM11 19L11 11L9 11L9 17L11 19ZM15 11L15 19L13 17L13 11L15 11Z",
        fill: "#1973B8",
        "fill-rule": "evenodd",
        stroke: "none"
    },
    {
        width: "22px",
        height: "16px",
        viewBox: "0 0 22 16",
        desc: "icono-mail",
        d:
            "M21.001 0L0.999 0Q0.900607 0 0.804105 0.0191955Q0.707602 0.038391 0.616699 0.0760443Q0.525796 0.113698 0.443985 0.168362Q0.362175 0.223026 0.2926 0.2926Q0.223026 0.362175 0.168362 0.443985Q0.113698 0.525796 0.0760443 0.616699Q0.038391 0.707602 0.0191955 0.804105Q0 0.900607 0 0.999L0 15.001Q0 15.0994 0.0191955 15.1959Q0.038391 15.2924 0.0760443 15.3833Q0.113698 15.4742 0.168362 15.556Q0.223026 15.6378 0.2926 15.7074Q0.362175 15.777 0.443985 15.8316Q0.525796 15.8863 0.616699 15.924Q0.707602 15.9616 0.804105 15.9808Q0.900607 16 0.999 16L21.001 16Q21.0994 16 21.1959 15.9808Q21.2924 15.9616 21.3833 15.924Q21.4742 15.8863 21.556 15.8316Q21.6378 15.777 21.7074 15.7074Q21.777 15.6378 21.8316 15.556Q21.8863 15.4742 21.924 15.3833Q21.9616 15.2924 21.9808 15.1959Q22 15.0994 22 15.001L22 0.999Q22 0.900607 21.9808 0.804105Q21.9616 0.707602 21.924 0.616699Q21.8863 0.525796 21.8316 0.443985Q21.777 0.362175 21.7074 0.2926Q21.6378 0.223026 21.556 0.168362Q21.4742 0.113698 21.3833 0.0760443Q21.2924 0.038391 21.1959 0.0191955Q21.0994 0 21.001 0ZM20 6L11 11L2 6L2 3L11 8L20 3L20 6Z",
        fill: "#1973B8",
        "fill-rule": "evenodd",
        stroke: "none"
    },
    {
        width: "22px",
        height: "20px",
        viewBox: "0 0 22 20",
        desc: "icono-name",
        d:
            "M14.1318 6.0909C14.1318 6.4848 14.0903 6.8691 14.0125 7.2402C14.1694 7.2609 14.3292 7.2727 14.4919 7.2727C16.5002 7.2727 18.1282 5.6447 18.1282 3.6364C18.1282 1.6281 16.5002 0 14.4919 0C13.3177 0 12.2742 0.557 11.6094 1.4207C13.1274 2.4214 14.1318 4.1407 14.1318 6.0909ZM8.54085 10.1818C10.8001 10.1818 12.6318 8.3502 12.6318 6.0909C12.6318 3.8316 10.8001 2 8.54085 2C6.28155 2 4.44995 3.8316 4.44995 6.0909C4.44995 8.3502 6.28155 10.1818 8.54085 10.1818ZM16.8297 8.00024C19.7942 8.70774 21.9999 11.3206 21.9999 14.4376L21.9999 15.9999L18.2118 15.9999C17.4832 13.2183 15.4247 10.9261 12.6443 9.88304C13.0287 9.46734 13.3509 8.99364 13.596 8.47634C13.8803 8.52194 14.1709 8.54724 14.4655 8.54724C15.3148 8.54724 16.1192 8.34954 16.8297 8.00024ZM8.5143 11.6157C9.4699 11.6157 10.3779 11.3932 11.1772 11.0003C14.5123 11.7962 17 14.7358 17 18.2424L17 20L0 20L0 18.2424C0 14.7359 2.4945 11.7954 5.8334 11C6.6329 11.3931 7.5585 11.6157 8.5143 11.6157Z",
        fill: "#1973B8",
        "fill-rule": "evenodd",
        stroke: "none"
    },
    {
        width: "14px",
        height: "22px",
        viewBox: "0 0 14 22",
        desc: "icono-phone",
        d:
            "M0.999 0L13.001 0Q13.0994 0 13.1959 0.0191955Q13.2924 0.038391 13.3833 0.0760443Q13.4742 0.113698 13.556 0.168362Q13.6378 0.223026 13.7074 0.2926Q13.777 0.362175 13.8316 0.443985Q13.8863 0.525796 13.924 0.616699Q13.9616 0.707602 13.9808 0.804105Q14 0.900607 14 0.999L14 21.001Q14 21.0994 13.9808 21.1959Q13.9616 21.2924 13.924 21.3833Q13.8863 21.4742 13.8316 21.556Q13.777 21.6378 13.7074 21.7074Q13.6378 21.777 13.556 21.8316Q13.4742 21.8863 13.3833 21.924Q13.2924 21.9616 13.1959 21.9808Q13.0994 22 13.001 22L0.999 22Q0.900607 22 0.804105 21.9808Q0.707602 21.9616 0.616699 21.924Q0.525796 21.8863 0.443985 21.8316Q0.362175 21.777 0.2926 21.7074Q0.223026 21.6378 0.168362 21.556Q0.113698 21.4742 0.0760443 21.3833Q0.038391 21.2924 0.0191955 21.1959Q0 21.0994 0 21.001L0 0.999Q0 0.900607 0.0191955 0.804105Q0.038391 0.707602 0.0760443 0.616699Q0.113698 0.525796 0.168362 0.443985Q0.223026 0.362175 0.2926 0.2926Q0.362175 0.223026 0.443985 0.168362Q0.525796 0.113698 0.616699 0.0760443Q0.707602 0.038391 0.804105 0.0191955Q0.900607 0 0.999 0ZM12 2L2 2L2 18L12 18L12 2ZM4 4L10 4L10 9L4 15L4 4ZM8 20C8 20.5523 7.55228 21 7 21C6.44772 21 6 20.5523 6 20C6 19.4477 6.44772 19 7 19C7.55228 19 8 19.4477 8 20Z",
        fill: "#1973B8",
        "fill-rule": "evenodd",
        stroke: "none"
    },
    {
        width: "22px",
        height: "22px",
        viewBox: "0 0 22 22",
        desc: "icono-home",
        d:
            "M11 0L0 9.30767L1.6923 11L11 3.38461L20.3077 11L22 9.30767L11 0ZM14.6385 8.03843C11.1352 8.04216 8.29614 10.8813 8.29241 14.3846C8.29241 14.4455 8.29241 14.5064 8.2958 14.5673C8.3398 16.5 9.07003 18.1474 9.97287 19.4615L3.37459 19.4615C2.91005 19.4587 2.5358 19.0799 2.53859 18.6153L2.53859 11.8461L11.0001 5.0769L14.7037 8.03843L14.6385 8.03843ZM9.56152 14.3846C9.56152 11.5807 11.8345 9.30768 14.6384 9.30768C17.4416 9.30861 19.7136 11.5814 19.7136 14.3846L19.7136 14.5344C19.6138 19.0181 15.0547 21.8874 15.0547 21.8874C14.7951 22.0396 14.4728 22.0373 14.2154 21.8815C14.2154 21.8815 9.66137 19.019 9.56152 14.5344L9.56152 14.3846ZM14.6385 16.923C13.2366 16.923 12.1001 15.7866 12.1001 14.3846C12.1001 12.9826 13.2366 11.8461 14.6385 11.8461C16.0405 11.8461 17.177 12.9826 17.177 14.3846C17.177 15.7866 16.0405 16.923 14.6385 16.923Z",
        fill: "#1973B8",
        "fill-rule": "evenodd",
        stroke: "none"
    },
    {
        width: "22px",
        height: "22px",
        viewBox: "0 0 22 22",
        desc: "icono-agregar",
        d:
            "M0 11C0 4.92487 4.92487 0 11 0C17.0751 0 22 4.92487 22 11C22 17.0751 17.0751 22 11 22C4.92487 22 0 17.0751 0 11ZM10.0833 10.0833L10.0833 5.5L11.9167 5.5L11.9167 10.0833L17.4166 10.0833L15.5832 11.9166L11.9167 11.9166L11.9167 16.5L10.0833 16.5L10.0833 11.9166L5.5 11.9166L5.5 10.0833L10.0833 10.0833Z",
        fill: "#1973B8",
        "fill-rule": "evenodd",
        stroke: "none"
    },
    {
        width: "22px",
        height: "22px",
        viewBox: "0 0 22 22",
        desc: "icono-referencia",
        d:
            "M11 0L0 9.34354L1.6923 11.0423L11 3.39771L20.3077 11.0423L22 9.34354L11 0ZM14.6503 8.06937C11.1404 8.07311 8.29603 10.9285 8.29231 14.4518C8.29273 16.4497 9.22604 18.3318 10.8138 19.5363L3.37448 19.5363C2.91003 19.5335 2.53569 19.1533 2.53849 18.6869L2.53849 11.8917L11 5.09646L14.7036 8.06937L14.6503 8.06937ZM14.6491 9.34391C17.4595 9.34365 19.738 11.6306 19.7381 14.4518C19.7397 15.5294 19.4002 16.5797 18.7684 17.4511L22 20.6949L20.8694 21.8297L17.6381 18.5867C16.7692 19.2196 15.7232 19.5605 14.6498 19.5605C11.8394 19.5607 9.56089 17.2738 9.56072 14.4526C9.56047 11.6313 11.8386 9.34408 14.6491 9.34391ZM16.9112 14.4518C16.9112 13.1979 15.8986 12.1813 14.6495 12.1813L14.6495 11.0423C16.5229 11.0423 18.0416 12.5669 18.0416 14.4476L16.9112 14.4518Z",
        fill: "#1973B8",
        "fill-rule": "evenodd",
        stroke: "none"
    },
    {
        width: "22px",
        height: "22px",
        viewBox: "0 0 22 22",
        desc: "icono-telefono-fijo",
        d:
            "M21.7981 18.8846L16.7198 14.623C16.4658 14.4099 16.0671 14.4266 15.8336 14.6601L15.0927 15.401C15.0448 15.449 14.9955 15.4982 14.9454 15.5483L13.4177 17.0762C11.5468 16.3999 9.72717 15.2477 8.24127 13.7617C6.71567 12.236 5.60337 10.456 4.92717 8.58488L6.45487 7.05698C6.50497 7.00688 6.55417 6.95768 6.60217 6.90968L7.34297 6.16878C7.57647 5.93528 7.58547 5.52868 7.37237 5.27478L3.11507 0.199782C2.90197 -0.0541181 2.54047 -0.0669181 2.30697 0.166582L0.396671 2.07708C0.357971 2.11578 0.310271 2.14378 0.283571 2.19008L0.0554715 2.41828L0.0498715 2.41308L0.0326715 2.41008C-0.269129 7.50788 1.51967 12.7003 5.41107 16.5922C9.30267 20.484 14.4907 22.2691 19.588 21.9673L19.5812 21.9462L19.5837 21.9484L19.8119 21.7202C19.8484 21.6836 19.8862 21.6458 19.9249 21.6071L21.8353 19.6966C22.0687 19.4631 22.052 19.0977 21.7981 18.8846",
        fill: "#1973B8",
        "fill-rule": "evenodd",
        stroke: "none"
    }
];

export const svgConst = {
    version: "1.1",
    "xmlns:xlink": "http://www.w3.org/1999/xlink",
    xmlns: "http://www.w3.org/2000/svg"
};
