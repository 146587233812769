import React, { useEffect, useState, useLayoutEffect } from "react";
import { connect } from "react-redux";

const BottomBarInfo = props => {
    const [headerHeight, setHeaderHeight] = useState(0);
    useLayoutEffect(() => {
        function updateSize() {
            handleResize();
        }
        window.addEventListener("resize", updateSize);
        updateSize();
    }, [window.innerWidth]);

    useEffect(() => {
        handleResize();
    }, []);

    // espera unos segundos, antes de verificar el tamaño del header !!
    const handleResize = () => {
        setTimeout(() => {
            const header = document.getElementsByTagName("header");
            let height = header[0].clientHeight;
            setHeaderHeight(height);
        }, 100);
    };

    return (
        <div
            style={{
                background: "#f4f4f4",
                marginTop: !props.iShowSearch ? headerHeight : "60px",
                position: "fixed",
                width: "100%",
                zIndex: 9
            }}
        >
            {props.children}{" "}
        </div>
    );
};

const mapStateToProps = ({ headerReducer }) => {
    return headerReducer;
};

export default connect(
    mapStateToProps,
    null
)(BottomBarInfo);
