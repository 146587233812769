export const GET_CAR = "get_car";

export const SET_PRODUTS = "set_produts";

export const ADD_CAR = "add_car";

export const REMOVE_CAR_BY_ID = "remove_car_by_id";

export const REMOVE_ALL_CAR = "remove_all_car";

export const SUBTRACT_ITEM_CAR = "SUBTRACT_ITEM_CAR";
