import { cnnAjax } from "../connect/apiPuntos";

const BASE_PATH = "canje";

export const setCanjeServices = async body => {
    const response = await cnnAjax(`${BASE_PATH}/create`, "POST", null, body);

    return response;
};

// genera un row en la tabla de pedido !!
export const generateNumeroCanjeServices = async body => {
    const { response } = await cnnAjax(
        `${BASE_PATH}/generateNumber`,
        "POST",
        null,
        body
    );

    return response;
};

export const updateAuthCash = async body => {
    const { status, ...rest } = await cnnAjax(
        `${BASE_PATH}/update/cash`,
        "POST",
        null,
        body
    );

    return status;
};

export const updateAuthPoints = async (body) => {
    const response = await cnnAjax(
        `${BASE_PATH}/update/puntos`,
        "POST",
        null,
        body
    );

    return response
}

/**
 * 
 * @param {object} body { num_pedido: string, token: string }
 */
export const getCanjeBYNumber = async (body) => {
    const response = await cnnAjax(
        `${BASE_PATH}/pedido`,
        "POST",
        null,
        body
    );


    return response;
}
