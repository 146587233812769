import { getSessionKey } from "services/visaCash/visaCashServices";
import { START_BUTTON_CASH_VISA } from "redux/types/visaCashTypes";
import { ERROR_SESSION_VISA, IS_LOADING } from "redux/types/visaCashTypes";
import { setAuthStorage } from "services/localStorage/authStorage";

export const initialButton = (amount, token) => async disptach => {
    disptach({
        type: IS_LOADING
    });
    try {

        const session = await getSessionKey(amount, token);

        if (session.status == "error") {
            if (session.message["status"] == 412) {
                setAuthStorage();
                return disptach(error({ error: "No autorizado" }));
            }
        }

        return disptach(success({ ...session }));
    } catch (err) {
        return disptach(error(err));
    }
};

function success(data) {
    return {
        type: START_BUTTON_CASH_VISA,
        payload: { ...data }
    };
}

function error(err) {
    return {
        type: ERROR_SESSION_VISA,
        payload: err
    };
}
