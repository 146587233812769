import React, { useEffect } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardFooter from "components/Card/CardFooter.js";
import Button from "components/CustomButtons/Button.js";
import WrapperMovile from 'hoc/WrapperMovile';
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import style from "assets/jss/material-kit-pro-react/views/ComplaintBook.js";
import moment from "moment";

const useStyles = makeStyles(style);

// se le esta colocaldo este prefijo a los numero de reclamos!!
const PREFIX_ID = "10";

const ResponseComplaintBook = (props) => {
    const classes = useStyles();
    const { location, history } = props;

    useEffect(() => {
        if (!location.state.hasOwnProperty) {
            handleGoHome();
        }

    }, [])

    const handleGoHome = () => {
        history.replace("/");
    }

    
    const {response} = location.state.result; 
    return (
        <WrapperMovile>
            <div className={classNames(classes.main, classes.container)}>
                <GridContainer >
                    <GridItem xs={12} sm={12} md={12} className={classes.marginContainer}>
                        <div className={classNames(classes.centerFlex)}>
                            <h3>Puntos Delivery - Libro de reclamaciones</h3>
                        </div>
                        <div className={classNames(classes.centerFlex, classes.marginText)}>
                            <p>Rewards Peru S.A.C: 20536876953</p>
                        </div>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12} >
                        <Card color="white">
                            <CardBody >
                                <div className={classNames(classes.centerFlex)}>
                                    <h3>Hemos recibido su reclamo.</h3>
                                </div >
                                <div className={classes.marginText}>
                                    <p >Hemos recibido su reclamo, el cual será evaluado y respondido a la dirección de correo electrónico indicada.</p>
                                    <p className={classes.textClaint}>Fecha del registro:  {moment(response.create_date.split(" ")[0]).format("DD-MM-YYYY")}</p>
                                    <p className={classes.textClaint}>Número de reclamo: {`#${PREFIX_ID}${response.id}`}</p>
                                </div>
                            </CardBody>
                            <CardFooter>
                                <Button
                                    color="info"
                                    size="md"
                                    type="button"
                                    onClick={() => handleGoHome()}
                                >
                                    Regresar al inicio
                                </Button>
                            </CardFooter>
                        </Card>
                    </GridItem>
                </GridContainer>
            </div>
        </WrapperMovile>
    )
}

export default ResponseComplaintBook;
