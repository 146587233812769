const directionStyle = {
    titleNoDirection: {
        marginLeft: "-15px"
    },
    iconActive: {
        fontSize: "19px",
        marginBottom: "3px"
    },
    container: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: "35px 0"
    },
    containerAccordion: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        paddingBottom: "35px"
    }
};

export default directionStyle;
