import { cnn, cnnAjax } from "../connect/apiPuntos";

const BASE_PATH = "visa/puntos";

/**
 * Obtiene los puntos del usuario!!
 * @param {Object} token {token: string}
 */
export const getPoints = async token => {
    const data = await cnnAjax(`${BASE_PATH}/query`, "POST", null, token);

    return data;
};

export const setCanjePuntos = async body => {
    const response = await cnnAjax(
        `${BASE_PATH}/change`,
        "POST",
        null,
        body
    );

    return response.response;
};

/**
 * 
 * @param {object} body {token: strign, idTransacionVoid: string, amount: string | int | float}
 */
export const annulmentPoints = async (body) => {
    const response = await cnnAjax(
        `${BASE_PATH}/anulacion`,
        "POST",
        null,
        body
    );

    return response;
}