/*eslint-disable*/
import React, { useEffect, useState } from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// core components
import Paginations from "components/Pagination/Pagination.js";
// sections for this page
import SectionProducts from "views/EcommercePage/Sections/SectionProducts";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

//config
import { pathBanner, baseRouteImages } from "../../config/config.json";

// redux
import { connect } from "react-redux";
import * as productsActions from "../../redux/actions/productsActions";
import * as filterProductsActions from "../../redux/actions/filterProductsActions";

import MenuItem from "@material-ui/core/MenuItem";

import styles from "assets/jss/material-kit-pro-react/views/ecommerceStyle";
import { lowerOrUpper, capitalize } from "utils/utils";
import BannerSection from "views/Banner/BannerSection";
import WrapperMovile from "hoc/WrapperMovile";
import SectionOrder from "./Sections/SectionOrder";
import { capitalizeFirst } from "utils/utils";

const useStyles = makeStyles(styles);

const EcommercePage = props => {
    const classes = useStyles();
    const [categoryParam, setCategoryParam] = useState("");
    const [isLoadingProduct, setIsLoadingProduct] = useState(false);
    const [totalProducts, setTotalProducts] = useState(0);

    const [orderActive, setOrderActive] = useState(0);

    useEffect(() => {
        const { match, productsReducer } = props;
        const {
            params: { category }
        } = match;
        const page = props.location.search.split("=")[1] || 1;
        setCategoryParam(category);

        if (!productsReducer.isLoading) {
            setIsLoadingProduct(true);
            props.getProductsAction(`${category}?page=${page}`, props.filter);
        } else if (!productsReducer.products && !isLoadingProduct) {
            setIsLoadingProduct(true);
            props.getProductsAction(`${category}?page=${page}`, props.filter);
        }

        handleSelectedCategory();
        goTop();
    }, [props.match.params, props.filter]);

    useEffect(() => {
        if (
            props.productsReducer.products &&
            props.productsReducer.products.total !== totalProducts
        ) {
            setTotalProducts(props.productsReducer.products.total);
        }
    }, [props.productsReducer.products]);

    // useEffect(() => {
    // handleSelectedCategory();
    // }, []);

    // mueve la vista al top de la pagina
    const goTop = () => {
        window.scrollTo(0, 0);
        document.body.scrollTop = 0;
    };

    // va a detalle del producto por id
    const hanleDetailProduct = id => {
        props.history.push(`/producto/${btoa(id)}`);
    };

    // se optiene el parametro de paginacion por la url !!
    // @OJO usar queryString!!
    const pagination = e => {
        // props.location.search = "?page=1"
        const { location } = props;
        const { pathname, search } = location;
        const newSearch = search.split("=")[0] + "=" + e;

        props.history.push(`${pathname}${newSearch}`);
    };

    const setPaginator = () => {
        if (!props.productsReducer.products) {
            return null;
        }
        let pageArr = [];
        const currentPage = props.location.search.split("=")[1];
        const { products } = props.productsReducer;

        for (let i = 0; i < products.last_page; i++) {
            let obj = {
                text: i + 1,
                onClick: () => pagination(i + 1),
                active: i + 1 == currentPage
            };
            pageArr.push(obj);
        }

        pageArr.unshift({
            text: "Anterior",
            onClick: () => pagination(parseInt(currentPage) - 1),
            disabled: currentPage == 1
        });

        pageArr.push({
            text: "Siguiente",
            onClick: () => pagination(parseInt(currentPage) + 1),
            disabled: currentPage == products.last_page
        });

        return pageArr.length > 3 ? (
            <Paginations color="info" pages={pageArr} />
        ) : null;
    };

    const {
        match: { params }
    } = props;

    const handleSelectedCategory = () => {
        const { categories } = props;
        if (!categories.length) return;
        const index = categories.findIndex(
            cate => lowerOrUpper(cate.slug) === lowerOrUpper(categoryParam)
        );
        if (index === -1) return;

        return handleRenderNameCategory(categories[index]);
    };

    const handleRenderNameCategory = ({ id, nombre }) => {
        if (id === 17) {
            return capitalizeFirst(nombre);
        }

        return capitalize(nombre);
    };

    const setBannerCategory = () => {
        const { categories } = props;

        if (!categories.length || !categoryParam) return;

        const index = categories.findIndex(
            cate => lowerOrUpper(cate.slug) === lowerOrUpper(categoryParam)
        );

        let banner = "Banner_Category_Entretenimiento"; // REFACTOR: imagen por defecto !!

        if (index > -1 && categories[index].hasOwnProperty("imagen")) {
            banner = categories[index].imagen;
        }

        return `${baseRouteImages}${pathBanner}${banner}.jpg`;
    };

    // order
    const handleSelect = () => {
        return INITIAL_ORDER.map((orderFilter, index) => (
            <MenuItem
                key={`${orderFilter}-${index}`}
                classes={{
                    root: classes.selectMenuItem,
                    selected: classes.selectMenuItemSelected
                }}
                value={orderFilter.id}
            >
                {orderFilter.title}
            </MenuItem>
        ));
    };

    return (
        <WrapperMovile>
            <BannerSection
                bg={setBannerCategory()}
                title={handleSelectedCategory()}
            />
            <div className={classNames(classes.main, classes.mainRaised)}>
                {/* <SectionLatestOffers /> */}

                {/* Orders  */}
                {!!totalProducts && (
                    <SectionOrder totalProducts={totalProducts} />
                )}

                <SectionProducts
                    products={props.products || []}
                    title={params.category}
                    detailProduct={hanleDetailProduct}
                />

                <div className={classNames(classes.containerPaginator)}>
                    {setPaginator()}
                </div>
            </div>
        </WrapperMovile>
    );
};

const mapStateToProps = ({
    productsReducer,
    filterProductsReducer,
    categoriesReducer,
    headerReducer,
    userReducer
}) => {
    return {
        productsReducer,
        filter: filterProductsReducer,
        categories: categoriesReducer.categories,
        ...headerReducer,
        ...userReducer
    };
};

const mapDispatchToProps = {
    ...productsActions,
    ...filterProductsActions
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(EcommercePage);
