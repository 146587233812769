import React, { useEffect } from "react";
import WrapperMovile from "hoc/WrapperMovile";
import { pathBanner, baseRouteImages } from "../../config/config.json";
import BannerSection from "views/Banner/BannerSection";
import { getProductsOffersAction } from "redux/actions/productsActions";
import { getMarcasFroCategory } from "redux/actions/marcasActions";
import { connect } from "react-redux";
import SectionProducts from "views/EcommercePage/Sections/SectionProducts";

const OfferProducts = props => {
    useEffect(() => {
        handleInitialRender();

        return () => {};
    }, []);

    const handleInitialRender = async () => {
        await props.getProductsOffersAction();
        await props.getMarcasFroCategory("All");
    };

    const handleBgImageBanner = () => {
        const banner = "Banner_Oferta"; // REFACTOR: imagen por defecto !!

        return `${baseRouteImages}${pathBanner}${banner}.jpg`;
    };

    const hanleDetailProduct = id => {
        props.history.push(`/producto/${btoa(id)}`);
    };

    return (
        <WrapperMovile>
            <BannerSection
                bg={handleBgImageBanner()}
                title={"Productos en oferta"}
            />

            <SectionProducts detailProduct={hanleDetailProduct} />
        </WrapperMovile>
    );
};

const mapStateToProps = ({ productsReducer }) => productsReducer;

const mapDispatchToProps = {
    getMarcasFroCategory,
    getProductsOffersAction
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(OfferProducts);
