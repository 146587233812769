import {
    GET_INFO,
    SET_INFO,
    REMOVE_INFO,
    UPDATE_PROFILE,
    GET_POINTS_USERS,
    ERROR_USER,
    LOADING_POINTS,
    GET_USER_DATA,
    UPDATE_POINTS_USER,
    SET_CODE_VISA_POINT
} from "../types/userTypes";
import { setUserStorage } from "services/localStorage/userStorage";
import { updateProfile } from "services/user/userServices";
import { getPoints } from "services/visaPoints/visaPointsServices";
import { getUserStorage } from "services/localStorage/userStorage";
import { getUserById } from "services/user/userServices";
import { setAuthStorage } from "services/localStorage/authStorage";
import { autoLoginServices } from "services/user/userServices";

// TRABAJANDO: llamar al api !!
export const singout = () => dispatch => {
    return dispatch({
        type: REMOVE_INFO
    });
};

// deprecate <-
// export const getUserDataById = id => async dispatch => {
//     try {
//         const user = await getUserById(id);
//         setUserStorage(user);

//         dispatch({
//             type: GET_USER_DATA,
//             payload: user
//         });
//     } catch (error) {}
// };

export const setUserInfo = state => dispatch => {
    setUserStorage(state);
    return dispatch({
        type: SET_INFO,
        payload: state
    });
};

/**
 *  hace el auto login
 * @param {String} token tokenBanking
 */
export const autoLogin = token => async dispatch => {
    const user = await autoLoginServices({ token });

    if (user.status === "error") {
        return dispatch({
            type: ERROR_USER,
            payload: user.message
        });
    }

    setUserStorage(user.response);
    return dispatch({
        type: SET_INFO,
        payload: user.response
    });
};

export const setProfile = body => async dispatch => {
    const newUser = await updateProfile(body);

    if (newUser.status == "error") {
        if (newUser.message["status"] == 412) {
            setAuthStorage();
            return;
        }
    }

    if (!newUser.response) {
        return dispatch({
            type: ERROR_USER,
            payload: newUser["message"]["responseJSON"]["message"] || "Error"
        });
    }
    delete newUser.response.puntos;
    // que se actualizen solo los datos nuevos !!
    setUserStorage(newUser.response);

    return dispatch({
        type: UPDATE_PROFILE,
        payload: newUser.response
    });
};

/**
 * Realiza la peticion al servicio de visa net para traer los puntos del usuario !!
 * @param {Object} token { token: string }
 */
export const getPointsUser = token => async dispatch => {
    dispatch({
        type: LOADING_POINTS,
        payload: true
    });
    dispatch({
        type: SET_CODE_VISA_POINT,
        payload: ""
    });

    try {
        const { response, ...rest } = await getPoints(token);

        if (rest.status == "error") {
            if (rest.message["status"] == 412) {
                setAuthStorage();
            }
        }

        let error = 400;

        if (!response) {
            if (rest.message === "El usuario no tiene tarjeta") {
                error = "MPE0007";
            } else {
                return dispatch({
                    type: ERROR_USER,
                    payload: "Error en la peticion"
                });
            }
        } else {
            if (!!response.dataMap) {
                error = response.dataMap.codError;
            }
        }

        const userPoints = hasUserPoint(!response ? 0 : response.dataMap);

        addPointToStorage(userPoints);

        dispatch({
            type: SET_CODE_VISA_POINT,
            payload: error
        });

        return dispatch({
            type: GET_POINTS_USERS,
            payload: userPoints
        });
    } catch (error) {
        dispatch({
            type: GET_POINTS_USERS,
            payload: 0
        });

        return dispatch({
            type: ERROR_USER,
            payload: error
        });
    }
};

export const updatePointsUser = points => dispatch => {
    dispatch({
        type: LOADING_POINTS,
        payload: true
    });

    const newPoints = hasUserPoint(points);
    addPointToStorage(newPoints);

    return dispatch({
        type: UPDATE_POINTS_USER,
        payload: points
    });
};

const hasUserPoint = user => {
    return !user ? 0 : user.availablePoints;
};

const addPointToStorage = userPoint => {
    // let puntos = hasUserPoint(user);
    // if (!Object.keys(user).length) return;
    const localUser = getUserStorage();
    localUser.puntos = userPoint;

    setUserStorage(localUser);
};
