import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import classNames from "classnames";
import Close from "@material-ui/icons/Close";

// redux !!
import { connect } from "react-redux";
import * as modalActions from "redux/actions/modalActions";
import * as userActions from "redux/actions/userActions";

import loginPageStyle from "assets/jss/material-kit-pro-react/views/loginPageStyle.js";

import Slide from "@material-ui/core/Slide";
import ContainerCreditCard from "components/CreditCard/ContainerCreditCard";

const useStyles = makeStyles(loginPageStyle);

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});

Transition.displayName = "Transition";


const ModalCreditCard = (props) => {
    const classes = useStyles();

    return (
        <Dialog
            classes={{
                root: classNames(classes.modalRoot),
                paper: classes.modal
            }}
            open={props.show}
            TransitionComponent={Transition}
            onClose={props.toggleCreditCard}
            // keepMounted
            aria-labelledby="classic-modal-slide-title"
            aria-describedby="classic-modal-slide-description"
        >
            <DialogContent
                id="classic-modal-slide-description"
                className={classNames(classes.modalBody, classes.rootCreditCard)}
            >
                <ContainerCreditCard />

            </DialogContent>
        </Dialog >
    );
};

const mapStateToProps = ({ ModalsReducer, userReducer }) => {
    return {
        ...ModalsReducer.creditCard,
        user: userReducer.user
    };
};

export default connect(mapStateToProps, { ...modalActions, ...userActions })(ModalCreditCard);