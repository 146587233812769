import React from "react";
import { Dialog, DialogContent } from "@material-ui/core";
import Slide from "@material-ui/core/Slide";
import { makeStyles } from "@material-ui/core/styles";
import modalBeetrackStyles from "assets/jss/material-kit-pro-react/components/ModalBeetrack";
import javascriptStyles from "assets/jss/material-kit-pro-react/views/componentsSections/javascriptStyles.js";
import classNames from "classnames";
import { connect } from "react-redux";

const useStyles = makeStyles(javascriptStyles);
const beetrackStyles = makeStyles(modalBeetrackStyles);

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});

Transition.displayName = "Transition";

const ModalBeetrack = ({ show, onClose, height }) => {
    const classes = useStyles();
    const beetrackClasses = beetrackStyles();

    return (
        <Dialog
            classes={{
                root: classes.modalRoot,
                paper: classNames(classes.modal, beetrackClasses.content)
            }}
            open={show}
            TransitionComponent={Transition}
            keepMounted
            onClose={onClose}
        >
            <DialogContent className={beetrackClasses.content2}>
                <iframe
                    className="frame"
                    name="beetrack-widget"
                    id="beetrack-widget"
                    frameborder="0"
                    width="100%"
                    height={height}
                    src="https://puntosdelivery.dispatchtrack.com/widget/eyf3Rc77F710XfLelG3OtA"
                />
            </DialogContent>
        </Dialog>
    );
};

const mapStateToProps = ({ ModalsReducer }) => {
    return {
        beetrackModal: ModalsReducer?.beetrackModal
    };
};

export default connect(
    mapStateToProps,
    null
)(ModalBeetrack);
