import { GET_MARCAS_FOR_CATEGORY } from "redux/types/marcasTypes";
import { marcasForCategory } from "../../services/home/marcasServices";

export const getMarcasFroCategory = category => async dispatch => {
    const marcas = await marcasForCategory(category);

    return dispatch({
        type: GET_MARCAS_FOR_CATEGORY,
        payload: marcas.response
    });
};
