// chekea si el string tiene valores en minusculas !!
const lowerCasePatter = "/^(?=.*[a-z]).+$/";

const upperCasePatter = "/^(?=.*[A-Z]).+$/";

const numberCasePatter = "/^(?=.*[\d]).*$/";

const specialsCharracteresPatter = "/([-+=_!@#$%^&*.,;:'\"<>/?`~\[\]\(\)\{\}\\\|\s])/";

const charecterCountPatter = "/^.{8,}/";


// ============ exports ===================== //
export const validationNumber = new RegExp("^[0-9,*]+$");

export const validationPhone = new RegExp("/^[6-9]d{9}$/");

export const validateSpecialCaracteres = new RegExp(specialsCharracteresPatter);


export const valideOnliNumber = (evt) => {
    const code = evt.which ? evt.which : evt.keyCode;
    if ((code == 8) || (code >= 48 && code <= 57)) {
        return true;
    }

    return false;
}

// valida el correo!!
export const validateEmail = (email) => {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}

// replace Accents in the string !!
export const removeAccents = (str) => {
    return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
} 