import {
    title,
    main,
    mainRaised,
    mrAuto,
    mlAuto,
    container,
    description,
    blackColor,
    whiteColor,
    grayColor,
    hexToRgb,
    marginSectionTop,
    titleCarousel,
    preFooter,
    ffLight
} from "assets/jss/material-kit-pro-react.js";
import imagesStyles from "assets/jss/material-kit-pro-react/imagesStyles.js";

const styles = {
    ...imagesStyles,
    ffLight,
    preFooter,
    title,
    main,
    mainRaised,
    mrAuto,
    mlAuto,
    description,
    marginSectionTop,
    selectMenu: {
        height: "35px",
        display: "flex",
        alignItems: "center"
    },
    titleCarousel: {
        ...titleCarousel,
        marginBottom: "32px"
    },
    textCenter: {
        textAlign: "center !important"
    },
    container: {
        ...container,
        zIndex: "2"
    },
    brand: {
        "& h1, & h4": {
            color: whiteColor
        }
    },
    card: {},
    subscribeButton: {},
    cardBody: {
        padding: "15px",
        "& form": {
            marginBottom: "0"
        }
    },
    cardForm: {
        margin: "0 0 0 14px",
        padding: 0,
        top: 10
    },
    subscribeLine: {
        padding: "1.875rem 0px",
        "& $card": {
            marginTop: "30px"
        },
        "& form": { margin: "0px" },
        "&$subscribeLineImage:after": {
            position: "absolute",
            zIndex: 1,
            width: "100%",
            height: "100%",
            display: "block",
            left: 0,
            top: 0,
            content: "''",
            backgroundColor: "rgba(" + hexToRgb(blackColor) + ",0.66)"
        }
    },
    subscribeLineImage: {
        position: "relative",
        backgroundPosition: "bottom center",
        backgroundSize: "cover",
        "& $container": {
            zIndex: 2,
            position: "relative"
        },
        "& $title": {
            color: whiteColor
        },
        "& $description": {
            color: grayColor[0]
        }
    },
    socialFeed: {
        "& p": {
            display: "table-cell",
            verticalAlign: "top",
            overflow: "hidden",
            paddingBottom: "10px",
            maxWidth: 300
        },
        "& i": {
            fontSize: "20px",
            display: "table-cell",
            paddingRight: "10px"
        }
    },
    img: {
        width: "20%",
        marginRight: "5%",
        marginBottom: "5%",
        float: "left"
    },
    list: {
        marginBottom: "0",
        padding: "0",
        marginTop: "0"
    },
    inlineBlock: {
        display: "inline-block",
        padding: "0px",
        width: "auto"
    },
    left: {
        float: "left!important",
        display: "block"
    },
    right: {
        padding: "15px 0",
        margin: "0",
        float: "right"
    },
    aClass: {
        textDecoration: "none",
        backgroundColor: "transparent"
    },
    block: {
        color: "inherit",
        padding: "0.9375rem",
        fontWeight: "500",
        fontSize: "12px",
        textTransform: "uppercase",
        borderRadius: "0",
        textDecoration: "none",
        position: "relative",
        display: "block"
    },
    containerPaginator: {
        justifyContent: "center",
        alignItems: "center",
        paddingBottom: "1.5em",
        display: "flex"
    },
    pt_1em: {
        paddingTop: "1em"
    },
    ptTitle: {
        paddingTop: "35px"
    },
    sizeTitle: {
        fontSize: "2em",
        "@media (max-width: 600px)": {
            lineHeight: "normal",
            fontSize: "1.5em"
        }
    },
    marginInfoContainer: {
        marginTop: "100px",
        paddingTop: "10px",
        paddingBottom: "10px"
    },
    mt25: {
        marginTop: "35px"
    },
    fz19: {
        fontSize: "19px"
    },
    bgWhite: {
        backgroundColor: "white"
    },
    sectionWhite: {
        backgroundColor: "white",
        marginTop: "-31px",
        paddingTop: "72px",
        paddingBottom: "45px"
    },
    orderContainer: {
        paddingTop: "15px"
    },
    textOrder: {
        paddingTop: "10px",
        fontSize: "14px",
        "@media (max-width: 600px)": {
            paddingBottom: "20px"
        }
    },
    sectionGray: {
        paddingTop: "50px",
        paddingBottom: "50px",
        "@media (max-width: 900px)": {
            paddingTop: "40px"
        }
    },
    heightContainer: {
        paddingLeft: "5px ",
        paddingRight: "5px ",
        "@media (max-width: 900px)": {
            marginTop: "10px"
        }
    },
    pointer: {
        cursor: "pointer"
    },
    centerItem: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    }
};

export default styles;
