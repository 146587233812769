import {
    main,
    mainRaised,
    infoColor,
    title,
    description,
    cardTitle,
    cardDescription,
    ffLight
} from "assets/jss/material-kit-pro-react.js";

const infoPageStyle = {
    ...main,
    ...mainRaised,
    ...title,
    ...description,
    cardDescription,
    cardTitle: {
        ...cardTitle,
        ...ffLight,
        "@media (max-width: 600px)": {
            lineHeight: "inherit"
        },
    },
    minHgtCard: {
        minHeight: "302px"
    },
    cardInfoLanding: {
        display: "flex",
        justifyContent: "center"
    },
    iconBeetrack: {
        fontSize: "5em",
        color: infoColor[0]
    },
    mb30: {
        marginBottom: "-30px"
    },
    mt: {
        paddingTop: "3em"
    },
    pl5: {
        paddingLeft: "5px"
    },
    pointer: {
        cursor: "pointer"
    },
    contentDialog: {
        paddingTop: "19px"
    },
    containerModal: {
        paddingLeft: "25px",
        paddingRight: "25px",
        textAlign: "justify"
    },
    containerTerminos: {
        paddingLeft: "20px",
        paddingRight: "20px"
    },
    paddingTitleBeetrack: {
        paddingLeft: "22px"
    },
    colorInfo: {
        color: infoColor[0]
    },
    actionBanner: {
        display: "flex",
        marginTop: "20px",
        alignItems: "center"
    }
};

export default infoPageStyle;
