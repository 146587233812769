import { TIME } from "./const/constStorage";

import { objectToString, stringToObject } from "../../utils/utils";

// regresa los datos del usuario !!
export const getTimeStorage = () => {
    const user = localStorage.getItem(TIME);
    return !!user ? stringToObject(user) : null;
};

// agrega los datos del usuario al storage !!
export const setTimeStorage = data => {
    localStorage.setItem(TIME, objectToString(data));
};

// elimina usuario del storage !!
export const removeTimeStorage = () => {
    localStorage.removeItem(TIME);
};
