import React, { useEffect } from "react";
import classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import WrapperMovile from "hoc/WrapperMovile";
import Book from "@material-ui/icons/Book";
import InfoArea from "components/InfoArea/InfoArea.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import style from "assets/jss/material-kit-pro-react/views/ComplaintBook.js";
import FormComplaintBook from "components/Forms/FormComplaintBook";

const useStyles = makeStyles(style);

const ComplaintBook = props => {
    const classes = useStyles();

    useEffect(() => {
        goTop();
    }, []);

    const goTop = () => {
        window.scrollTo(0, 0);
        document.body.scrollTop = 0;
    };

    return (
        <WrapperMovile>
            <div
                className={classNames(
                    classes.main,
                    classes.container,
                    classes.heightAuto
                )}
            >
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                        <InfoArea
                            className={classes.infoArea}
                            title={"Libro de Reclamaciones"}
                            classText={classes.ffBook}
                            icon={Book}
                            iconColor="info"
                        />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                        <FormComplaintBook {...props} />
                    </GridItem>
                </GridContainer>
            </div>
        </WrapperMovile>
    );
};

ComplaintBook.propTypes = {};

export default ComplaintBook;
