import { cnn } from "../connect/apiPuntos";

export const marcaForId = async id => {
	const { data } = await cnn(`marcas/${id}`, "GET");
	return data;
};

export const marcasForCategory = async category => {
	const { data } = await cnn(`marcas/${category}/category`);
	return data;
};
