// storage de usuario !!
export const USER = "user";

// storage de token auth !!
export const AUTH = "auth";

// storage de carrito de compras !!
export const CAR = "car";

export const POINTS = "points";

export const TIME = "time";