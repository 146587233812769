import {
    SHOW_MODAL,
    CLOSE_MODAL,
    SHOW_DIRECTIONS_MODAL,
    CLOSE_DIRECTIONS_MODAL,
    SHOW_NEW_DIRECTION_MODAL,
    CLOSE_NEW_DIRECTION_MODAL,
    SHOW_UPDATE_DIRECTION_MODAL,
    CLOSE_UPDATE_DIRECTION_MODAL,
    SHOW_MESSAGE_NO_POINTS_IN_CARD_MODAL,
    CLOSE_MESSAGE_NO_POINTS_IN_CARD_MODAL,
    TOGGLE_BEETRACK_MODAL
} from "redux/types/modalTypes";
import { TOGGLE_LOGIN_MODAL } from "redux/types/modalTypes";
import { TOGGLE_GLOBAL_LOADER_MODAL } from "redux/types/modalTypes";
import { TOGGLE_CREDIT_CARD_MODAL } from "redux/types/modalTypes";

export const toggleLoginModal = () => dispatch => {
    return dispatch({
        type: TOGGLE_LOGIN_MODAL
    });
};

export const showModal = state => dispatch => {
    return dispatch({
        type: SHOW_MODAL,
        payload: state
    });
};

export const hidenModal = () => dispatch =>
    dispatch({
        type: CLOSE_MODAL
    });

// modal generic !!
// muestra el modal de direcciones !!
export const showModalDirections = () => dispatch => {
    return dispatch({
        type: SHOW_DIRECTIONS_MODAL
    });
};

// econde el modal de direcciones
export const hidenModalDirections = () => dispatch => {
    return dispatch({
        type: CLOSE_DIRECTIONS_MODAL
    });
};

// muesta el modal de formulario de direcciones
export const shoModalFormDirections = () => dispatch => {
    return dispatch({
        type: SHOW_NEW_DIRECTION_MODAL
    });
};

// esconde el modal de formulario de direcciones
export const hidenModalFormDirections = () => dispatch => {
    return dispatch({
        type: CLOSE_NEW_DIRECTION_MODAL
    });
};

// muesta el modal de formulario de direcciones
export const shoModalFormUpdateDirections = () => dispatch => {
    return dispatch({
        type: SHOW_UPDATE_DIRECTION_MODAL
    });
};

// esconde el modal de formulario de direcciones
export const hidenModalFormUpdateDirections = () => dispatch => {
    return dispatch({
        type: CLOSE_UPDATE_DIRECTION_MODAL
    });
};

export const showMessageNoPointsInCard = () => dispatch => {
    return dispatch({
        type: SHOW_MESSAGE_NO_POINTS_IN_CARD_MODAL
    });
};

export const closeMessageNoPointsInCard = () => dispatch => {
    return dispatch({
        type: CLOSE_MESSAGE_NO_POINTS_IN_CARD_MODAL
    });
};


// modal beetrack !!
export const togglebeetrackTrack = () => dispatch => {
    return dispatch({
        type: TOGGLE_BEETRACK_MODAL
    });
}

export const toggleGlobalLoader = () => dispatch => {
    return dispatch({
        type: TOGGLE_GLOBAL_LOADER_MODAL
    });
}

export const toggleCreditCard = () => dispatch => {
    return dispatch({
        type: TOGGLE_CREDIT_CARD_MODAL
    });
}