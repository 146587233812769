import React, { useState, useEffect } from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";


import Button from "components/CustomButtons/Button.js";
import javascriptStyles from "assets/jss/material-kit-pro-react/views/componentsSections/javascriptStyles.js";
import Slide from "@material-ui/core/Slide";
import { DialogTitle } from "@material-ui/core";

const useStyles = makeStyles(javascriptStyles);

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});

Transition.displayName = "Transition";

const ModalGericInfo = ({
    showModal,
    closeModal,
    content,
    noButtonClose,
    classContent,
    title,
    butonAction,
    classTitle,
    buttonClose,
    classFooter,
    ...props
}) => {
    const classes = useStyles();
    const [btnDisable, setBtnDisable] = useState(false);

    useEffect(() => {

        return () => {
            setBtnDisable(false)
        }
    }, [])

    const handleClick = () => {
        setBtnDisable(true);
        butonAction.click();
    }

    return (
        <Dialog
            classes={{
                root: classes.modalRoot,
                paper: classes.modal
            }}
            open={showModal}
            TransitionComponent={Transition}
            keepMounted
            onClose={closeModal}
            aria-labelledby="classic-modal-slide-title"
            aria-describedby="classic-modal-slide-description"
        >
            {title && (
                <DialogTitle
                    className={classNames(classes.paddingHeader, classTitle)}
                >
                    {title}
                </DialogTitle>
            )}
            <DialogContent
                // id="classic-modal-slide-description"
                className={classNames(
                    classes.modalBody,
                    classes.paddingBody,
                    // classes.noPaddingHorizont,
                    classContent
                )}
            >
                {!content ? props.children : content}
            </DialogContent>
            <DialogActions
                className={classNames(classes.modalFooter, classes.paddingFooter, classFooter)}
            >
                {noButtonClose ? null : (
                    <Button
                        onClick={closeModal}
                        color="info"
                        className={classNames(
                            classes.modalSmallFooterFirstButton,
                            classes.modalSmallFooterSecondButton)
                        }
                    >
                        Cerrar
                    </Button>
                )}
                {buttonClose && (
                    <Button
                        onClick={buttonClose.click}
                        className={classNames(
                            classes.modalSmallFooterFirstButton,
                            classes.modalSmallFooterSecondButton)
                        }
                    >
                        {buttonClose.title}
                    </Button>
                )}
                {butonAction && (
                    <Button
                        onClick={handleClick}
                        color="bbva"
                        disabled={btnDisable}
                        className={classNames(
                            classes.modalSmallFooterFirstButton,
                            classes.modalSmallFooterSecondButton)
                        }
                    >
                        {butonAction.title}
                    </Button>
                )}
            </DialogActions>
        </Dialog>
    );
};

export default ModalGericInfo;
