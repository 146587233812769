import {
    primaryColor,
    warningColor,
    dangerColor,
    successColor,
    infoColor,
    roseColor,
    grayColor,
    title,
    ffLight
} from "assets/jss/material-kit-pro-react.js";

const infoAreaBbvaStyle = {
    infoArea: {
        margin: "0 auto",
        padding: "10px 0 30px"
    },
    title: {
        ...title,
        margin: ".75rem 0 0.875rem !important",
        minHeight: "unset",
        fontSize: "1.8em",
        fontWeight: "300",
        textAlign: "center",
        ...ffLight
    },
    image: {
        witdth: "170px"
    },
    imageCenter: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    },
    description: {
        color: grayColor[0],
        fontSize: "1em",
        textAlign: "center"
    }
};

export default infoAreaBbvaStyle;
