import {
    container,
    mlAuto,
    section,
    main,
    mainRaised,
    title,
    cardTitle,
    grayColor,
    roseColor,
    ffBook,
    corlosGrayDescuento
} from "assets/jss/material-kit-pro-react.js";

import tooltipsStyle from "assets/jss/material-kit-pro-react/tooltipsStyle.js";
import imagesStyles from "assets/jss/material-kit-pro-react/imagesStyles.js";
import customSelectStyle from "assets/jss/material-kit-pro-react/customSelectStyle.js";

const productStyle = {
    ffBook,
    mlAuto,
    main,
    ...imagesStyles,
    ...customSelectStyle,
    ...tooltipsStyle,
    container: {
        ...container,
        zIndex: 2
    },
    mainRaised: {
        ...mainRaised
    },
    section: {
        ...section,
        padding: "0"
    },
    title: {
        ...title,
        minHeight: "5px",
        marginBottom: 0
    },
    sectionGray: {
        background: grayColor[14]
    },
    mainPrice: {
        marginBottom: "0"
    },
    textCenter: {
        textAlign: "center !important"
    },
    features: {
        paddingTop: "0"
    },
    hoverIcon: {
        "&:hover": {
            color: "#fff"
        }
    },
    productPage: {
        backgroundColor: grayColor[2],
        "& $mainRaised": {
            padding: "10px",
            paddingTop: "20px"
        },
        "& .image-gallery-slide img": {
            borderRadius: "0",
            // maxWidth: "300px",
            height: "auto"
        },
        "& .image-gallery-swipe": {
            margin: "30px 0px",
            overflow: "hidden",
            width: "100%",
            height: "auto",
            textAlign: "center"
        },
        "& .image-gallery-thumbnails > .image-gallery-thumbnails-container a": {
            "&.active > div": {
                opacity: "1",
                borderColor: grayColor[6]
            },
            "& > div": {
                width: "80%",
                maxWidth: "85px",
                margin: "0 auto",
                padding: "8px",
                display: "block",
                border: "1px solid transparent",
                background: "transparent",
                borderRadius: "0",
                opacity: ".8"
            },
            "& > div img": {
                borderRadius: "0",
                width: "100%",
                height: "auto",
                textAlign: "center"
            }
        }
    },
    titleRow: {
        marginTop: "-8vh"
    },
    titleProduct: {
        marginTop: "0",
        marginBottom: "10px",
        fontSize: "18px"
    },
    decount: {
        textDecoration: "line-through",
        color: corlosGrayDescuento
    },
    subTitle: {
        fontSize: "17px"
    },
    floatRight: {
        float: "right!important"
    },
    pageHeader: {
        minHeight: "45vh",
        maxHeight: "600px",
        height: "auto",
        backgroundPosition: "top center"
    },
    relatedProducts: {
        marginTop: "50px",
        "& $title": {
            marginBottom: "80px"
        }
    },
    containerPrince: {
        display: "flex",
        alignItems: "flex-end",
        flexDirection: "column"
    },
    pickSize: {
        marginTop: "50px"
    },
    pullRight: {
        float: "right"
    },
    cardCategory: {
        textAlign: "center",
        marginTop: "10px"
    },
    cardTitle: {
        ...cardTitle,
        textAlign: "center"
    },
    cardDescription: {
        textAlign: "center"
    },
    textRose: {
        color: roseColor[0]
    },
    justifyContentBetween: {
        justifyContent: "space-between!important"
    },
    socialFeed: {
        "& p": {
            display: "table-cell",
            verticalAlign: "top",
            overflow: "hidden",
            paddingBottom: "10px",
            maxWidth: 300
        },
        "& i": {
            fontSize: "20px",
            display: "table-cell",
            paddingRight: "10px"
        }
    },
    img: {
        width: "20%",
        marginRight: "5%",
        marginBottom: "5%",
        float: "left"
    },
    block: {
        color: "inherit",
        padding: "0.9375rem",
        fontWeight: "500",
        fontSize: "12px",
        textTransform: "uppercase",
        borderRadius: "0",
        textDecoration: "none",
        position: "relative",
        display: "block"
    },
    inlineBlock: {
        display: "inline-block",
        padding: "0px",
        width: "auto"
    },
    list: {
        marginBottom: "0",
        padding: "0",
        marginTop: "0"
    },
    left: {
        float: "left!important",
        display: "block"
    },
    textRight: {
        textAlign: "right"
    },
    icon: {
        top: "3px",
        width: "18px",
        height: "18px",
        position: "relative"
    },
    priceMedium: {
        fontSize: "1rem"
    },
    m0: {
        margin: "0"
    },
    mb10: {
        marginBottom: "10px"
    },
    fz16: {
        fontSize: "16px"
    },
    pdLefRig05: {
        padding: "0 .5em"
    },
    textRight: {
        textAlign: "right"
    },
    flexCenter: {
        display: "flex",
        flex: "1",
        justifyContent: "center",
        alignItems: "center"
    },
    styleMarca: {
        fontSize: "12px"
    },
    noTop: {
        marginTop: "0"
    },
    noPaddingHorizontal: {
        paddingRight: "0",
        paddingLeft: "0"
    },
    bgWhite: {
        backgroundColor: "#fff"
    },
    maxWidthImagenGallery: {
        maxWidth: "400px"
    },
    iconButton: {
        width: "15px !important",
        height: "15px !important",
        marginTop: "-5px"
    },
    bulletStyle: {
        marginTop: "40px !important"
    },
    flex: {
        display: "flex"
    },
    btnCantidad: {
        padding: "12px"
    },
    fixedBottom: {
        marginBottom: "0",
        position: "fixed",
        bottom: "0",
        zIndex: "4",
        paddingBottom: "30px"
    },
    ml5: {
        marginLeft: "5px"
    },
    ml3: {
        marginLeft: "3px"
    },
    containerTypeSend: {
        display: "flex",
        alignItems: "center",
        fontSize: "12px",
        ...ffBook
    },
    textTypeSend: {
        marginLeft: "5px",
        ...ffBook
    }
};

export default productStyle;
