import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CustomInput from "components/CustomInput/CustomInput.js";

//redux
import { connect } from "react-redux";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { baseRouteImages, pathBanner } from "../../../config/config.json";

import styles from "assets/jss/material-kit-pro-react/views/componentsSections/preFooter.js";
import bannerStyle from "assets/jss/material-kit-pro-react/components/bannerStyle";
import WrapperMovile from "hoc/WrapperMovile.js";

const useStyles = makeStyles(styles);
const useStylesBanner = makeStyles(bannerStyle);

const BannerMobile = ({ banner, goRoute, ...props }) => {
    const classes = useStyles();
    const classesBanner = useStylesBanner();

    const handleResolveRouteImg = img => {
        const url = !!banner ? `${baseRouteImages}${pathBanner}${img}` : "";

        return `url(${url})`;
    };

    // trabajar el margen en base al alto del header !!
    const handleCalculatePaddingBottom = () => {
        let padding = "20px";
        if (props.sizeWindow > 575) {
            padding = "50px";
        }

        return padding;
    };

    return (
        <WrapperMovile>
            <div
                style={{
                    backgroundImage: handleResolveRouteImg(
                        banner.img_fondo_movil
                    ),
                    backgroundSize: "cover",
                    paddingBottom: handleCalculatePaddingBottom()
                }}
            >
                <div className={classes.container}>
                    <GridContainer>
                        <GridItem
                            xs={12}
                            sm={12}
                            md={6}
                            lg={6}
                            className={classNames(
                                classes.mlAuto,
                                classes.mrAuto
                            )}
                        >
                            <Card plain className={classes.noBorder}>
                                <div
                                    style={{
                                        backgroundSize: "contain",
                                        backgroundImage: handleResolveRouteImg(
                                            banner.imgprincipal
                                        ),
                                        padding: "30% 20%",
                                        marginTop: "55px",
                                        backgroundRepeat: "no-repeat"
                                    }}
                                ></div>
                                {/* <img
                                    style={{
                                        backgroundSize: "contain",
                                        // backgroundImage: `url(${handleResolveRouteImg(banner.imgprincipal)})`,
                                        padding: "30% 20%",
                                        marginTop: "55px",
                                        backgroundRepeat: "no-repeat"
                                    }}
                                    src={handleResolveRouteImg(banner.imgprincipal)}
                                /> */}
                            </Card>
                        </GridItem>
                        <GridItem
                            xs={12}
                            sm={12}
                            md={6}
                            lg={6}
                            className={classesBanner.gridContainer}
                        >
                            <Card className={classesBanner.noBorder}>
                                <CardBody>
                                    <div className={classesBanner.infoTitle}>
                                        <h3
                                            className={classNames(
                                                classesBanner.titleMovil,
                                                classesBanner.fz23
                                            )}
                                        >
                                            {banner.titulo}
                                        </h3>
                                    </div>
                                    <div className={classesBanner.infoParrafo}>
                                        <p
                                            className={classNames(
                                                classesBanner.parrafo,
                                                classesBanner.fz14
                                            )}
                                        >
                                            {banner.descripcion}
                                        </p>
                                    </div>
                                </CardBody>
                            </Card>
                            <div className={classesBanner.boxBtn}>
                                <Button
                                    color="bbva"
                                    shadow
                                    noRound
                                    className={classNames(
                                        classesBanner.noMargin,
                                        classesBanner.noUpper
                                    )}
                                    onClick={() =>
                                        goRoute(
                                            banner.urlboton,
                                            banner.newScreen
                                        )
                                    }
                                >
                                    {banner.textoboton}
                                </Button>
                            </div>
                        </GridItem>
                    </GridContainer>
                </div>
            </div>
        </WrapperMovile>
    );
};

const mapStateToProps = ({ headerReducer, userReducer }) => {
    return {
        ...headerReducer,
        ...userReducer
    };
};

export default connect(
    mapStateToProps,
    null
)(BannerMobile);
