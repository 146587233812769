import React from "react";
import classNames from "classnames";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router";

import { capitalize, numberFormat, generateSpaces } from "utils/utils";
import { PUNTOS } from "config/const/constText";
import SvgIcon from "components/Svg/SvgIcon";
import AirplanemodeActive from "@material-ui/icons/AirplanemodeActive";
// router
import { Link } from "react-router-dom";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import noImagen from "assets/img/no-image.svg";

//config
import {
    baseRouteImages,
    pathImagenBig,
    pathImagenMedium,
    pathImagenSmall
} from "../../../config/config.json";
import styles from "assets/jss/material-kit-pro-react/views/ecommerceSections/productsStyle.js";
import Discount from "components/Card/Discount";
import ImageLazy from "components/Images/ImageLazy";
import { ENVIO_LIMA_CALLAO, ENVIO_NACIONAL } from "config/const/constText";

const useStyles = makeStyles(styles);

const CardProduct = ({
    product,
    addToCar,
    showModal,
    cardClass,
    discount,
    ...props
}) => {
    const classes = useStyles();

    // -------------------------metodos !! ----------------------------//
    const handleRouteImage = image => {
        return `${baseRouteImages}${pathImagenSmall}${image}`;
    };

    const showModalAddCar = product => {
        const {
            id,
            marca,
            nombrecorto,
            imgproducto,
            precio,
            preciooferta,
            puntos,
            puntosoferta,
            codproducto,
            envio,
            idcategoria,
            costoenviovale,
            ...props
        } = product;
        // cambiando el costo de envio que venia con [.00]
        const costoenvio = parseInt(product.costoenvio.replace(".00", ""));
        const soles = parseInt(preciooferta.replace(".00", ""));

        addToCar({
            idcategoria,
            marca,
            codproducto,
            precio,
            envio,
            id,
            costoenvio,
            titulo: nombrecorto,
            precioOferta: preciooferta,
            cantidad: 1,
            precioFinal: puntosoferta != 0 ? puntosoferta : puntos,
            puntos: puntos,
            puntosOferta: puntosoferta,
            imagen: handleRouteImage(imgproducto),
            costoenviovale
        });

        showModal({
            title: `${marca} ${nombrecorto}`,
            description: nombrecorto,
            cantidad: 1,
            precio: puntosoferta || puntos,
            uriImg: imgproducto,
            soles: soles || precio
        });
    };

    const handlePrice = product => {
        if (product.preciooferta > 0) {
            return product.preciooferta;
        }

        return product.precio;
    };

    const handlePoints = product => {
        if (product.puntosoferta) {
            return numberFormat(product.puntosoferta);
        }

        return numberFormat(product.puntos);
    };

    const handleInfoEnvio = envio => {
        return envio === "L" ? (
            <div className={classes.containerSend}>
                <i className="icon__delivery"></i>
                <span className={classes.ml5}>{ENVIO_LIMA_CALLAO}</span>
            </div>
        ) : (
            <div
                className={classNames(classes.containerSend, classes.mlMenos5)}
            >
                <AirplanemodeActive style={{ fontSize: "20px" }} />
                <span className={classes.ml3}> {ENVIO_NACIONAL}</span>
            </div>
        );
    };

    const handleRouter = product => {
        props.history.push(`/producto/${btoa(product.id)}`, {
            product: product.marca
        });
    };
    // -------------------------metodos  fin!! ----------------------------//

    return (
        <Card
            color="white"
            product
            className={classNames(cardClass, "hover", classes.mb0)}
            fullHeight
        >
            <CardHeader noShadow image className={classes.imgInCarousel}>
                {product.puntosoferta > 0 && (
                    <Discount
                        puntos={product.puntos}
                        offer={product.puntosoferta}
                    />
                )}
                <Link
                    to={`/producto/${btoa(product.id)}`}
                    state={{
                        product: product.marca
                    }}
                >
                    <ImageLazy src={product.imgproducto} alt={product.marca} />
                </Link>
            </CardHeader>
            <CardBody
                plain
                grow
                className={classNames(classes.noPaddingVertical, classes.mb0)}
            >
                <div
                    className={classes.containerBody}
                    onClick={() => handleRouter(product)}
                >
                    <h4
                        style={{
                            // REFACTOR: HERE!!
                            fontFamily: "BentonSansBBVA Medium",
                            fontWeight: "normal",
                            fontSize: "14px"
                        }}
                        className={classes.cardTitle}
                    >
                        {product.marca}
                    </h4>

                    <p className={classNames(classes.cardDescription)}>
                        {product.nombrecorto}
                    </p>
                    {product.puntosoferta > 0 && (
                        <p className={classes.descuento}>
                            {" "}
                            {numberFormat(product.puntos)}
                            {" " + PUNTOS}{" "}
                        </p>
                    )}
                    <p className={classNames(classes.price)}>
                        {handlePoints(product)}
                        {" " + PUNTOS}
                        <span
                            className={classNames(
                                classes.fz12,
                                classes.priceSoles
                            )}
                        >
                            o {`S/${numberFormat(handlePrice(product))}`}
                        </span>
                    </p>

                    <p className={classes.envio}>
                        {handleInfoEnvio(product.envio)}
                    </p>
                </div>
            </CardBody>
            <CardFooter plain className={classes.justifyContentBetween}>
                <div className={classes.priceContainer}>
                    <div
                        className={classes.boxIcon}
                        onClick={() => showModalAddCar(product)}
                    >
                        <i className="icon__shopping icon__shopping--small icon__shopping--color-primary"></i>

                        <span
                            style={{
                                paddingLeft: "5px",
                                color: "#1d73b2",
                                paddingTop: "3px",
                                fontSize: "13px"
                            }}
                        >
                            Agregar a la bolsa
                        </span>
                    </div>
                    {/* <Button
                            simple
                            color="info"
                            size="sm"
                            type="button"
                            onClick={() =>
                                props.detailProduct(
                                    product.id
                                )
                            }
                        >
                            Ver producto
                        </Button> */}
                </div>
            </CardFooter>
        </Card>
    );
};

export default withRouter(CardProduct);
