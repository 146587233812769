import * as Yup from "yup";

export const SignupSchema = Yup.object().shape({
    numero_documento: Yup.string()
        .min(8, "Mínimo 8 digitos")
        .max(15, "Maximo 15 digitos")
        // .matches(validationNumber, "Solo se permiten numeros")
        .required("Numero de documento es requerido"),
    password: Yup.string()
        .min(4, "Mínimo 4 caracteres")
        .max(30, "Maximo 30 caracteres")
        .required("Contraseña requerida")
        .when("numero_documento", (numero_documento, schema) => {
            return !!numero_documento ? schema.required() : schema;
        })
});