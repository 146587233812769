import React, { useState, useEffect } from "react";
import Directions from "./Directions";

import FormularioDirecciones from "./FormularioDirecciones";
import NavPills from "components/NavPills/NavPills";
import * as directionsUserActions from "../../../redux/actions/directionsUserActions";
import { connect } from "react-redux";
import HomeIcon from "@material-ui/icons/Home";
import { Box } from "@material-ui/core";
import SvgIcon from "components/Svg/SvgIcon";
import Button from "components/CustomButtons/Button.js";

const ContainerDireciones = props => {
    const [active, setActive] = useState(0);

    useEffect(() => {
    }, [props.direction.directionActive]);

    useEffect(() => {
        if (!props.direction.directions.length ) {
            setActive(1);
        } else if (props.direction.directionActive) {
            setActive(0);
        } else if (!props.direction.directionActive) {
            setActive(1);
        }
    }, [props.direction.directions.length]);

    const handleClick = () => {
        setActive(0);
    };

    const handleToggleClick = () => {
        setActive(!active);
    };

    return (
        <>
            <Button
                color="info"
                size="lg"
                style={{ padding: "25px" }}
                onClick={handleClick}
            >
                <Box
                    style={{
                        display: "flex",

                        alignItems: "center",
                        justifyContent: "center",
                        flexDirection: "column"
                    }}
                >
                    <SvgIcon
                        desc="icono-home"
                        fill="#F4F4F4"
                        width="20px"
                        height="20px"
                    />
                    <p
                        style={{
                            margin: 0,
                            marginTop: "5px",
                            color: "#F4F4F4",
                            fontFamily: "BentonSansBBVA Medium",
                            fontSize: "12px"
                        }}
                    >
                        Dirección
                    </p>
                </Box>
            </Button>
            <div style={{ minHeight: "300px" }}>
                <Directions show={!active} onClick={handleToggleClick} />
                <div style={{ display: active ? "block" : "none" }}>
                    <FormularioDirecciones onClick={handleToggleClick} />
                </div>
            </div>
        </>
    );
};

const mapStateToProps = ({ directionsReducer }) => {
    return {
        directionsReducer
    };
};

const mapDispatchToProps = {
    selectEditDirection: directionsUserActions.selectEditDirection
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ContainerDireciones);
