import React from "react";
import PropTypes from "prop-types";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import productStyle from "assets/jss/material-kit-pro-react/views/productStyle.js";
import SvgIcon from "components/Svg/SvgIcon";
import AirplanemodeActive from "@material-ui/icons/AirplanemodeActive";
import { ENVIO_NACIONAL, ENVIO_LIMA_CALLAO } from "config/const/constText";

const useStyles = makeStyles(productStyle);

const InfoTypeSend = ({ envio, ...props }) => {
    const classes = useStyles();

    const handleInfoEnvio = () => {
        if (envio === "L") {
            return (
                <>
                    <i className="icon__delivery"></i>
                    <span className={classes.textTypeSend}>
                        {ENVIO_LIMA_CALLAO}
                    </span>
                </>
            );
        }

        return (
            <>
                <AirplanemodeActive style={{ fontSize: "20px" }} />
                <span className={classes.textTypeSend}>{ENVIO_NACIONAL}</span>
            </>
        );
    };

    return (
        <div className={classNames(classes.containerTypeSend)}>
            {handleInfoEnvio()}
        </div>
    );
};

// InfoTypeSend.defaultProps = {

// }

InfoTypeSend.propTypes = {
    envio: PropTypes.isRequired
};

export default InfoTypeSend;
