import React, { useEffect, useState } from "react";

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Danger from "components/Typography/Danger.js";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardFooter from "components/Card/CardFooter";

import InputAdornment from "@material-ui/core/InputAdornment";
import Button from "components/CustomButtons/Button.js";
import Email from "@material-ui/icons/Email";

// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// formik
import { Formik, Form } from "formik";
import * as Yup from "yup";
import CustomInput from "components/CustomInput/CustomInput";
import { ModalLoader } from "components/Modal/ModalLoader";
import ModalTerminos from "components/Modal/ModalTerminos";

import newPasswordPageStyle from "assets/jss/material-kit-pro-react/views/newPasswordPageStyle.js";
import { resetPassSendMail } from "services/user/userServices";
import ReCAPTCHA from "react-google-recaptcha";
import { CAPCHA_KEY } from "config/const/capchat";


const useStyles = makeStyles(newPasswordPageStyle);

// esquema de validacion del formulario
const SignupSchema = Yup.object().shape({
    email: Yup.string()
        .email("El Correo no es valido")
        .required("Correo es requerido")
});

const ForgotPassword = props => {
    const [isLoading, setIsLoading] = useState(false);
    const [modalMessage, setModalMessage] = useState(false);
    const [tokenCapchat, setTokenCapchat] = useState("");
    const [message, setMessage] = useState(
        "Acabamos de envíarte un correo electrónico para que puedas restaurar tu contraseña en Puntos Delivery"
    );
    const classes = useStyles();

    useEffect(() => {
        goTop();
    }, []);

    const goTop = () => {
        window.scrollTo(0, 0);
        document.body.scrollTop = 0;
    };

    const handleCloseModal = () => {
        setModalMessage(false);
        props.history.replace("/");
    };

    const handleSendMail = async ({ email }) => {
        setIsLoading(true);
        const { response, message } = await resetPassSendMail(email, tokenCapchat);
        if (!response) {
            setMessage(message.responseJSON.message);
            setModalMessage(true);
            setIsLoading(false);
            return;
        }
        setIsLoading(false);
        setModalMessage(true);
    };

    const onChangeCapcha = value => {
        setTokenCapchat(value);
    };

    return (
        <div className={classes.section}>
            <div className={classes.container}>
                <ModalLoader showModal={isLoading} />
                <ModalTerminos
                    showModal={modalMessage}
                    closeModal={handleCloseModal}
                    content={message}
                />
                <GridContainer
                    className={classNames(classes.mt4, classes.center)}
                >
                    <GridItem xs={12} sm={6} md={6}>
                        <div className={classes.title}>
                            <h3>Escribe tu correo electrónico</h3>
                        </div>
                        <Formik
                            initialValues={{ email: "" }}
                            validationSchema={SignupSchema}
                            onSubmit={handleSendMail}
                            render={({
                                handleSubmit,
                                handleChange,
                                handleBlur,
                                values,
                                touched,
                                errors
                            }) => {
                                return (
                                    <Form className={classes.form}>
                                        <Card>
                                            <CardBody>
                                                <CustomInput
                                                    labelText="Correo"
                                                    id="email"
                                                    formControlProps={{
                                                        fullWidth: true,
                                                        className: classes.mb0
                                                    }}
                                                    error={
                                                        !!errors.email &&
                                                        touched.email
                                                    }
                                                    inputProps={{
                                                        type: "email",
                                                        name: "email",
                                                        onChange: handleChange,

                                                        value: values.email,
                                                        startAdornment: (
                                                            <InputAdornment position="start">
                                                                <Email
                                                                    className={
                                                                        classes.inputIconsColor
                                                                    }
                                                                />
                                                            </InputAdornment>
                                                        )
                                                    }}
                                                />
                                                {errors.email &&
                                                    touched.email && (
                                                        <Danger
                                                            className={
                                                                classes.ffBook
                                                            }
                                                        >
                                                            {errors.email}
                                                        </Danger>
                                                    )}
                                                <div className={classes.mt10}>
                                                 <ReCAPTCHA sitekey={CAPCHA_KEY} onChange={onChangeCapcha} />
                                                </div>
                                                <div className={classes.mt10}>
                                                    <Button
                                                        color="info"
                                                        size="lg"
                                                        type="submit"
                                                        disabled={!tokenCapchat || !values.email}
                                                    >
                                                        Enviar
                                                    </Button>
                                                </div>
                                            </CardBody>
                                        </Card>
                                    </Form>
                                );
                            }}
                        />
                    </GridItem>
                </GridContainer>
            </div>
        </div>
    );
};

export default ForgotPassword;
