import React, { useEffect, useState } from "react";
import classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import queryString from "query-string";
import newPasswordPageStyle from "assets/jss/material-kit-pro-react/views/newPasswordPageStyle.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardFooter from "components/Card/CardFooter";
import CustomInput from "components/CustomInput/CustomInput";
import Spinner from "../../components/spinner/Spinner";
import Slide from "@material-ui/core/Slide";
import Icon from "@material-ui/core/Icon";
import InputAdornment from "@material-ui/core/InputAdornment";
import Danger from "components/Typography/Danger.js";
import Button from "components/CustomButtons/Button.js";

// formik
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { ModalLoader } from "components/Modal/ModalLoader";
import { isValidTokenResetPass } from "services/user/userServices";
import { saveNewPassword } from "services/user/userServices";
import ModalTerminos from "components/Modal/ModalTerminos";

const useStyles = makeStyles(newPasswordPageStyle);

const SignupSchema = Yup.object().shape({
    password: Yup.string()
        .min(4, "Minimo 4 caracteres")
        .max(30, "Maximo 30 caracteres")
        .required("Contraseña requerida")
});

const NewPassword = props => {
    const [isLoading, setIsLoading] = useState(true);
    const [tokenState, setToken] = useState("");
    const [modalMessage, setModalMessage] = useState(false);
    const classes = useStyles();
    useEffect(() => {
        const { location } = props;
        const token = queryString.parse(location.search);

        setToken(token.t);
        handleValidToken(token.t);
        goTop();
    }, []);

    const goTop = () => {
        window.scrollTo(0, 0);
        document.body.scrollTop = 0;
    };

    const handleValidToken = async token => {
        const resul = await isValidTokenResetPass(token);

        if (!resul) {
            props.history.replace("/");
        }
        setIsLoading(false);
    };

    const handleSaveNewPassword = async ({ password }) => {
        setModalMessage(true);
        await saveNewPassword(tokenState, password);
    };

    const handleCloseModal = () => {
        setModalMessage(false);
        props.history.replace("/");
    };

    return (
        <div className={classes.section}>
            <div className={classes.container}>
                <ModalLoader showModal={isLoading} />
                <ModalTerminos
                    showModal={modalMessage}
                    closeModal={handleCloseModal}
                    content="Se actualizo su contraseña con exito"
                />
                <GridContainer
                    className={classNames(classes.mt4, classes.center)}
                >
                    <GridItem xs={12} sm={6} md={6}>
                        <h3
                            className={classNames(
                                classes.ffBook,
                                classes.pdTittle
                            )}
                        >
                            Ingrese la nueva contraseña.
                        </h3>
                        <Formik
                            initialValues={{ password: "" }}
                            validationSchema={SignupSchema}
                            onSubmit={handleSaveNewPassword}
                            render={({
                                handleSubmit,
                                handleChange,
                                handleBlur,
                                values,
                                touched,
                                errors
                            }) => {
                                return (
                                    <Form className={classes.form}>
                                        <Card>
                                            <CardBody>
                                                <CustomInput
                                                    id="pass"
                                                    error={
                                                        !!errors.password &&
                                                        touched.password
                                                    }
                                                    formControlProps={{
                                                        fullWidth: true
                                                    }}
                                                    inputProps={{
                                                        placeholder:
                                                            "Contraseña",
                                                        type: "password",
                                                        name: "password",
                                                        value: values.password,
                                                        onChange: handleChange,
                                                        startAdornment: (
                                                            <InputAdornment position="start">
                                                                <Icon
                                                                    className={
                                                                        classes.inputIconsColor
                                                                    }
                                                                >
                                                                    lock_utline
                                                                </Icon>
                                                            </InputAdornment>
                                                        ),
                                                        autoComplete: "off"
                                                    }}
                                                />

                                                {errors.password &&
                                                    touched.password && (
                                                        <Danger>
                                                            {errors.password}
                                                        </Danger>
                                                    )}
                                            </CardBody>
                                            <CardFooter
                                                className={classes.pdl15}
                                            >
                                                <Button
                                                    color="info"
                                                    size="lg"
                                                    type="submit"
                                                >
                                                    Enviar
                                                </Button>
                                            </CardFooter>
                                        </Card>
                                    </Form>
                                );
                            }}
                        />
                    </GridItem>
                </GridContainer>
            </div>
        </div>
    );
};

export default NewPassword;
