import {
    grayColor,
    container,
    cardTitle,
    whiteColor,
    blackColor,
    hexToRgb,
    ffBook,
    infoColor
} from "assets/jss/material-kit-pro-react.js";

import customCheckboxRadioSwitchStyle from "assets/jss/material-kit-pro-react/customCheckboxRadioSwitchStyle.js";

const signupPageStyle = {
    ffBook,
    container: {
        ...container,
        paddingTop: "3em",
        paddingBottom: "2em"
    },
    formControl: {
        width: "45%"
    },
    centerBox: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flex: 1
    },
    gridInputs: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center"
    },
    containerSelectInput: {
        width: "90%"
    },
    labelSize: {
        fontSize: "14px"
    },
    labelSize12: {
        fontSize: "12px"
    },
    separationInputs: {
        marginTop: "-15px"
    },
    separationFirstInput: {
        marginTop: "-25px"
    },
    mt12: {
        marginTop: "12px"
    },
    hidden: {
        display: "none"
    },
    pt0: {
        paddingTop: "0"
    },
    containerRegister: {
        width: "100%",
        zIndex: "2",
        position: "relative",
        marginLeft: "auto",
        paddingTop: "20vh",
        marginRight: "auto",
        paddingLeft: "15px",
        paddingRight: "15px",
        maxWidth: "1000px",
        paddingBottom: "9vh"
    },
    pageHeader: {
        minHeight: "100vh",
        height: "auto",
        display: "inherit",
        position: "relative",
        margin: "0",
        padding: "0",
        border: "0",
        alignItems: "center",
        "&:before": {
            background: "#0598AB"
        },
        "&:after": {
            background: "#0598AB"
        },
        "&:before,&:after": {
            position: "absolute",
            zIndex: "1",
            width: "100%",
            height: "100%",
            display: "block",
            left: "0",
            top: "0",
            content: '""'
        }
    },
    cardSignup: {
        borderRadius: "0",
        boxShadow:
            "0 16px 24px 2px rgba(" +
            hexToRgb(blackColor) +
            ", 0.14), 0 6px 30px 5px rgba(" +
            hexToRgb(blackColor) +
            ", 0.12), 0 8px 10px -5px rgba(" +
            hexToRgb(blackColor) +
            ", 0.2);",
        marginBottom: "100px",
        padding: "40px 0px"
    },
    cardTitle: {
        ...cardTitle,
        ...ffBook,
        textDecoration: "none",
        textAlign: "center !important",
        marginBottom: "0.75rem",
        fontSize: "35px !important",
        color: "#626262"
    },
    ...customCheckboxRadioSwitchStyle,
    socials: {
        marginTop: "0",
        position: "absolute",
        width: "100%",
        transform: "none",
        left: "0",
        top: "0",
        height: "100%",
        lineHeight: "41px",
        fontSize: "20px"
    },
    textCenter: {
        textAlign: "center"
    },
    inputAdornment: {
        marginRight: "18px",
        position: "relative"
    },
    inputAdornmentIcon: {
        color: grayColor[13]
    },
    form: {
        margin: "0"
    },
    infoArea: {
        padding: "0px 0px 20px !important"
    },
    block: {
        color: "inherit",
        padding: "0.9375rem",
        fontWeight: "500",
        fontSize: "12px",
        textTransform: "uppercase",
        borderRadius: "0",
        textDecoration: "none",
        position: "relative",
        display: "block"
    },
    inlineBlock: {
        display: "inline-block",
        padding: "0px",
        width: "auto"
    },
    list: {
        marginBottom: "0",
        padding: "0",
        marginTop: "0"
    },
    left: {
        float: "left!important",
        display: "block",
        "&,& *,& *:hover,& *:focus": {
            color: whiteColor + "  !important"
        }
    },
    right: {
        padding: "15px 0",
        margin: "0",
        float: "right",
        "&,& *,& *:hover,& *:focus": {
            color: whiteColor + "  !important"
        }
    },
    image: {
        width: "184px"
    },
    align: {
        alignItems: "center"
    },
    centerFlex: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
    marginCaptcha: {
        marginTop: "15px",
        marginBottom: "15px"
    },
    colorBlue: {
        color: infoColor[0]
    }
};

export default signupPageStyle;
