import React from "react";
import { Route, Redirect } from "react-router-dom";
import { getUserStorage } from "services/localStorage/userStorage";
import { validateLengthObject } from "utils/utils";

const PrivateLoginRouter = ({ component: Component, ...rest }) => {
    const isAuthenticated = () => validateLengthObject(getUserStorage());

    return (
        <Route
            {...rest}
            render={props => {
                return !isAuthenticated() ? (
                    <Redirect
                        to={{
                            pathname: "/"
                        }}
                    />
                ) : (
                        <Component {...props} />
                    );
            }}
        />
    );
};

export default PrivateLoginRouter;
