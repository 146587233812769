import React, { useState, useRef, useEffect } from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
import { IMaskInput } from "react-imask";
import { connect } from "react-redux";
import * as modalActions from "redux/actions/modalActions";
import * as userActions from "redux/actions/userActions";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import FormControl from "@material-ui/core/FormControl";
import styles from "assets/jss/material-kit-pro-react/components/customInputStyle.js";
import CreditCard from "./CreditCard";
import Button from "components/CustomButtons/Button.js";
import { updateCreditCardServices } from "services/user/userServices";
import { sanitizeStr } from "utils/utils";
import { useLocation } from "react-router-dom";

// internal components !!
function TextMaskCustom(props) {
    return (
        <IMaskInput
            mask="0000 0000 0000 0000"
            radix=""
            value=""
            unmask={true} // true|false|'typed'
            // onChange={(e) => console.log("cambio", e.target.value, e.currentTarget)}
            // onAccept={
            //     // depending on prop above first argument is
            //     // `value` if `unmask=false`,
            //     // `unmaskedValue` if `unmask=true`,
            //     // `typedValue` if `unmask='typed'`
            //     (value, mask) => console.log(value)
            // }
            // ...and more mask props in a guide
            {...props}
            // input props also available
            placeholder="Ej: 0000 0000 0000 0000"
        />
    );
}

const useStyles = makeStyles(styles);

const ContainerCreditCard = ({ user, ...props }) => {
    const classes = useStyles();
    const input = useRef(null);
    const location = useLocation();
    const [cardNumber, setCardNumber] = useState("");
    const [isDisable, setIsDisable] = useState(true);

    useEffect(() => {
        if (input.current) {
            // @OJO no hace el focus!!
            input.current.focus();
        }
    }, []);

    const handleChange = e => {
        setIsDisable(true);
        if (e.target.value.length == 19) {
            setIsDisable(false);
        }

        setCardNumber(e.target.value);
    };

    const handleSave = async () => {
        setIsDisable(true);
        props.toggleGlobalLoader();
        const s = await updateCreditCardServices({
            token: user.token,
            tarjeta: sanitizeStr(cardNumber, " ", "")
        });
        props.getPointsUser({ token: user.token });
        setCardNumber("");
        props.toggleGlobalLoader();
        props.toggleCreditCard();
    };

    const handleCloseModal = () => {
        props.toggleCreditCard();
    };

    const handleKeyPress = e => {
        if (e.charCode === 13 && cardNumber.length == 19) {
            handleSave();
        }
    };

    return (
        <div style={{ flexDirection: "column" }}>
            <CreditCard
                cardNumber={cardNumber}
                userName={`${user.nombres} ${user.apellidos}`}
            />
            <FormControl variant="outlined" style={{ width: "280px" }}>
                <InputLabel
                    shrink
                    className={classes.labelCC}
                    htmlFor="formatted-text-mask-input"
                >
                    Número de tarjeta
                </InputLabel>
                <OutlinedInput
                    value={cardNumber}
                    onChange={handleChange}
                    onKeyPress={handleKeyPress}
                    ref={input}
                    name="textmask"
                    id="formatted-text-mask-input"
                    inputComponent={TextMaskCustom}
                    label="Numero de tarjeta"
                />
                <Button
                    color="info"
                    size="lg"
                    type="buton"
                    className={classes.mt15}
                    onClick={handleSave}
                    disabled={isDisable}
                >
                    Registrar mi Tarjeta
                </Button>
                {location.pathname == "/registro" && (
                    <Button
                        color="info"
                        simple
                        type="buton"
                        className={classNames(classes.colorText)}
                        onClick={handleCloseModal}
                    >
                        Omitir
                    </Button>
                )}
            </FormControl>
        </div>
    );
};

const mapStateToProps = ({ userReducer }) => ({
    ...userReducer
});

const mapDispatchToProps = {
    ...modalActions,
    ...userActions
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ContainerCreditCard);
