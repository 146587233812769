import { combineReducers } from "redux";

import userReducer from "./users/userReducer";
import categoriesReducer from "./categories/categoriesReducer";
import productsReducer from "./products/productsReducer";
import modalReducer from "./modal/modalReducer";
import carReducer from "./car/carReducer";
import marcasReducer from "./marcas/marcasReducer";
import filterProductsReducer from "./filterProducts/filterProductsReducer";
import formReducer from "./form/formReducer";
import directionsReducer from "./directions/directionsReducer";
import productsRecommended from "./products/productsRecommended";
import ModalsReducer from "./modal/ModalsReducer";
import visaCashReducer from "./visaCash/visaCashReducer";
import headerReducer from "./header/headerReducer";

export default combineReducers({
    userReducer,
    categoriesReducer,
    productsReducer,
    modalReducer,
    carReducer,
    marcasReducer,
    filterProductsReducer,
    formReducer,
    directionsReducer,
    productsRecommended,
    ModalsReducer,
    visaCashReducer,
    headerReducer
});
